/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import FacebookLogin from "@greatsumini/react-facebook-login";
import GoogleLogin from "react-google-login";
import Loader from "../../utils/Loader";
import Alert from "../../utils/Alert";
import { logger } from "../../utils/utils";

function LoginRender({
  history,
  setPassword,
  setUsername,
  login,
  isLoading,
  responseFacebook,
  responseGoogle,
  gralErrorMsg,
  submitErrorMsg,
  setGralErrorMsg,
}) {
  return (
    <section className="banner-login">
      <header className="header1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2">
              <a href="./" className="logo"></a>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid hero-content">
        <div className="row align-items-stretch">
          <div className="col-lg-6">
            <div className="container-5"></div>
          </div>
          <div className="col-lg-6">
            <div className="container-login">
              <h2>
                <strong>Ingresá a tu cuenta</strong>
              </h2>

              {gralErrorMsg && (
                <Alert
                  type="danger"
                  text={gralErrorMsg}
                  visible={true}
                  style={{ textAlign: "left" }}
                />
              )}

              <FacebookLogin
                appId="1652032348483828"
                fields="name,email,first_name,last_name"
                scope="public_profile"
                autoLoad={false}
                render={({ onClick, logout }) => (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      onClick();
                    }}
                    className="cont-login"
                  >
                    <img src="images/fb-login.png" className="img" alt="" />{" "}
                    Ingresá con Facebook
                  </a>
                )}
                onSuccess={(response) => {
                  logger.debug("Login Success!", response);
                }}
                onFail={(error) => {
                  logger.debug("Login Failed!", error);
                }}
                onProfileSuccess={(response) => {
                  logger.debug("Get Profile Success!", response);
                  responseFacebook(response);
                }}
              />

              <GoogleLogin
                clientId="651898992576-gicdp1774a832dl3vukt70dvnrkmgdpu.apps.googleusercontent.com"
                buttonText="Ingresar con GMAIL"
                onSuccess={responseGoogle}
                onFailure={(data) => {
                  logger.debug("googleFailure", data);
                  setGralErrorMsg("Ocurrió un error al intentar ingresar con Google. Por favor, intentá nuevamente.");
                }}
                cookiePolicy={"single_host_origin"}
                render={({ onClick, disabled }) => {
                  return (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onClick();
                      }}
                      className="cont-login"
                    >
                      <img src="images/g-login.png" className="img" alt="" />{" "}
                      Ingresá con Google
                    </a>
                  );
                }}
              />

              {/* <a href="" className="cont-login">
                <img src="images/g-login.png" className="img" alt="" />
                Ingresa con Google
              </a>
              <div className="subtext1">
                <p>O ingresá con tu email</p>
              </div> */}
              <div className="subtext1">
                <p>O ingresá con tu email</p>
              </div>
              <div className="login">
                <form action="#">
                  <input
                    onChange={(evt) => setUsername(evt.target.value)}
                    type="text"
                    className="email"
                    placeholder="Email"
                  />
                  <input
                    onChange={(evt) => setPassword(evt.target.value)}
                    type="password"
                    className="pass"
                    placeholder="Contraseña"
                  />

                  {submitErrorMsg && (
                    <Alert
                      type="danger"
                      text={submitErrorMsg}
                      visible={true}
                      style={{ textAlign: "left" }}
                    />
                  )}

                  <button
                    onClick={() => login()}
                    disabled={isLoading}
                    value="INGRESAR"
                    className="btn btn-primary dark btn_ingreso"
                  >
                    <Loader
                      style={{ paddinTop: 0, paddingBottom: 0 }}
                      isLoading={isLoading}
                    >
                      INGRESAR
                    </Loader>
                  </button>

                  <a onClick={()=> history.push('/reestablecerPass')} className="link mt-3">
                    ¿Olvidaste tu contraseña?
                  </a>
                </form>
              </div>
            </div>
            <div className="container-6">
              <div className="text_cuenta">
                <h2>¿Aún no tenes una cuenta?</h2>
                <a onClick={()=> history.push('/registro')} className="btn btn-primary dark btn-start">
                  Crear una Cuenta
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginRender;
