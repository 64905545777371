import axios from "axios";
const upload = (url, headers, file, onUploadProgress) => {
  let formData = new FormData();
  formData.append("files[]", file);
  return axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...headers,
    },
    onUploadProgress,
  });
};

const exp = {
  upload,
};

export default exp;