import moment from "moment";
const winston = require("winston");
// Niveles de log
// en orden de importancia
// descendente
const LOGGER_LEVELS = {
  emerg: "emerg",
  alert: "alert",
  crit: "crit",
  error: "error",
  warning: "warning",
  notice: "notice",
  info: "info",
  debug: "debug",
};

// Nivel mínimo a loggear
// (niveles más bajos se ignoran)
const MIN_LEVEL = process.env.REACT_APP_WINSTON_MIN_LVL;

// eslint-disable-next-line new-cap
export const logger = new winston.createLogger({
  levels: winston.config.syslog.levels,
  format: winston.format.json(),
  transports: [
    new winston.transports.Console({
      level: MIN_LEVEL,
      timestamp: true,
    }),
  ],
});

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const addDots = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

export const thousandSeparator = (value, commas = false) => {
  if (commas) {
    return addCommas(removeNonNumeric(value));
  }
  return addDots(removeNonNumeric(value));
};

export const tipoVendedorOptions = [
  {
    value: "Particular",
    label: "Particular",
  },
  {
    value: "Playa",
    label: "Playa",
  },
  {
    value: "Intermediario",
    label: "Intermediario",
  },
];

export const consumoOptions = [
  {
    value: "5-10",
    label: "5 a 10 Litros c/ 100 km",
  },
  {
    value: "11-15",
    label: "11 a 15 Litros c/ 100 km",
  },
  {
    value: "16-100",
    label: "Mas de 16 Litros c/ 100 km",
  },
];

export const combustibleOptions = [
  {
    value: "Diesel",
    label: "Diesel",
  },
  {
    value: "Naftero",
    label: "Naftero",
  },
  {
    value: "Gas",
    label: "Gas",
  },
  {
    value: "Flex",
    label: "Flex",
  },
  {
    value: "Hibrido",
    label: "Hibrido",
  },
  {
    value: "Semi Hibrido",
    label: "Semi Hibrido",
  },
  {
    value: "Electrico",
    label: "Electrico",
  },
];

export const colorOptions = [
  { value: "Amarillo", label: "Amarillo" },
  { value: "Azul", label: "Azul" },
  { value: "Beige", label: "Beige" },
  { value: "Blanco", label: "Blanco" },
  { value: "Bordo", label: "Bordo" },
  { value: "Cafe", label: "Café" },
  { value: "Celeste", label: "Celeste" },
  { value: "Dorado", label: "Dorado" },
  { value: "Gris claro", label: "Gris claro" },
  { value: "Gris oscuro", label: "Gris oscuro" },
  { value: "Lila", label: "Lila" },
  { value: "Marron", label: "Marron" },
  { value: "Mostaza", label: "Mostaza" },
  { value: "Naranja", label: "Naranja" },
  { value: "Negro", label: "Negro" },
  { value: "Ocre", label: "Ocre" },
  { value: "Plata", label: "Plata" },
  { value: "Rojo", label: "Rojo" },
  { value: "Rosa", label: "Rosa" },
  { value: "Verde", label: "Verde" },
];

export const cajaOptions = [
  { value: "Automatico", label: "Automatico" },
  { value: "Mecanico", label: "Mecanico" },
];

export const duenhosOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3 o más" },
];

export const duenhosOptionLabelsByValue = {
  1: "1",
  2: "2",
  3: "3 o más",
};

export const kilometrajeOptions = [
  { value: "0-1", label: "0km" },
  { value: "1-5000", label: "Hasta 5.000 km" },
  { value: "5001-10000", label: "Entre 5.000 y 10.000 km" },
  { value: "10001-1000000", label: "Más de 10.000 km" },
];

export const cuerpoOptions = [
  { value: "Camioneta", label: "Camioneta" },
  { value: "Camion", label: "Camion" },
  { value: "VAN", label: "VAN" },
  { value: "Sedan", label: "Sedan" },
  { value: "SUV", label: "SUV" },
  { value: "Hatchback", label: "Hatchback" },
  { value: "Pickup", label: "Pick Up" },
  // { value: "Notchback", label: "Notchback" },
  // { value: "Fastback", label: "Fastback" },
  // { value: "Hard Top", label: "Hard Top" },
  // { value: "Targa", label: "Targa" },
  // { value: "Shooting Brake", label: "Shooting Brake" },
  // { value: "Station Wagon", label: "Station Wagon" },
  // { value: "Coupe", label: "Coupe" },
  // { value: "Roadster", label: "Roadster" },
  // { value: "Monovolumen", label: "Monovolumen" },
  // { value: "Familiar", label: "Familiar" },
];

export const precioOptions = [
  { value: "0-10000000", label: "Hasta 10 millones" },
  { value: "10000000-50000000", label: "De 10 a 50 millones" },
  { value: "50000000-100000000", label: "De 50 a 100 millones" },
  { value: "100000000-0", label: "Más de 100 millones" },
];

export const traccionOptions = [
  { value: "4x2", label: "4x2" },
  { value: "4x4", label: "4x4" },
];

export const financiacionOptions = [
  { value: "si", label: "Ofrece financiación" },
  { value: "no", label: "No ofrece financiación" },
];

export const financiacionOptionByDBValue = {
  [true]: { value: "si", label: "Ofrece financiación" },
  [false]: { value: "no", label: "No ofrece financiación" },
};

export const financiacionOptionLabelsByViewValue = {
  si: "Ofrece financiación",
  no: "No ofrece financiación",
};

export const aceptaUsadoOptions = [
  { value: "si", label: "Acepta usados" },
  { value: "no", label: "No acepta usados" },
];

export const usadoOptionLabelsByViewValue = {
  si: "Acepta usados",
  no: "No acepta usados",
};

export const estadoOptions = [
  { value: "VALIDANDO", label: "Por validar" },
  { value: "OCULTO", label: "Oculto" },
];

export const estadoOptionLabelsByValue = {
  VALIDANDO: "Por validar",
  OCULTO: "Oculto",
};

export const rolDescriptionByRol = {
  vendedor: 'Vendedor',
  admin: 'Administrador',
  superadmin: 'Super-admin',
};

export const getLinkWithSize = (originalLink, size) => {
  /*

  originalLink:

  https://mindy1811.s3.us-east-2.amazonaws.com/autos/publicacion/ba6793c761f3a407fdd403a8b6faea8c/img/big/wallhaven-g8v9wq_1624036672172._1624036687738..png

  parts:

  0: "https:"
  1: ""
  2: "mindy1811.s3.us-east-2.amazonaws.com"
  3: "autos"
  4: "publicacion"
  5: "ba6793c761f3a407fdd403a8b6faea8c"
  6: "img"
  7: "big"
  8: "wallhaven-g8v9wq_1624036672172._1624036687738..png"

  hostParts:

  0: "mindy1811"
  1: "s3"
  2: "us-east-2"
  3: "amazonaws"
  4: "com"

  */

  let parts = originalLink.split("/");

  // El tamaño de la imagen siempre debería estar
  // en el directorio anterior al nombre del archivo
  // (el penúltimo índice)
  let penultimoIndice = parts.length - 2;

  // parts[0] = "http:";

  parts[penultimoIndice] = size;

  // let hostParts = parts[2].split(".");

  // hostParts[1] = "s3-website";

  // Recreate host
  // parts[2] = hostParts.join(".");
  // parts[2] = 'mindy.vaun.com.py';

  // Recreate whole link
  let newLink = parts.join("/");

  return newLink;
};

export const sleep = (ms) => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

export const timeSince = (ts) => {
  const currentTime = moment();
  const time = moment(ts);
  const diff = currentTime.diff(time, "days");
  let result = diff + " dias";
  if (diff === 1) {
    result = " " + diff + " dia";
  }
  if (diff < 1) {
    const horas = currentTime.diff(time, "h");
    if (horas === 1) {
      result = " " + horas + " hora";
    } else {
      result = " " + horas + " horas";
    }
  }
  if (diff > 30) {
    const meses = currentTime.diff(time, "months");
    if (meses === 1) {
      result = " " + meses + " mes";
    } else {
      result = " " + meses + " meses";
    }
  }
  return result;
};