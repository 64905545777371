/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";

export default function HeaderPublicar() {
  let history = useHistory();
  return (
    <header className="header2">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              history.push("/");
            }}
            className="logo"
          ></a>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNavAltMarkup"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a href={`https://api.whatsapp.com/send?phone=+595982367421&text=Hola, tengo una consulta sobre VAUN.`} target="_blank" className="nav-link" rel="noreferrer">
                  <img src="/images/ayuda.png" alt="" className="img-fluid" />
                  Ayuda
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}
