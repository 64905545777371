import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Header from "../../utils/Header";
import Footer from "../../utils/Footer";
import VenderRenderTop from "./VenderRenderTop";
import VenderRenderMiddle from "./VenderRenderMiddle";
import VenderRenderBottom from "./VenderRenderBottom";
import controller from "../duck";

function Vender({}) {
  let history = useHistory();
  const dispatch = useDispatch();
  const modelContenido = useSelector((state) => state.contenido);
  const modelMarcas = useSelector((state) => state.marcas);
  const modelModelos = useSelector((state) => state.modelos);
  const [contenido, setContenido] = useState(null);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [marca, setMarca] = useState(null);
  const [modelo, setModelo] = useState(null);
  const [anho, setAnho] = useState(null);

  useEffect(() => {
    const data = modelContenido?.data?.data;
    const error = modelContenido?.error?.message;

    if (error) {
      alert(error);
      dispatch(controller.duckContenidoByOrigen.actions.clear());
    }
    if (data) {
      setContenido(data.data);
      dispatch(controller.duckContenidoByOrigen.actions.clear());
    }
  }, [modelContenido]);

  useEffect(() => {
    const data = modelMarcas?.data?.data;
    const error = modelMarcas?.error?.message;

    if (error) {
      alert(error);
      dispatch(controller.duckMarcas.actions.clear());
    }
    if (data) {
      setMarcas(data.data);
      dispatch(controller.duckMarcas.actions.clear());
    }
  }, [modelMarcas]);

  useEffect(() => {
    const data = modelModelos?.data?.data;
    const error = modelModelos?.error;
    const errorMessage = modelModelos?.error?.message;

    if (error) {
      const errorToShow =
        errorMessage || "Ocurrió un error al realizar la acción";
      alert(errorToShow);
      dispatch(controller.duckGetModelos.actions.clear());
    }

    if (data) {
      setModelos(data.data);
      dispatch(controller.duckGetModelos.actions.clear());
    }
  }, [modelModelos]);

  useEffect(() => {
    dispatch(controller.getContenidoByIdThunk("vender-landing"));
    dispatch(controller.getMarcasThunk());
  }, []);

  useEffect(() => {
    if (marca) {
      dispatch(controller.getModelosThunk(marca.value));
    }
  }, [marca]);

  return (
    <React.Fragment>
      <Header history={history} />
      <VenderRenderTop
        history={history}
        contenido={contenido}
        marcas={marcas}
        marca={marca}
        setMarca={setMarca}
        modelos={modelos}
        modelo={modelo}
        setModelo={setModelo}
        anho={anho}
        setAnho={setAnho}
        loadingMarcas={modelMarcas.loading}
        loadingModedlos={modelModelos.loading}
      />
      <VenderRenderMiddle history={history} contenido={contenido} />
      <VenderRenderBottom history={history} contenido={contenido} />
      <Footer history={history} />
    </React.Fragment>
  );
}

export default Vender;
