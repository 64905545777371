/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import HeaderPublicar from "./HeaderPublicar";
import Footer from "../../utils/Footer";
import FiltrosOptionRender from "./../results/FiltrosOptionRender";
import {
  cajaOptions,
  combustibleOptions,
  cuerpoOptions,
  estadoOptions,
  logger,
  estadoOptionLabelsByValue,
} from "../../utils/utils";
import Alert from "../../utils/Alert";
import Pasos from "./Pasos";
import KilometrajeInput from "./KilometrajeInput";
import Loader from "../../utils/Loader";

const outerContainerStyle = { margin: "0.5rem 0", width: "100%" };
const innerContainerStyle = {
  textAlign: "left",
  paddingTop: "1rem",
  paddingRight: "1.25rem",
  paddingBottom: "0.75rem",
  paddingLeft: "1.25rem",
  border: "1px solid #d2dde3",
  borderRadius: "5px",
};
const dropdownIndicatorStyle = { width: 18, height: 10 };

const loaderStyle = {
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
};

const loaderSize = 30;

function InformacionBasicaView({
  history,
  loadingMarcas,
  loadingModelos,
  marcas,
  setMarca,
  marca,
  modelos,
  modelo,
  setModelo,
  modeloOption,
  setModeloOption,
  insertarPublicacion,
  anho,
  anhos,
  setAnho,
  loadingAnhos,
  combustible,
  setCombustible,
  caja,
  setCaja,
  kilometraje,
  setKilometraje,
  cuerpo,
  setCuerpo,
  // estado,
  // setEstado,
  publicacionID,
  publicacion,
  missingDataErrorMsg,
  startPubErrorMsg,
  generalErrorMsg,
  continuarButtonLoading,
  modelPublicacionIncompleta,
}) {
  const images = {
    van: "/images/ico-van.png",
    camioneta: "/images/ico-camioneta.png",
    camion: "/images/ico-camion.png",
    hatchback: "/images/ico-hatchback.png",
    pickup: "/images/ico-pickup.png",
    sedan: "/images/ico-sedan.png",
    suv: "/images/ico-suv.png",
  };

  const getImage = (cuerpo) => {
    const cuerpoLowerCase = cuerpo ? cuerpo.toLowerCase() : "";
    if (cuerpoLowerCase.includes("hatchback")) {
      return images["hatchback"];
    } else if (cuerpoLowerCase.includes("pickup")) {
      return images["pickup"];
    } else if (cuerpoLowerCase.includes("sedan")) {
      return images["sedan"];
    } else if (cuerpoLowerCase.includes("camioneta")) {
      return images["camioneta"];
    } else if (cuerpoLowerCase.includes("camion")) {
      return images["camion"];
    } else if (cuerpoLowerCase.includes("van")) {
      return images["van"];
    } else {
      return images["suv"];
    }
  };

  // render

  return (
    <Loader style={loaderStyle} isLoading={modelPublicacionIncompleta.loading} loaderSize={loaderSize}>
      <section className="contenedor-pasos">
        <HeaderPublicar />
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Pasos publicacionID={publicacionID} pasoNro={1} />
            </div>
            <div className="col-lg-9">
              <div className="container container-content">
                <section id="content-selmodel">
                  {generalErrorMsg && (
                    <Alert
                      text={generalErrorMsg}
                      type="danger"
                      visible={true}
                    />
                  )}
                  <h1 className="titulo-paso1">Seleccionar Modelo</h1>
                  <div className="home-publicar">
                    <FiltrosOptionRender
                      filter={
                        marca === null ? null : { label: marca, value: marca }
                      }
                      options={marcas}
                      onClick={(selected) => {
                        setModelo(null);
                        setModeloOption(null);
                        setMarca(selected.value);
                        // setMarcaOption(selected)
                      }}
                      label={"Marca"}
                      isLoading={loadingMarcas}
                      placeholder="Seleccione una marca"
                      outerContainerStyle={outerContainerStyle}
                      innerContainerStyle={innerContainerStyle}
                      dropdownIndicatorStyle={dropdownIndicatorStyle}
                      valueContainerStyle={{
                        fontStyle: "italic",
                        fontSize: "1.15rem",
                        ":hover": {
                          color: "#FFD340",
                        },
                        padding: 0,
                      }}
                      labelStyle={{
                        color: "#A7A9AC",
                        display: "block",
                        fontSize: "0.85rem",
                        lineHeight: "1rem",
                        cursor: "pointer",
                        paddingLeft: "2px",
                      }}
                    />

                    <FiltrosOptionRender
                      filter={modeloOption}
                      options={modelos}
                      onClick={(selected) => {
                        setModeloOption(selected);
                        setModelo(selected.value);
                      }}
                      label={"Modelo"}
                      isLoading={loadingModelos}
                      placeholder="Seleccione un modelo"
                      outerContainerStyle={outerContainerStyle}
                      innerContainerStyle={innerContainerStyle}
                      dropdownIndicatorStyle={dropdownIndicatorStyle}
                      valueContainerStyle={{
                        fontStyle: "italic",
                        fontSize: "1.15rem",
                        ":hover": {
                          color: "#FFD340",
                        },
                        padding: 0,
                      }}
                      labelStyle={{
                        color: "#A7A9AC",
                        display: "block",
                        fontSize: "0.85rem",
                        lineHeight: "1rem",
                        cursor: "pointer",
                        paddingLeft: "2px",
                      }}
                    />

                    <FiltrosOptionRender
                      filter={
                        anho === null ? null : { label: anho, value: anho }
                      }
                      options={anhos}
                      onClick={(selected) => setAnho(selected.value)}
                      label={"Año"}
                      isLoading={loadingAnhos}
                      placeholder="Seleccione un año"
                      outerContainerStyle={outerContainerStyle}
                      innerContainerStyle={innerContainerStyle}
                      dropdownIndicatorStyle={dropdownIndicatorStyle}
                      valueContainerStyle={{
                        fontStyle: "italic",
                        fontSize: "1.15rem",
                        ":hover": {
                          color: "#FFD340",
                        },
                        padding: 0,
                      }}
                      labelStyle={{
                        color: "#A7A9AC",
                        display: "block",
                        fontSize: "0.85rem",
                        lineHeight: "1rem",
                        cursor: "pointer",
                        paddingLeft: "2px",
                      }}
                    />
                  </div>
                </section>
                <section className="content-selauto">
                  <div className="titulo-selauto">
                    <h2>Seleccionar Cuerpo del Vehículo</h2>
                  </div>
                  <div className="seleccionar-auto">
                    <div className="row">
                      {cuerpoOptions &&
                        cuerpoOptions.map((row, index) => {
                          return (
                            <div key={row.value} className="col-lg-4">
                              <label
                                className={
                                  "tipo-auto" +
                                  (row.value === cuerpo
                                    ? " tarjeta-amarillo"
                                    : "")
                                }
                              >
                                <div className="tipo-auto-content">
                                  <figure className="logo-auto">
                                    <img
                                      src={getImage(row.value)}
                                      alt={row.label}
                                      className="img-fluid"
                                    />
                                  </figure>
                                  <p className="estilo-auto">{row.label}</p>
                                  <input
                                    type="radio"
                                    name="tipo"
                                    className="tipo-tar"
                                    onChange={() => {
                                      logger.debug("InfoBasica.view.setCuerpo");
                                      logger.debug(row.value);
                                      setCuerpo(row.value);
                                    }}
                                    checked={row.value === cuerpo}
                                  />
                                </div>
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </section>

                <section className="caracteristica-auto">
                  <div className="row">
                    <div className="col-lg-6">
                      <h3>Combustible</h3>
                      <FiltrosOptionRender
                        filter={
                          combustible === null
                            ? null
                            : { label: combustible, value: combustible }
                        }
                        options={combustibleOptions}
                        onClick={(selected) => setCombustible(selected.value)}
                        label=""
                        isLoading={false}
                        placeholder="Combustible"
                      />
                    </div>
                    <div className="col-lg-6">
                      <h3>Caja</h3>
                      <FiltrosOptionRender
                        filter={
                          caja === null ? null : { label: caja, value: caja }
                        }
                        options={cajaOptions}
                        onClick={(selected) => setCaja(selected.value)}
                        label=""
                        isLoading={false}
                        placeholder="Caja"
                      />
                    </div>
                  </div>                
                </section>

                <section className="caracteristica-auto">                

                <div className="row">
                    <div className="col-lg-6">
                      <KilometrajeInput
                        title="Kilometraje (Km)"
                        inputStyle={{ lineHeight: "normal" }}
                        inputContainerClassName="drop-down-trigger drop-down-filter"
                        inputClassName="input-text"
                        placeholder="Ej. 1.200"
                        setValue={setKilometraje}
                        value={kilometraje}
                        unit="Km"
                      />
                    </div>
                  </div>                  
                </section>

                <div className="content-btnpublicar">
                  {startPubErrorMsg && (
                    <Alert
                      type="danger"
                      text={startPubErrorMsg}
                      visible={true}
                      style={{ textAlign: "left" }}
                    />
                  )}
                  {missingDataErrorMsg && (
                    <Alert
                      type="danger"
                      text={missingDataErrorMsg}
                      visible={true}
                      style={{ textAlign: "left" }}
                    />
                  )}
                  <button
                    className="btn btn-primary dark"
                    id="next"
                    onClick={insertarPublicacion}
                    disabled={continuarButtonLoading}
                  >
                    <Loader
                      isLoading={continuarButtonLoading}
                      loaderSize={19}
                      loaderColor="#fff"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      CONTINUAR
                    </Loader>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer history={history} />
    </Loader>
  );
}

export default InformacionBasicaView;
