/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Modal, Button } from "react-bootstrap";
import Geocode from "react-geocode";

const marker = require("./img/marker.png").default;

const Marker = ({}) => (
  <img
    alt=""
    src={marker}
    style={{
      position: "absolute",
      top: "48%",
      left: "48%",
      width: 20,
      height: 20,
    }}
  />
);

function Direccion({ direccion, setDireccion, initialRegion, setLat, setLng }) {
  Geocode.setApiKey(process.env.REACT_APP_GEOCODE_APIKEY);
  const [center, setCenter] = useState({
    lat: -25.295196,
    lng: -57.63172,
  });
  const [show, setShow] = useState(false);

  let zoom = 15;

  useEffect(() => {
    if (initialRegion && initialRegion.lat && initialRegion.lng) {
      setCenter({
        lat: initialRegion.lat,
        lng: initialRegion.lng,
      });
    }
  }, [initialRegion]);

  const setDirFromLatLng = () => {
    Geocode.fromLatLng(center.lat, center.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
    
        !direccion && setDireccion(address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  return (
    <div>
      {/* <input
        value={direccion}
        onChange={(evt) => setDireccion(evt.target.value)}
      /> */}

      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          setShow(true);
        }}
        style={{ float: "right", marginBottom: '15px', marginTop:'5px' }}
      >
        Geolocalizar
      </a>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Seleccionar ubicación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: "40vh", width: "100%" }}>
            <GoogleMapReact
              onChange={(region) => {
                setLat(region.center.lat);
                setLng(region.center.lng);
                setCenter(region.center);
              }}
              bootstrapURLKeys={{
                key: process.env.REACT_APP_MAP_APIKEY,
              }}
              defaultCenter={center}
              defaultZoom={zoom}
            ></GoogleMapReact>
          </div>
          <Marker />
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={() => {
              setDirFromLatLng();
              setShow(false);
            }}
          >
            Seleccionar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Direccion;
