import React, { useEffect } from "react";
import { Dots } from "react-activity";
import Header from "./Header";
import Footer from "./Footer";
import "react-activity/dist/Dots.css";

function NotFound({ history }) {
  useEffect(() => {
    // Listen for click events on body
    document.body.classList.add("body404");

    return () => document.body.classList.remove("body404");
  }, []);
  return (
    <>
      <Header history={history} />
      <section id="seccion404">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 offset-lg-7">
              <div className="titulo404">
                <figure className="imagen404">
                  <img src="/images/alto.png" alt="error404" />
                </figure>
                <h1>oops! Parece que no encontramos el camino</h1>
                <p>
                  Verifica que este correctamente escrita o ingresa desde la
                  página de inicio.
                </p>
                <a
                  onClick={() => history.push("/")}
                  className="btn btn-primary dark"
                >
                  IR AL INICIO
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer history={history} />
    </>
  );
}

export default NotFound;
