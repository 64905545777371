import React, { useEffect, useState } from "react";
import _ from "lodash";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import controller from "../auth/duck";
import ConfirmationRender from "./ConfirmationRender";
import Footer from "../../utils/Footer";
import { logger, parseJwt, validateEmail } from "../../utils/utils";

const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || "vaun.com.py";

function Confirmation() {
  let history = useHistory();
  const dispatch = useDispatch();
  const modelRegistro = useSelector((state) => state.registro);
  const [codigo, setCodigo] = useState(null);
  const [submitErrorMsg, setSubmitErrorMsg] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const data = modelRegistro?.data?.data;
    const error = modelRegistro?.error;
    const errorMessage = modelRegistro?.error?.response?.data?.message;

    if (error) {
      let errorToShow;

      if (errorMessage) {
        errorToShow = errorMessage;
      }
      setSubmitErrorMsg(
        `Ocurrió un error: ${errorToShow}. Por favor, intentá nuevamente.`
      );
      dispatch(controller.duck.actions.clear());
    }
    if (data) {
      logger.debug("modelRegistro.data", data);
      dispatch(controller.duckRegistro.actions.clear());
      history.push("/login")
      const analytics = getAnalytics();
      logEvent(analytics, "estadisticas", {
        action: "usuariosNuevos",
      });
    }
  }, [modelRegistro]);

  function submit() {
    setSubmitErrorMsg(null);
    let data = {
      invitacionCodigo:codigo,
      invitacionId:id,
    };
   
    dispatch(controller.registroThunk(data));
  }

  return (
    <React.Fragment>
      <ConfirmationRender
        history={history}
        setCodigo={setCodigo}
        submit={submit}
        isLoading={modelRegistro.loading || modelRegistro.loading}
        submitErrorMsg={submitErrorMsg}
      />
      <Footer />
    </React.Fragment>
  );
}

export default Confirmation;
