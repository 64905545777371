import { combineReducers } from "redux";

import publicaciones from "../publicaciones/duck";
import auth from "../publicaciones/auth/duck";
import register from "../publicaciones/register/duck";
import imagenesV3 from "./../imageUploadV3/duck";

const publicacionesReducer = publicaciones.duck.reducer;
const publicaciones2Reducer = publicaciones.duck2.reducer;
const publicacionDetailReducer =
  publicaciones.duckGetPublicacionCompleta.reducer;
const marcasReducer = publicaciones.duckMarcas.reducer;
const modelosReducer = publicaciones.duckGetModelos.reducer;
const contenidoReducer = publicaciones.duckContenidoByOrigen.reducer;
const loginStandardReducer = auth.duck.reducer;
const loginSocialReducer = auth.duckSocial.reducer;
const registroReducer = auth.duckRegistro.reducer;
const segurosForSelectReducer = publicaciones.duckGetSegurosForSelect.reducer;
const talleresBySeguroReducer = publicaciones.duckGetTalleresBySeguro.reducer;
const addPublicacionReducer = publicaciones.duckAddPublicacion.reducer;
const getOrganizacionesReducer = publicaciones.duckGetOrganizaciones.reducer;
const getPublicacionIncompletaReducer =
  publicaciones.duckGetPublicacionIncompleta.reducer;
const updateProductDetailsReducer =
  publicaciones.duckUpdateProductDetails.reducer;
const eliminarImgPublicacionReducer =
  publicaciones.duckEliminarImgPublicacion.reducer;
const getImagenesTemporalesV3Reducer =
  imagenesV3.duckGetImagenesTemporalesV3.reducer;
const eliminacionImgTmpReducer = imagenesV3.duckEliminarImgTmpV3.reducer;
const ordenarImagenesReducer = publicaciones.duckOrdenarImagenes.reducer;
const getUsuarioByCredencialReducer = auth.duckGetUsuarioByCredencial.reducer;
const publicacionesByUserReducer = publicaciones.duckPublicacionesByUser.reducer;
const organizacionByIdReducer = publicaciones.duckOrganizacion.reducer;
const updateUsuarioReducer = auth.duckUpdateUsuario.reducer;
const updatePassByOldPassReducer = auth.duckUpdatePassByOldPass.reducer;
const addVisitaReducer = publicaciones.duckAddVisita.reducer;
const organizacionByUrlReducer = publicaciones.duckOrganizacionByUrl.reducer;
const crearInvitacionReducer = register.duckCrearAutoInvitacion.reducer;
const olvidePassReducer = register.duckOlvide.reducer;
const changePassByTokenReducer = register.duckChangePassByToken.reducer;
const checkResetTokenReducer = register.duckCheckResetToken.reducer;
const getCotizacionActivaReducer = publicaciones.duckCotizacionActiva.reducer;
const urlByDomainReducer = publicaciones.duckUrlByDomain.reducer;

export default combineReducers({
  publicaciones: publicacionesReducer,
  publicaciones2: publicaciones2Reducer,
  marcas: marcasReducer,
  modelos: modelosReducer,
  publicacionDetail: publicacionDetailReducer,
  contenido: contenidoReducer,
  loginStandard: loginStandardReducer,
  loginSocial: loginSocialReducer,
  registro: registroReducer,
  segurosForSelect: segurosForSelectReducer,
  talleresBySeguro: talleresBySeguroReducer,
  addPublicacion: addPublicacionReducer,
  getOrganizaciones: getOrganizacionesReducer,
  publicacionIncompleta: getPublicacionIncompletaReducer,
  updateProductDetails: updateProductDetailsReducer,
  eliminarImgPublicacion: eliminarImgPublicacionReducer,
  imagenesTemporalesV3: getImagenesTemporalesV3Reducer,
  eliminacionImgTmp: eliminacionImgTmpReducer,
  ordenarImagenes: ordenarImagenesReducer,
  getUsuarioByCredencial: getUsuarioByCredencialReducer,
  publicacionesByUser: publicacionesByUserReducer,
  organizacionById: organizacionByIdReducer,
  updateUsuario: updateUsuarioReducer,
  updatePassByOldPass: updatePassByOldPassReducer,
  addVisita: addVisitaReducer,
  organizacionByUrl: organizacionByUrlReducer,
  crearInvitacion: crearInvitacionReducer,
  olvidePass: olvidePassReducer,
  changePassByToken: changePassByTokenReducer,
  checkResetToken: checkResetTokenReducer,
  getCotizacionActiva: getCotizacionActivaReducer,
  urlByDomain: urlByDomainReducer

});
