import createDuck, { createAction, createThunk } from "../../utils/actions";
import axios from "../../net/axios";
import config from "../../config";
let baseUrl = config.api.baseUrl;
const newActions = {
  test: (name) => createAction("NAME", name),
};

const newReducers = {
  NAME: (state, action) => {
    const nState = { data: action.payload, loading: false, error: null };
    return { ...state, ...nState };
  },
};

const duck = createDuck("LOGIN_STANDARD", newActions, newReducers);

const loginStandardThunk = (data) => {
  const url = `${baseUrl}/login`;
  return createThunk(axios, "post", duck.actions, url, data);
};

const duckSocial = createDuck("LOGIN_SOCIAL", newActions, newReducers);

const loginSocialThunk = (data) => {
  const url = `${baseUrl}/login`;
  return createThunk(axios, "post", duckSocial.actions, url, data);
};

const duckGetUsuarioByCredencial = createDuck("GET_USUARIO_BY_CREDENCIAL");

const getUsuarioByCredencialThunk = (credencialId) => {
  const url = `${baseUrl}/usuario/credencial/${credencialId}`;
  return createThunk(
    axios,
    "get",
    duckGetUsuarioByCredencial.actions,
    url,
    null
  );
};

const duckUpdateUsuario = createDuck("UPDATE_USUARIO");

const updateUsuarioThunk = (usuario) => {
  const url = `${baseUrl}/updateUsuarioByCredencial`;
  return createThunk(axios, "post", duckUpdateUsuario.actions, url, usuario);
};

const duckUpdatePassByOldPass = createDuck("UPDATE_CONTRASENA");

const updatePassByOldPassThunk = (params) => {
  const url = `${baseUrl}/updatePassByOldPass`;
  return createThunk(axios, "post", duckUpdatePassByOldPass.actions, url, params);
};

const duckRegistro = createDuck("REGISTRO", newActions, newReducers);

const registroThunk = (data) => {
  const url = `${baseUrl}/signup`;
  return createThunk(axios, "post", duckRegistro.actions, url, data);
};

const exp = {
  duck,
  loginStandardThunk,
  duckSocial,
  loginSocialThunk,
  duckGetUsuarioByCredencial,
  getUsuarioByCredencialThunk,
  duckUpdateUsuario,
  updateUsuarioThunk,
  duckUpdatePassByOldPass,
  updatePassByOldPassThunk,
  duckRegistro,
  registroThunk,  
};

export default exp;
