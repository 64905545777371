/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";
import { getAnalytics, logEvent } from "firebase/analytics";
import ImageGallery from "react-image-gallery";
import { thousandSeparator } from "../../utils/utils";
import Alert from "../../utils/Alert";
import "react-image-gallery/styles/css/image-gallery.css";

const images = {
  van: "/images/ico-van-active.png",
  camioneta: "/images/ico-camioneta-active.png",
  camion: "/images/ico-camion-active.png",
  hatchback: "/images/ico-hatchback-active.png",
  pickup: "/images/ico-pickup-active.png",
  sedan: "/images/ico-sedan-active.png",
  suv: "/images/ico-suv-active.png",
};

const aceptaFinanciacionStrings = {
  [true]: "Ofrece financiación",
  [false]: "No ofrece financiación",
};

const aceptaUsadoStrings = {
  [true]: "Acepta usados como parte de pago",
  [false]: "No acepta usados como parte de pago",
};

function FichaVendedor({
  className,
  publicacion,
  loading,
  latitud,
  longitud,
  history,
  outsideDomain=false
}) {

  return (
    <div className={className}>
      <div className="ficha-title clearfix">
        {publicacion || !loading ? (
          <h3>Información del vendedor</h3>
        ) : (
          <h3>
            <Skeleton />
          </h3>
        )}

        {/* <a href="#" className="link-8">
    Ver perfil
  </a> */}
      </div>
      {publicacion && publicacion.perfil === "SI" && publicacion.perfilLink ? (
        <a
          href=""
          className="info-vendedor"
          onClick={(e) => {
            e.preventDefault();
            if (publicacion.perfil === "SI" && publicacion.perfilLink) {
              history.push(outsideDomain ? "/" : "/organizacion/" + publicacion.perfilLink);
            }
          }}
        >
          {!loading && publicacion ? (
            <div className="avatar">
              <img
                src={
                  publicacion.avatar ? publicacion.avatar : "/images/user.jpg"
                }
                alt="Vendedor"
              />
            </div>
          ) : (
            <div className="avatar">
              <Skeleton height={120} />
            </div>
          )}

          <div className="desc">
            {!loading && publicacion ? (
              <h4 className="name">
                {publicacion?.organizacion
                  ? publicacion?.organizacion
                  : publicacion?.vendedor?.nombre
                  ? publicacion?.vendedor?.nombre
                  : null}
              </h4>
            ) : (
              <h4>
                <Skeleton width={150} />
              </h4>
            )}
            {!loading && publicacion ? (
              <p className="about">
                {publicacion && publicacion.organizacionDesc
                  ? publicacion.organizacionDesc
                  : null}
              </p>
            ) : (
              <p>
                <Skeleton width={150} />
              </p>
            )}
          </div>
        </a>
      ) : (
        <div className="info-vendedor">
          {!loading && publicacion ? (
            <div className="avatar">
              <img
                src={
                  publicacion.avatar ? publicacion.avatar : "/images/user.jpg"
                }
                alt="Vendedor"
              />
            </div>
          ) : (
            <div className="avatar">
              <Skeleton height={120} />
            </div>
          )}

          <div className="desc">
            {!loading && publicacion ? (
              <h4 className="name">
                {publicacion?.organizacion
                  ? publicacion?.organizacion
                  : publicacion?.vendedor?.nombre
                  ? publicacion?.vendedor?.nombre
                  : null}
              </h4>
            ) : (
              <h4>
                <Skeleton width={150} />
              </h4>
            )}
            {!loading && publicacion ? (
              <p className="about">
                {publicacion && publicacion.organizacionDesc
                  ? publicacion.organizacionDesc
                  : null}
              </p>
            ) : (
              <p>
                <Skeleton width={150} />
              </p>
            )}
          </div>
        </div>
      )}

      <div className="ubicacion">
        {!loading && publicacion ? (
          <p className="ciudad">
            {publicacion.address?.direccion
              ? publicacion.address.direccion
              : publicacion?.vendedor?.address?.direccion
              ? publicacion?.vendedor?.address?.direccion
              : null}
          </p>
        ) : (
          <p>
            <Skeleton />
          </p>
        )}

        {!loading && publicacion && !latitud ? null : (
          <div className="mapa">
            {!loading && publicacion ? (
              <iframe
                src={`https://maps.google.com/maps?q=${latitud}, ${longitud}&z=15&output=embed`}
                width="100%"
                height="185"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            ) : (
              <Skeleton height={185} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function PublicacionDetails({ history, publicacion, loading, cotizacion, outsideDomain }) {
  const [imagenes, setImagenes] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const getImage = (cuerpo) => {
    const cuerpoLowerCase = cuerpo ? cuerpo.toLowerCase() : "";
    if (cuerpoLowerCase.includes("hatchback")) {
      return images["hatchback"];
    } else if (cuerpoLowerCase.includes("pickup")) {
      return images["pickup"];
    } else if (cuerpoLowerCase.includes("sedan")) {
      return images["sedan"];
    } else if (cuerpoLowerCase.includes("camioneta")) {
      return images["camioneta"];
    } else if (cuerpoLowerCase.includes("camion")) {
      return images["camion"];
    } else if (cuerpoLowerCase.includes("van")) {
      return images["van"];
    } else {
      return images["suv"];
    }
  };

  useEffect(() => {
    if (publicacion) {
      const imgs = [];

      publicacion.imagenes.forEach((row) => {
        imgs.push({
          original: row.url,
          thumbnail: row.url,
        });
      });

      setImagenes(imgs);
    }
  }, [publicacion]);

  const takeMeToWhatsApp = () => {
    if (publicacion) {
      let analyticsString = null;

      if (publicacion.organizacion) {
        analyticsString = `${publicacion.organizacion}`;
      } else {
        analyticsString = `vendedor_${publicacion.vendedor.nombre}`;
      }

      let telefono = null;

      if (publicacion?.phoneNumber) {
        telefono = publicacion?.phoneNumber;
      } else {
        telefono = publicacion?.vendedor?.phoneNumber;
      }

      const analytics = getAnalytics();
      logEvent(analytics, "contact_action", {
        action: "whatsapp",
      });
      logEvent(analytics, analyticsString, {
        contact: "whatsapp",
      });
      logEvent(analytics, "funnel", {
        action: "whatsapp",
      });
      const link = window.location.href.replace('%20', '-');

      const url = `https://api.whatsapp.com/send?phone=${telefono}&text=Hola, estoy interesado en este anuncio que encontre en VAUN ${publicacion.attrs.marca} ${publicacion.attrs.modelo} ${publicacion.attrs.anho} ${link}`;
      return window.open(url, "_blank");
    }
  };

  const tryCall = () => {
    if (publicacion) {
      let analyticsString = null;

      if (publicacion.organizacion) {
        analyticsString = `${publicacion.organizacion}`;
      } else {
        analyticsString = `vendedor_${publicacion.vendedor.nombre}`;
      }

      let telefono = null;

      if (publicacion?.phoneNumber) {
        telefono = publicacion?.phoneNumber;
      } else {
        telefono = publicacion?.vendedor?.phoneNumber;
      }

      const analytics = getAnalytics();

      logEvent(analytics, "contact_action", {
        action: "call",
      });
      logEvent(analytics, analyticsString, {
        contact: "call",
      });
      logEvent(analytics, "funnel", {
        action: "call",
      });

      const url = `tel:${telefono}`;
      return window.open(url, "_blank");
    }
  };

  let latitud = null;
  let longitud = null;

  if (publicacion) {
    let latitudNoEncontrada = false;
    let longitudNoEncontrada = false;

    if (publicacion.address?.lat) {
      latitud = publicacion.address.lat;
    } else if (publicacion?.vendedor?.address?.lat) {
      latitud = publicacion?.vendedor?.address?.lat;
    } else {
      latitudNoEncontrada = true;
    }

    if (publicacion.address?.lng) {
      longitud = publicacion.address.lng;
    } else if (publicacion?.vendedor?.address?.lng) {
      longitud = publicacion?.vendedor?.address?.lng;
    } else {
      longitudNoEncontrada = true;
    }

    // if (latitudNoEncontrada || longitudNoEncontrada) {
    //   latitud = "35.856737";
    //   longitud = "10.606619";
    // }
  }

  let aceptaFinanciacionText = "";

  if (
    !loading &&
    publicacion &&
    publicacion.aceptaFinanciacion !== undefined &&
    publicacion.aceptaFinanciacion !== null
  ) {
    aceptaFinanciacionText =
      aceptaFinanciacionStrings[publicacion.aceptaFinanciacion];
  }

  let aceptaUsadoText = "";

  if (
    !loading &&
    publicacion &&
    publicacion.aceptaUsado !== undefined &&
    publicacion.aceptaUsado !== null
  ) {
    aceptaUsadoText = aceptaUsadoStrings[publicacion.aceptaUsado];
  }

  return (
    <section className="producto-container">
      <div className="container section-1">
        <div className="row">
          {/* <Loader loaderSize={30} isLoading={publicacion ? false : true}> */}
          <div className="col-lg-7 order-lg-2">
            <div className="prod-img-gallery">
              {imagenes && !loading ? (
                <ImageGallery items={imagenes} />
              ) : (
                <Skeleton height={isMobile ? 300 : 500} />
              )}
            </div>
            <FichaVendedor
              className="ficha-vendedor d-none d-lg-block"
              publicacion={publicacion}
              loading={loading}
              latitud={latitud}
              longitud={longitud}
              history={history}
              outsideDomain={outsideDomain}
            />
          </div>

          <div className="col-lg-5">
            <div className="prod-desc">
              <div className="intro">
                {/* {row.attrs.kilometraje === 0 ? (
                          <div className="details" style={{ marginTop: 10 }}>
                            <span>
                              {thousandSeparator(row.attrs.anho) + " | "}
                            </span>

                            <strong
                              style={{
                                backgroundColor: "#ffc107",
                                borderRadius: 15,
                                paddingLeft: 10,
                                paddingRight: 10,
                              }}
                            >
                              {thousandSeparator(row.attrs.kilometraje) + " km"}
                            </strong>
                          </div>
                        ) : (
                          <div className="details" style={{ marginTop: 10 }}>
                            {thousandSeparator(row.attrs.anho) +
                              " | " +
                              thousandSeparator(row.attrs.kilometraje) +
                              " km"}
                          </div>
                        )} */}

                {!loading && publicacion ? (
                  publicacion.attrs.kilometraje === 0 ? (
                    <div className="details" style={{ marginTop: 10 }}>
                      {/* <span>
                        {thousandSeparator(publicacion.attrs.anho) + " | "}
                      </span> */}

                      <strong
                        style={{
                          backgroundColor: "#ffc107",
                          borderRadius: 15,
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 2,
                          paddingBottom: 2,
                        }}
                      >
                        {publicacion.attrs.anho +
                          " | " +
                          thousandSeparator(publicacion.attrs.kilometraje) +
                          " KM"}
                      </strong>
                    </div>
                  ) : (
                    <div className="details" style={{ marginTop: 10 }}>
                      {publicacion.attrs.anho +
                        " | " +
                        thousandSeparator(publicacion.attrs.kilometraje) +
                        " KM"}
                    </div>
                  )
                ) : (
                  <div className="details">
                    <Skeleton />
                  </div>
                )}

                <h1 className="title" style={{ marginTop: 5 }}>
                  {!loading && publicacion ? (
                    publicacion.attrs.marca + " " + publicacion.attrs.modelo
                  ) : (
                    <Skeleton />
                  )}
                </h1>
                <p className="price">
                  {!loading && publicacion ? (
                    "USD. " + thousandSeparator(publicacion.precio, true)
                  ) : (
                    <Skeleton />
                  )}
                </p>
                <p className="date">
                  {!loading && publicacion ? (
                    "Publicado el " +
                    moment(publicacion.ts).format("YYYY-MM-DD")
                  ) : (
                    <Skeleton />
                  )}
                </p>
                <Alert
                  type="warning"
                  text={
                    "El precio en GS. puede variar de acuedo a la cotizacion del dia"
                  }
                  visible={true}
                  style={{ textAlign: "left" }}
                />
              </div>
              {!loading && publicacion ? (
                <div className="caracteristicas">
                  <ul className="lista-caracteristicas">
                    <li>
                      <figure className="ico">
                        <img
                          style={{ marginBottom: 10 }}
                          src={getImage(publicacion.attrs.cuerpo)}
                          alt="icono"
                        />
                      </figure>{" "}
                      {!loading && publicacion ? (
                        publicacion.attrs.cuerpo
                      ) : (
                        <Skeleton />
                      )}
                    </li>
                    <li>
                      <figure className="ico">
                        <img src="/images/ico-combustible.png" alt="icono" />
                      </figure>{" "}
                      {!loading && publicacion ? (
                        publicacion.attrs.combustible
                      ) : (
                        <Skeleton />
                      )}
                    </li>
                    <li>
                      <figure className="ico">
                        <img src="/images/ico-caja.png" alt="icono" />
                      </figure>{" "}
                      {!loading && publicacion ? (
                        publicacion.attrs.caja
                      ) : (
                        <Skeleton />
                      )}
                    </li>
                  </ul>
                </div>
              ) : (
                <Skeleton height={40} style={{ marginBottom: 10 }} />
              )}

              <div className="full-desc">
                <p>
                  {!loading && publicacion ? (
                    "Versión: " + publicacion.attrs.version
                  ) : (
                    <Skeleton />
                  )}
                </p>
                <p>
                  {!loading && publicacion ? (
                    aceptaFinanciacionText
                  ) : (
                    <Skeleton />
                  )}
                </p>
                <p>
                  {!loading && publicacion ? aceptaUsadoText : <Skeleton />}
                </p>
                {!loading && publicacion ? (
                  <p>
                    {publicacion.attrs.vendedorTipo === "Intermediario"
                      ? "El vendedor no es dueño del vehículo"
                      : "El vendedor es dueño del vehículo"}
                  </p>
                ) : (
                  <p>
                    <Skeleton />
                  </p>
                )}
              </div>
              <div className="ficha-tecnica">
                <h2>
                  {!loading && publicacion ? "Ficha técnica" : <Skeleton />}{" "}
                </h2>
                {!loading && publicacion ? (
                  <ul className="ficha-list">
                    <li>
                      <span className="title">Precio USD:</span>
                      <span className="value">
                        {publicacion ? (
                          thousandSeparator(publicacion.precio, true)
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="title">Precio GS:</span>
                      <span className="value">
                        {publicacion ? (
                          thousandSeparator(
                            publicacion.precio * cotizacion,
                            false
                          )
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="title">Marca:</span>
                      <span className="value">
                        {!loading && publicacion ? (
                          publicacion.attrs.marca
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="title">Modelo:</span>
                      <span className="value">
                        {!loading && publicacion ? (
                          publicacion.attrs.modelo
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="title">Año</span>
                      <span className="value">
                        {!loading && publicacion ? (
                          publicacion.attrs.anho
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </li>

                    <li>
                      <span className="title">Caja:</span>
                      <span className="value">
                        {!loading && publicacion ? (
                          publicacion.attrs.caja
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="title">Tracción:</span>
                      <span className="value">
                        {!loading && publicacion ? (
                          publicacion.attrs.traccion
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="title">Color:</span>
                      <span className="value">
                        {!loading && publicacion ? (
                          publicacion.attrs.color
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="title">Combustible:</span>
                      <span className="value">
                        {!loading && publicacion ? (
                          publicacion.attrs.combustible
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="title">Cuerpo:</span>
                      <span className="value">
                        {!loading && publicacion ? (
                          publicacion.attrs.cuerpo
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="title">Dueños anteriores:</span>
                      <span className="value">
                        {!loading && publicacion ? (
                          publicacion.attrs.duenhos === 3 ? (
                            "3 o más"
                          ) : (
                            publicacion.attrs.duenhos
                          )
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="title">Kilometraje:</span>
                      <span className="value">
                        {!loading && publicacion ? (
                          thousandSeparator(publicacion.attrs.kilometraje)
                        ) : (
                          <Skeleton />
                        )}
                      </span>
                    </li>
                  </ul>
                ) : (
                  <Skeleton height={45} count={11} />
                )}
              </div>
              <div className="acciones">
                {!loading && publicacion ? (
                  <a href={""} onClick={tryCall} className="btn btn-contactar">
                    Llamar
                  </a>
                ) : null}

                {!loading && publicacion ? (
                  <a
                    onClick={takeMeToWhatsApp}
                    href={""}
                    className="btn btn-whatsapp"
                  >
                    <img
                      src="/images/logo-what.png"
                      alt="icono"
                      className="ico-what"
                    />
                    Whatsapp
                  </a>
                ) : null}
              </div>
              {/* <div className="otras">
                <a href="#" className="btn btn-ico">
                  <img src="/images/add-fav.png" alt="icono" className="ico" />
                  Agregar a favoritos
                </a>
                <a href="#" className="btn btn-ico btn-comparar">
                  <img
                    src="/images/add-compare.png"
                    alt="icono"
                    className="ico"
                  />
                  Comparar con otro
                </a>
              </div> */}
            </div>
          </div>

          <FichaVendedor
            className="ficha-vendedor vendedor-mobile d-lg-none mt-3"
            publicacion={publicacion}
            loading={loading}
            latitud={latitud}
            longitud={longitud}
            outsideDomain={outsideDomain}
          />

          {/* </Loader> */}
        </div>
      </div>
    </section>
  );
}

export default PublicacionDetails;
