import React, { useEffect, useState } from "react";
import _ from "lodash";
import Slider from "react-slick";
import { thousandSeparator } from "../../utils/utils";
import SliderTamanhosItem from "./SliderTamanhosItem";
import Loader from "../../utils/Loader";
import { logger, getLinkWithSize } from "../../utils/utils";

const settings = {
  dots: true,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        centerPadding: "60px",
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        arrows: false,
        dots: false,
        centerMode: true,
        centerPadding: "60px",
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        dots: false,
        centerMode: true,
        centerPadding: "80px",
        slidesToShow: 1,
      },
    },
  ],
};
function SliderTamanhos({ history, searchCuerpo, contenido }) {
  return (

    <section className="home-size">
      <div className="section-start">
        <h2>{contenido ? contenido["tamanhos"][0].titulo : ""}</h2>
        <Loader loaderSize={30} isLoading={contenido ? false : true}>
        {contenido && (
          <div className="body-type-row">
            <Slider {...settings}>
              {contenido["tamanhositem"].map((row, index) => (
                <SliderTamanhosItem
                  key={index}
                  searchCuerpo={searchCuerpo}
                  contenido={row}
                />
              ))}
            </Slider>
          </div>
        )}
        </Loader>
      </div>
    </section>
  );
}

export default SliderTamanhos;
