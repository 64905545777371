import React, { useEffect, useState } from "react";
import _ from "lodash";
import { getLinkWithSize, logger, thousandSeparator } from "../../utils/utils";

function SliderTamanhosItem({ history, searchCuerpo, contenido }) {
  const [x, setX] = useState(null);

  const handleMouseDown = (e) => {
    // store mouse position when click starts
    setX(e.screenX);
  };

  const handleClick = (e, contenido) => {
    const delta = Math.abs(e.screenX - x);

    if (delta > 10) {
      // If mouse moved more than threshold, ignore the click event
      e.preventDefault();
    } else {
      searchCuerpo(contenido.titulo, contenido.subtitulo);
    }
    setX(0);
  };

  useEffect(() => {
    const titulo = contenido ? contenido.titulo.toLowerCase() : "";
  }, [contenido]);

  const urlImagenV2 = contenido?.imagenes?.[0]?.url;

  const finalImgUrl = urlImagenV2 ? getLinkWithSize(urlImagenV2, 'medium') : null;

  return (
    <div className="slide">
      <div className="body-type-col">
        <a
          onMouseDown={handleMouseDown}
          onClick={(event) => handleClick(event, contenido)}
          className="body-type"
        >
          <div className="desc">
            <h3 className="title">{contenido.titulo.toUpperCase()}</h3>
            <p className="price">
              Desde{" "}
              <strong>USD. {thousandSeparator(contenido.subtitulo, true)}</strong>
            </p>
          </div>
          <figure className="img">
            <img
              src={finalImgUrl}
              alt={contenido.titulo.toUpperCase()}
              className="img-fluid"
            />
          </figure>
        </a>
      </div>
    </div>
  );
}

export default SliderTamanhosItem;
