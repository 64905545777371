/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import Loader from "../../utils/Loader";
import Alert from "../../utils/Alert";
import { logger } from "../../utils/utils";
import Phone from "../../utils/Phone";

function RegisterRender({
  setPassword,
  setUsername,
  crearInvitacion,
  isLoading,
  submitErrorMsg,
  setPassword2,
  setFullName,
  setPhoneNumber,
  phoneNumber,
  successMsg,
}) {
  return (
    <section className="banner-registro">
      <header className="header1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2">
              <a href="./" className="logo"></a>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid hero-content">
        <div className="row align-items-stretch">
          <div className="col-lg-6">
            <div className="container-5"></div>
          </div>
          <div className="col-lg-6">
            <div className="container-login">
              <h2>
                <strong>Crear una cuenta</strong>
              </h2>

              {successMsg && (
                <Alert
                  type="success"
                  text={successMsg}
                  visible={true}
                  style={{ textAlign: "left" }}
                />
              )}

              {submitErrorMsg && (
                <Alert
                  type="danger"
                  text={submitErrorMsg}
                  visible={true}
                  style={{ textAlign: "left" }}
                />
              )}

              <div className="datos-registro">
                <form action="#">
                  <div className="datos-content">
                    <h3 className="mb-3">Datos personales</h3>
                    <div className="form-group">
                      <label className="sr-only" for="c_nombre">
                        Nombre y apellido
                      </label>
                      <input
                        onChange={(evt) => setFullName(evt.target.value)}
                        type="text"
                        className="form-control"
                        id="c_nombre"
                        placeholder="Nombre completo"
                      />
                    </div>
                    <div className="form-group">
                      <label className="sr-only" for="c_tel">
                        Telefono
                      </label>
                      <Phone
                        value={phoneNumber}
                        setValue={setPhoneNumber}
                        international={false}
                        containerStyle={{}}
                      />
                    </div>
                    <div className="form-group">
                      <label className="sr-only" for="c_email">
                        E-mail
                      </label>
                      <input
                        onChange={(evt) => setUsername(evt.target.value)}
                        type="text"
                        className="form-control"
                        id="c_email"
                        placeholder="E-mail"
                      />
                    </div>
                  </div>
                  <div className="datos-content">
                    <h3 className="mb-3">Datos de la cuenta</h3>
                    <div className="form-group">
                      <label className="sr-only" for="c_pass">
                        Contraseña
                      </label>
                      <input
                        onChange={(evt) => setPassword(evt.target.value)}
                        type="password"
                        className="form-control"
                        id="c_pass"
                        placeholder="Contraseña"
                      />
                    </div>
                    <div className="form-group">
                      <label className="sr-only" for="c_pass2">
                        Reescribir Contraseña
                      </label>
                      <input
                        onChange={(evt) => setPassword2(evt.target.value)}
                        type="password"
                        className="form-control"
                        id="c_pass2"
                        placeholder="Reescribir Contraseña"
                      />
                    </div>
                  </div>

                  <button
                    onClick={() => crearInvitacion()}
                    disabled={isLoading}
                    value="Crear cuenta"
                    className="btn btn-primary dark"
                  >
                    <Loader
                      style={{ paddinTop: 0, paddingBottom: 0 }}
                      isLoading={isLoading}
                    >
                      INGRESAR
                    </Loader>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RegisterRender;
