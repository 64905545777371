import React, { useEffect, useState } from "react";

function FooterOrganizacion({ history, instagram }) {
  return (
    <footer id="footer">
      <div className="container">
        <div className="footer-top">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <ul className="footer-nav">
                {/* <li>
                  <a  onClick={()=>history.push('/resultados')}>USADOS</a>
                </li>
                <li>
                  <a  onClick={()=>history.push('/resultados')}>NUEVOS</a>
                </li> */}
                {/* <li>
                  <a href="">VENDER UN VEHÍCULO</a>
                </li> */}
              </ul>
            </div>
            <div className="col-lg-2">
              <ul className="redes-sociales">
                {/* <li>
                  <a href="" target="_BLANK" title="Seguinos en Twitter">
                    <img src="images/tw.png" alt="Twitter" />
                  </a>
                </li>
                <li>
                  <a href="" target="_BLANK" title="Seguinos en Facebook">
                    <img src="images/fb.png" alt="Facebook" />
                  </a>
                </li> */}
                {instagram && (
                  <li>
                    <a
                      href={instagram}
                      target="_BLANK"
                      title="Seguinos en Instagram"
                      rel="noreferrer"
                    >
                      <img src="/images/instagram.png" alt="Instagram" />
                    </a>
                  </li>
                )}
              </ul>
            </div>
            {/* <div className="col-lg-5 text-right">
              <div className="suscribe">
                <input
                  type="text"
                  placeholder="E-mail"
                  className="suscribe-input"
                />
                <input
                  type="submit"
                  value="Suscribirme"
                  className="btn btn-suscribe"
                />
              </div>
            </div> */}
          </div>
        </div>
        <div className="footer-bottom">
          <a
            style={{color:"#FFFFFF"}}
            href={'https://vaun.com.py'}
            target="_BLANK"
            title="Seguinos en Instagram"
            rel="noreferrer"
          >
            ©2021 | Powered by VAUN
          </a>
        </div>
      </div>
    </footer>
  );
}

export default FooterOrganizacion;
