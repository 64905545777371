import React, { useEffect, useState } from "react";
import _ from "lodash";
import Select from "react-select";

function RenderSearch({ value, setValue, placeholder, label }) {
  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  const [valor, setValor] = useState(null);

  const [focused, setFocused] = useState(false);
  useEffect(() => {
    if (value) {
      setValor(addCommas(removeNonNumeric(value)));
    }
    else{
      setValor('');
    }

  }, [value]);

  const handleChange = (value) => {
    setValor(addCommas(removeNonNumeric(value)));
    setValue(removeNonNumeric(value));
  };

  return (
    <div>
      <div
        style={{
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
          textAlign: "left",
          border: "1px solid #d2dde3",
          borderRadius: "4px",
          marginBottom: 5,
        }}
      >
        <text
          style={{
            color: "#A7A9AC",
            display: "block",
            fontSize: "0.85rem",
            lineHeight: "1rem",
            cursor: "pointer",
            paddingRight: "1.25rem",
            paddingLeft: "0.7rem",
          }}
        >
          {label}
        </text>

        <input
          style={{
            borderWidth: 0,
            width: "100%",
            color: focused ? "#FFD340" : "#2a2a2a",
            fontStyle: "italic",
            fontSize: "1.15rem",
            ":hover": {
              color: "#FFD340",
            },
          }}
          onChange={(evt) => handleChange(evt.target.value)}
          type="text"
          className="form-control"
          placeholder={placeholder}
          value={valor}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </div>
    </div>
  );
}

function FiltrosInputRender({ value, setValue, label, placeholder }) {
  return (
    <RenderSearch
      label={label}
      value={value}
      setValue={setValue}
      placeholder={placeholder}
    />
  );
}

export default FiltrosInputRender;
