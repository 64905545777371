/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import Modal from "react-modal";
import Loader from "../../utils/Loader";
import Header from "../../utils/Header";
import Footer from "../../utils/Footer";
import controller from "../duck.js";
import authController from "../auth/duck";
import { logger, thousandSeparator } from "../../utils/utils";
import { parseJwt } from "../../utils/utils";
import Alert from "../../utils/Alert";
// import { Modal, Button } from "react-bootstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function PageLink({
  pageNumber,
  currentPageNumber,
  handlePagination,
  ellipsis = false,
  itemClassName = "page-item",
  linkClassName = "page-link",
  debugStr = "",
}) {
  let mItemClassName = itemClassName;
  if (!ellipsis) {
    mItemClassName =
      itemClassName + (currentPageNumber === pageNumber ? " active" : "");
  }
  return (
    <li className={mItemClassName}>
      <a
        onClick={(e) => {
          e.preventDefault();
          if (!ellipsis) {
            handlePagination(pageNumber);
          }
        }}
        className={linkClassName}
      >
        {(ellipsis ? "..." : pageNumber) + debugStr}
      </a>
    </li>
  );
}

function DatosVendedorOrganizacion({
  imagenUrl,
  nombre,
  direccion,
  telefono,
  email,
  history,
  lat,
  lng,
}) {
  return (
    <aside className="logo-vendedor mi-cuenta">
      <div className="ficha-vendedor-res">
        <div className="ficha-title clearfix">
          <a
            href=""
            className="info-vendedor"
            onClick={(e) => e.preventDefault()}
          >
            <figure className="avatar">
              <img
                src={imagenUrl ? imagenUrl : "/images/user.jpg"}
                alt="Vendedor"
              />
            </figure>
          </a>
          <h4 className="name-com">{nombre}</h4>
          <div className="ubicacion">
            <p className="ciudad-res">{direccion}</p>
          </div>

          {lat && lng && (
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
              target="_blank"
              className="link"
              rel="noreferrer"
            >
              Ver Ubicación
            </a>
          )}
        </div>
        <div className="acciones">
          <a
            href=""
            className="btn btn-contactar"
            onClick={(e) => {
              e.preventDefault();
              history.push("/editarPerfil");
            }}
          >
            Editar Perfil
            <img src="/images/pencil.png" alt="icono" className="ico-pen" />
          </a>
        </div>
      </div>
      <div className="datos-res">
        {telefono && (
          <div className="titulodato">
            <p>Teléfono</p>
            <h3>{telefono}</h3>
          </div>
        )}
        {email && (
          <div className="titulodato">
            <p>Email</p>
            <h3>{email}</h3>
          </div>
        )}
      </div>
    </aside>
  );
}

export default function MisPublicaciones({ token }) {
  let history = useHistory();
  const dispatch = useDispatch();

  const modelPublicacionesByUser = useSelector(
    (state) => state.publicacionesByUser
  );
  const modelOrganizacion = useSelector((state) => state.organizacionById);
  const modelUsuario = useSelector((state) => state.getUsuarioByCredencial);
  const modelUpdate = useSelector((state) => state.updateProductDetails);
  const modelCotizacion = useSelector((state) => state.getCotizacionActiva);

  const [cotizacion, setCotizacion] = useState(0);
  const [modalErrorMsg, setModalErrorMsg] = useState("");
  const [usuario, setUsuario] = useState(null);
  const [organizacion, setOrganizacion] = useState(null);
  // const [token, setToken] = useState(() => {
  //   const rawToken = localStorage.getItem("token");
  //   return {
  //     raw: rawToken,
  //     decoded: parseJwt(rawToken),
  //   };
  // });
  const [generalErrorMsg, setGeneralErrorMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [sidebarDatos, setSidebarDatos] = useState(null);
  const [publicaciones, setPublicaciones] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [rowSelected, setRowSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [estadoDesc, setEstadoDesc] = useState(null);

  useEffect(() => {
    logger.debug(token);
    if (token.decoded.organizacionID) {
      dispatch(controller.organizacionByIdThunk(token.decoded.organizacionID));
    }
    dispatch(authController.getUsuarioByCredencialThunk(token.decoded.id));
  }, [dispatch, token]);

  useEffect(() => {
    const data = modelCotizacion?.data?.data;
    const error = modelCotizacion?.error?.message;

    if (error) {
      alert(error);
      dispatch(controller.duckCotizacionActiva.actions.clear())
    }
    if (data) {
      setCotizacion(data.data.valor);
    
      const token = localStorage.getItem("token");
      if (!token) {
        history.push("/logout");
      }
      dispatch(controller.getPublicacionesByUserThunk(token, 1, pageSize));
      dispatch(controller.duckCotizacionActiva.actions.clear());
    }
  }, [modelCotizacion]);

  useEffect(() => {
    const data = modelOrganizacion?.data?.data?.data;
    const error = modelOrganizacion?.error;

    if (error) {
      const errorMessage =
        modelOrganizacion?.error?.response?.data?.message ||
        "Ocurrió un error al consultar datos de organización";
      setGeneralErrorMsg(errorMessage);
      dispatch(controller.duckOrganizacion.actions.clear());
    }
    if (data) {
      setOrganizacion(data);
      dispatch(controller.duckOrganizacion.actions.clear());
    }
  }, [modelOrganizacion]);

  useEffect(() => {
    const data = modelUsuario?.data?.data?.data;
    const error = modelUsuario?.error;

    if (error) {
      const errorMessage =
        modelUsuario?.error?.response?.data?.message ||
        "Ocurrió un error al consultar datos de usuario. Por favor, intente nuevamente recargando la página";
      setGeneralErrorMsg(errorMessage);
      dispatch(authController.duckGetUsuarioByCredencial.actions.clear());
    }

    if (data) {
      logger.debug("modelUsuario.useEffect.ifData");
      logger.debug(data);
      setUsuario(data);
      dispatch(authController.duckGetUsuarioByCredencial.actions.clear());
    }
  }, [modelUsuario]);

  useEffect(() => {
    if (token?.decoded?.organizacionID && organizacion) {
      setSidebarDatos({
        imagenUrl: organizacion.imagenes?.[0]?.url,
        nombre: organizacion.name,
        direccion: organizacion.address?.direccion,
        lat: organizacion.address?.lat,
        lng: organizacion.address?.lng,
        telefono: organizacion.phoneNumber,
        email: organizacion.email,
      });
    } else if (usuario) {
      setSidebarDatos({
        imagenUrl: null,
        nombre: usuario.nombre,
        direccion: usuario.address?.direccion,
        lat: usuario.address?.lat,
        lng: usuario.address?.lng,
        telefono: usuario.phoneNumber,
        email: usuario.email,
      });
    }
  }, [usuario, organizacion, token]);

  useEffect(() => {
    const value = Math.ceil(totalResults / pageSize);
    logger.debug({
      "useEffect(totalResults,pageSize)": {
        value,
        totalResults,
        pageSize,
      },
    });
    setPages(value);
  }, [totalResults, pageSize]);

  useEffect(() => {
    const data = modelPublicacionesByUser?.data?.data;
    const error = modelPublicacionesByUser?.error;

    if (error) {
      setLoading(false);
      const errorMessage =
        modelPublicacionesByUser?.error?.response?.data?.message ||
        "Ocurrió un error al consultar datos de publicación. Por favor, intente de nuevo recargando la página.";
      setGeneralErrorMsg(errorMessage);
      dispatch(controller.duckPublicacionesByUser.actions.clear());
    }
    if (data) {
      setLoading(false);
      logger.debug({ publicaciones: data.data.items });
      setPublicaciones(data.data.items);
      setTotalResults(data.data.total);
      dispatch(controller.duckPublicacionesByUser.actions.clear());
    }
  }, [modelPublicacionesByUser]);

  useEffect(() => {
    const data = modelUpdate?.data?.data;
    const error = modelUpdate?.error;

    if (error) {
      const errorMessage =
        modelUpdate?.error?.response?.data?.message ||
        "Ocurrió un error al actualizar publicación. Por favor, intente de nuevo.";
      setGeneralErrorMsg(errorMessage);
      dispatch(controller.duckUpdateProductDetails.actions.clear());
    }
    if (data) {
      handlePagination(1);
      dispatch(controller.duckUpdateProductDetails.actions.clear());
      const analytics = getAnalytics();
      logEvent(analytics, "estadisticas", {
        action: "publicacionesEliminadas",
      });
    }
  }, [modelUpdate]);

  useEffect(() => {
    dispatch(controller.getCotizacionActivaThunk());
  }, []);

  function handlePagination(page) {
    setLoading(true);
    setActivePage(page);
    const token = localStorage.getItem("token");
    setPublicaciones([]);
    dispatch(controller.getPublicacionesByUserThunk(token, page, pageSize));
  }

  function eliminar() {
    if (!rowSelected) {
      setGeneralErrorMsg("No se encontró elemento seleccionado");
      return;
    }
    if (!estadoDesc) {
      setModalErrorMsg("Por favor complete el motivo");
      return;
    }
    const oldPublicacion = { ...rowSelected };

    const token = localStorage.getItem("token");
    const decodedToken = parseJwt(token);

    oldPublicacion.estado = "ELIMINADO";
    oldPublicacion.estadoDesc = estadoDesc;
    oldPublicacion.verifiedBy = decodedToken.id;

    dispatch(controller.updateProductDetailsThunk(oldPublicacion));
    setShowModal(false);
    setEstadoDesc(null);
    setRowSelected(null);
  }

  const getPageLinks = (totalPages, activePage, handlePagination) => {
    let pageLinks = [];

    const pageLinkLimit =
      activePage + 1 <= totalPages ? activePage + 1 : totalPages;

    const pageLinkStart = activePage - 1 >= 1 ? activePage - 1 : 1;


    if (activePage - 1 === 2) {
      const pageLink = (
        <PageLink
          key={1}
          pageNumber={1}
          currentPageNumber={activePage}
          handlePagination={handlePagination}
          // debugStr={"a"}
        />
      );
      pageLinks.push(pageLink);
    } else if (activePage > 3) {
      const pageLink = (
        <PageLink
          key={1}
          pageNumber={1}
          currentPageNumber={activePage}
          handlePagination={handlePagination}
          // debugStr={"b"}
        />
      );
      pageLinks.push(pageLink);
    }

    if (activePage === 4) {
      const pageLink = (
        <PageLink
          key={2}
          pageNumber={2}
          currentPageNumber={activePage}
          handlePagination={handlePagination}
          // debugStr={"c"}
        />
      );
      pageLinks.push(pageLink);
    } else if (activePage > 4) {
      const ellipsis = <PageLink key={2} ellipsis={true} />;
      pageLinks.push(ellipsis);
    }

    for (
      let pageNumber = pageLinkStart;
      pageNumber <= pageLinkLimit;
      pageNumber++
    ) {
      const pageLink = (
        <PageLink
          key={pageNumber}
          pageNumber={pageNumber}
          currentPageNumber={activePage}
          handlePagination={handlePagination}
        />
      );
      pageLinks.push(pageLink);
    }

    if (activePage + 3 < totalPages) {
      const ellipsis = <PageLink key={activePage + 2} ellipsis={true} />;
      pageLinks.push(ellipsis);
    } else if (activePage + 3 === totalPages) {
      const pageLink = (
        <PageLink
          key={totalPages - 1}
          pageNumber={totalPages - 1}
          currentPageNumber={activePage}
          handlePagination={handlePagination}
          // debugStr={"d"}
        />
      );
      pageLinks.push(pageLink);
    }

    if (activePage + 1 < totalPages) {
      const lastPageLink = (
        <PageLink
          key={totalPages}
          pageNumber={totalPages}
          currentPageNumber={activePage}
          handlePagination={handlePagination}
          // debugStr={"e"}
        />
      );
      pageLinks.push(lastPageLink);
    }

    return pageLinks;
  };

  logger.debug({
    renderConsoleLog: {
      pageSize,
      activePage,
      pages,
      totalResults,
    },
  });

  return (
    <React.Fragment>
      <Modal
        ariaHideApp={false}
        isOpen={showModal}
        onRequestClose={() => {
          setShowModal(false);
          setModalErrorMsg("");
          setEstadoDesc(null);
          setRowSelected(null);
        }}
        style={customStyles}
        contentLabel="Por favor indique el motivo por el cual la publicación será eliminada"
      >
        <React.Fragment>
          {modalErrorMsg && (
            <Alert
              type="danger"
              text={modalErrorMsg}
              visible={true}
              style={{ textAlign: "left" }}
            />
          )}
          <div className="drop-down-trigger drop-down-filter">
            <label>Motivo por el cual se elimina</label>
            <input
              type="text"
              className="input-text"
              // placeholder="Ej. no disponible"
              // value={version}
              onChange={(evt) => setEstadoDesc(evt.target.value)}
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ float: "right" }}>
            <button
              onClick={() => eliminar()}
              className="btn btn-primary dark"
              // disabled={modelUpdate.loading}
            >
              Eliminar
            </button>
          </div>
        </React.Fragment>
      </Modal>
      <Header history={history} />
      <section className="resultados-busqueda">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              {sidebarDatos && (
                <DatosVendedorOrganizacion
                  imagenUrl={sidebarDatos.imagenUrl}
                  nombre={sidebarDatos.nombre}
                  direccion={sidebarDatos.direccion}
                  lat={sidebarDatos.lat}
                  lng={sidebarDatos.lng}
                  telefono={sidebarDatos.telefono}
                  email={sidebarDatos.email}
                  history={history}
                />
              )}
            </div>
            <div className="col-lg-9">
              <section className="resultados-list">
                {generalErrorMsg && (
                  <Alert
                    type="danger"
                    text={generalErrorMsg}
                    visible={true}
                    style={{ textAlign: "left" }}
                  />
                )}
                <h1>Publicaciones</h1>
                <div className="filtrar-pubs clearfix">
                  <div className="buscador">
                    {/* <form id="busq" action="" method="post">
                      <div className="cont-publi">
                        <input
                          type="text"
                          placeholder="Buscar una Publicacion"
                        />
                      </div>
                      <div className="buscar-publi">
                        <input type="submit" value="" />
                      </div>
                    </form> */}
                  </div>
                  <div className="header-list clearfix">
                    <p className="orderby">
                      {/* <strong>Ordenar por</strong>
                      <select name="" id="">
                        <option value="">Fecha</option>
                        <option value="">Nombre</option>
                      </select> */}
                    </p>
                  </div>
                </div>
                <div className="container-favoritos">
                  <Loader isLoading={loading || modelUpdate.loading}>
                    {publicaciones &&
                      publicaciones.length > 0 &&
                      publicaciones.map((publicacion) => (
                        <div className="product-item" key={publicacion._id}>
                          <figure className="img">
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                if (publicacion.estado === "VERIFICADO") {
                                  history.push(
                                    `/publicacion/${publicacion.attrs.marca}-${publicacion.attrs.modelo.replace('/', '_')}-${publicacion.attrs.anho}-${publicacion.attrs.kilometraje}/${publicacion._id}`
                                  );
                                }
                              }}
                              className="img-link"
                            >
                              {publicacion.imagenes?.[0]?.url ? (
                                <img
                                  src={publicacion.imagenes?.[0]?.url}
                                  alt=""
                                />
                              ) : (
                                "Sin imagen"
                              )}
                            </a>
                          </figure>
                          <div className="desc">
                            <text className="titulo-dia">
                              <strong
                                style={{
                                  backgroundColor: "#ffc107",
                                  borderRadius: 15,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  fontSize: 12,
                                }}
                              >
                                {publicacion.estado}
                              </strong>
                            </text>

                            <text
                              className="titulo-dia"
                              style={{float:'right' }}
                            >
                              <strong
                                style={{
                                  backgroundColor: "#ffc107",
                                  borderRadius: 15,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  paddingTop: 2,
                                  paddingBottom: 2,
                                  fontSize: 12,
                                }}
                              >
                                Visitas: {publicacion.visitas}
                              </strong>
                            </text>

                            <h4 className="title">
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (publicacion.estado === "VERIFICADO") {
                                    history.push(
                                      `/publicacion/${publicacion.attrs.marca}-${publicacion.attrs.modelo.replace('/', '_')}-${publicacion.attrs.anho}-${publicacion.attrs.kilometraje}/${publicacion._id}`
                                    );
                                  }
                                }}
                              >
                                {publicacion.nombre}
                              </a>
                              <span className="details">
                                {publicacion.attrs.anho}
                                {publicacion.attrs?.kilometraje
                                  ? " | " +
                                    thousandSeparator(
                                      publicacion.attrs.kilometraje
                                    ) +
                                    " km."
                                  : "0 km."}
                              </span>
                            </h4>
                            <p className="price">
                              USD.{" "}
                              {publicacion.precio
                                ? thousandSeparator(publicacion.precio, true)
                                : "- - -"}
                            </p>
                            <div className="bottom">
                              <div className="bottom-item">
                                <img
                                  src="/images/ico-combustible.png"
                                  alt="Combulstible"
                                  className="ico"
                                />
                                {publicacion.attrs.combustible}
                              </div>
                              <div className="bottom-item">
                                <img
                                  src="/images/ico-caja.png"
                                  alt="Caja"
                                  className="ico"
                                />
                                {publicacion.attrs.caja}
                              </div>
                            </div>
                          </div>
                          <div className="boton-comparar">
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(
                                  `/publicar/detalles/${publicacion._id}`
                                );
                              }}
                              className="btn btn-primary dark"
                            >
                              Editar
                            </a>
                            <a
                              href=""
                              className="btn btn-secondary btn-eliminar"
                              onClick={(e) => {
                                e.preventDefault();
                                setRowSelected(publicacion);
                                setShowModal(true);
                              }}
                            >
                              Eliminar
                            </a>
                          </div>
                        </div>
                      ))}
                  </Loader>
                  {publicaciones &&
                    publicaciones.length === 0 &&
                    !modelPublicacionesByUser.loading &&
                    "Sin publicaciones para mostrar."}
                </div>
                {publicaciones && publicaciones.length > 0 && (
                  <div className="footer-list clearfix">
                    {totalResults > 0 ? (
                      <p className="total-resultados">
                        <strong>{totalResults}</strong>{" "}
                        {totalResults === 1 ? "Resultado" : "Resultados"}
                      </p>
                    ) : null}

                    <nav
                      className="paginacion-resultados"
                      // style={{ display: "flex" }}
                    >
                      <ul className="pagination">
                        {activePage !== 1 && (
                          <div className="page-item">
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                handlePagination(activePage - 1);
                              }}
                              className="page-link prev"
                              aria-label="Previous"
                            >
                              <span className="sr-only">Anterior</span>
                            </a>
                          </div>
                        )}

                        {getPageLinks(pages, activePage, handlePagination)}

                        {activePage !== pages ? (
                          <li className="page-item">
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                handlePagination(activePage + 1);
                              }}
                              className="page-link next"
                              aria-label="Next"
                            >
                              <span className="sr-only">Siguiente</span>
                            </a>
                          </li>
                        ) : null}
                      </ul>
                    </nav>
                  </div>
                )}
              </section>
            </div>
          </div>
        </div>
      </section>
      <Footer history={history} />
    </React.Fragment>
  );
}
