import React, { useEffect, useState } from "react";
import _ from "lodash";
import Loader from "../../utils/Loader";

function Encontra({ history, searchCategoria, contenido }) {
  const contenidoArray = contenido ? contenido["estilo de vida"] : null;
  return (
    <section className="smart-categories">
      <div className="container-fluid section-2">
        <Loader loaderSize={30} isLoading={contenidoArray ? false : true}>
          {contenidoArray ? (
            <div className="row gutter-7">
              <div className="col-12 mobile-title">
                <div className="intro">
                  <p className="caption">ESTILO DE VIDA</p>
                  <h2>
                    Encontrá <br />
                    el auto que <br />
                    necesitabas
                  </h2>
                </div>
              </div>
              <div className="col-lg-2 col-md-3">
                <div
                  className="category-item"
                  style={{ backgroundImage: "url(/images/bg-smart.jpg)" }}
                >
                  <div className="desc">
                    <h4 className="title">{contenidoArray[0].titulo}</h4>
                    <p>{contenidoArray[0].subtitulo}</p>
                    <a
                      onClick={() => {
                        let searchString = contenidoArray[0].action;
                        searchCategoria(searchString, 'encontra');
                      }}
                      className="btn btn-primary"
                      style={{ color: "#1C2830" }}
                    >
                      VER MÁS
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-3">
                <div
                  className="category-item"
                  style={{ backgroundImage: "url(/images/bg-smart-2.jpg)" }}
                >
                  <div className="desc">
                    <h4 className="title">{contenidoArray[1].titulo}</h4>
                    <p>{contenidoArray[1].subtitulo}</p>
                    <a
                      onClick={() => {
                        let searchString = contenidoArray[1].action;
                        searchCategoria(searchString, 'encontra');
                      }}
                      className="btn btn-primary"
                      style={{ color: "#1C2830" }}
                    >
                      VER MÁS
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-3">
                <div
                  className="category-item"
                  style={{ backgroundImage: "url(/images/bg-smart.jpg)" }}
                >
                  <div className="desc">
                    <h4 className="title">{contenidoArray[2].titulo}</h4>
                    <p>{contenidoArray[2].subtitulo}</p>
                    <a
                      onClick={() => {
                        let searchString = contenidoArray[2].action;
                        searchCategoria(searchString, 'encontra');
                      }}
                      className="btn btn-primary"
                      style={{ color: "#1C2830" }}
                    >
                      VER MÁS
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 desktop-title">
                <div className="intro">
                  <p className="caption">ESTILO DE VIDA</p>
                  <h2>
                    Encontrá <br />
                    el auto que <br />
                    necesitabas
                  </h2>
                </div>
              </div>
              {/* {contenidoArray[3] ? (
                <div className="col-lg-4 col-md-4">
                  <div
                    className="category-item"
                    style={{ backgroundImage: "url(/images/bg-smart-2.jpg)" }}
                  >
                    <div className="desc">
                      <h4 className="title">{contenidoArray[3].titulo}</h4>
                      <p>{contenidoArray[3].subtitulo}</p>
                      <a
                        onClick={() => {
                          let searchString = contenidoArray[3].action;
                          searchCategoria(searchString, 'encontra');
                        }}
                        className="btn btn-primary"
                        style={{ color: "#1C2830" }}
                      >
                        VER MÁS
                      </a>
                    </div>
                  </div>
                </div>
              ) : null}
              {contenidoArray[4] ? (
                <div className="col-lg-2 col-md-4">
                  <div
                    className="category-item"
                    style={{ backgroundImage: "url(/images/bg-smart.jpg)" }}
                  >
                    <div className="desc">
                      <h4 className="title">{contenidoArray[4].titulo}</h4>
                      <p>{contenidoArray[4].subtitulo}</p>
                      <a
                        onClick={() => {
                          let searchString = contenidoArray[4].action;
                          searchCategoria(searchString, 'encontra');
                        }}
                        className="btn btn-primary"
                        style={{ color: "#1C2830" }}
                      >
                        VER MÁS
                      </a>
                    </div>
                  </div>
                </div>
              ) : null}
              {contenidoArray[5] ? (
                <div className="col-lg-4 col-md-4">
                  <div
                    className="category-item"
                    style={{ backgroundImage: "url(/images/bg-smart-2.jpg)" }}
                  >
                    <div className="desc">
                      <h4 className="title">{contenidoArray[5].titulo}</h4>
                      <p>{contenidoArray[5].subtitulo}</p>
                      <a
                        onClick={() => {
                          let searchString = contenidoArray[5].action;
                          searchCategoria(searchString, 'encontra');
                        }}
                        className="btn btn-primary"
                        style={{ color: "#1C2830" }}
                      >
                        VER MÁS
                      </a>
                    </div>
                  </div>
                </div>
              ) : null} */}
            </div>
          ) : null}
        </Loader>
      </div>
    </section>
  );
}

export default Encontra;
