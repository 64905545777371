import React, { useEffect, useState } from "react";
import _ from "lodash";
import { thousandSeparator } from "../../utils/utils";

import { logger, getLinkWithSize } from "../../utils/utils";

function PopularesItem({ history, searchCategoria, item }) {

  const urlImagenV2 = item?.imagenes?.[0]?.url;
  // const finalImgUrl = urlImagenV2 ? getLinkWithSize(urlImagenV2, 'small') : null;
  const finalImgUrl = urlImagenV2;
  
  return (
    <div className="col-lg-4">
      <a
        onClick={() => {
          let searchString = item["action"];
          searchCategoria(searchString, 'populares');
        }}
        className="modelo-destacado"
      >
        <figure className="img">
          <img
            src={finalImgUrl}
            className="img-fluid"
            alt="Modelo Popular"
          />
        </figure>
        <div className="desc">
          <h3 className="title">{item["titulo"]}</h3>
          <p className="price">
            Desde{" "}
            <strong>USD. {thousandSeparator(item["subtitulo"], true)}</strong>
          </p>
        </div>
      </a>
    </div>
  );
}

export default PopularesItem;
