import React, { useEffect, useState } from "react";
import _ from "lodash";
import FiltrosView from "./Filtros.view";

function Filtros({
  marcas,
  setMarca,
  marca,
  modelo,
  setModelo,
  modelos,
  setActivePage,
  searchPublicaciones,
  perfil,
  tipoVendedor,
  setTipoVendedor,
  combustible,
  setCombustible,
  caja,
  setCaja,
  duenhos,
  setDuenhos,
  setKilometrajeMax,
  setKilometrajeMin,
  cuerpo,
  setCuerpo,
  setPrecioMin,
  setPrecioMax,
  handleFilter,
  anhos,
  setAnhos,
  loadingMarcas,
  loadingModelos,
  color,
  setColor,
  precioDesc,
  setPrecioDesc,
  precioMin,
  precioMax,
  kilometrajeMax,
  kilometrajeMin,
  uso,
  setUso,
  outsideDomain,
}) {
  const [kilometrajeDesc, setKilometrajeDesc] = useState(null);

  const [activeFilters, setActiveFilters] = useState([]);

  function handleFilters(filter) {
    const result = activeFilters.includes(filter);
    if (result) {
      setActiveFilters(activeFilters.filter((item) => item !== filter));
    } else {
      setActiveFilters((activeFilters) => [...activeFilters, filter]);
    }
  }

  function getFilter(filter) {
    const result = activeFilters.includes(filter);
    return result;
  }

  function handleKilometraje(value) {
    const values = value.value.split("-");
    setKilometrajeDesc(value);
    setKilometrajeMax(values[1]);
    setKilometrajeMin(values[0]);
  }

  function handleMarca(value) {
    setMarca(value);
    setModelo(null);
  }

  function handleUso(value) {
    const result = uso.includes(value);
    if (result) {
      setUso(uso.filter((item) => item !== value));
      setKilometrajeMin(null);
      setKilometrajeMax(null);
    } else {
      setUso((uso) => [value]);
      // if(value==='Usados' && !uso.includes('0 KM')){
      //   setKilometrajeMin(1);
      //   setKilometrajeMax(null);
      // }
      // else if(value==='0 KM' && !uso.includes('Usados')){
      //   setKilometrajeMin('0');
      //   setKilometrajeMax('0');
      // }
      if (value === "0 KM") {
        setKilometrajeMin("0");
        setKilometrajeMax(null);
      } else if (value === "Usados") {
        setKilometrajeMin("1");
        setKilometrajeMax(null);
      }
    }
  }

  function handleSelectAnhos(selected) {
    const isAlreadyIn = anhos.filter((element) => element == selected);
    if (isAlreadyIn.length > 0) {
      const newAnhos = anhos.filter((element) => element != selected);
      setAnhos(newAnhos);
    } else {
      setAnhos([...anhos, selected]);
    }
  }

  function handleSelectColor(selected) {
    const isAlreadyIn = color.filter(
      (element) => element.value == selected.value
    );
    if (isAlreadyIn.length > 0) {
      const newColor = color.filter(
        (element) => element.value != selected.value
      );
      setColor(newColor);
    } else {
      setColor([...color, selected]);
    }
  }

  function handleSelectCuerpo(selected) {
    const isAlreadyIn = cuerpo.filter(
      (element) => element.value == selected.value
    );
    if (isAlreadyIn.length > 0) {
      const newCuerpo = cuerpo.filter(
        (element) => element.value != selected.value
      );
      setCuerpo(newCuerpo);
    } else {
      setCuerpo([...cuerpo, selected]);
    }
  }

  function clearFilters() {
    setMarca(null);
    setModelo(null);
    setTipoVendedor(null);
    setCombustible(null);
    setCaja(null);
    setDuenhos(null);
    setKilometrajeMax(null);
    setKilometrajeMin(null);
    setKilometrajeDesc(null);
    setCuerpo([]);
    setPrecioDesc(null);
    setPrecioMin(null);
    setPrecioMax(null);
    setColor([]);
    setAnhos([]);
    setUso([]);
  }

  return (
    <FiltrosView
      outsideDomain={outsideDomain}
      loadingMarcas={loadingMarcas}
      loadingModelos={loadingModelos}
      perfil={perfil}
      setMarca={handleMarca}
      setModelo={setModelo}
      setTipoVendedor={setTipoVendedor}
      setCombustible={setCombustible}
      setCaja={setCaja}
      setDuenhos={setDuenhos}
      setKilometrajeMax={setKilometrajeMax}
      setKilometrajeMin={setKilometrajeMin}
      setKilometrajeDesc={setKilometrajeDesc}
      setCuerpo={setCuerpo}
      setPrecioDesc={setPrecioDesc}
      setPrecioMin={setPrecioMin}
      setPrecioMax={setPrecioMax}
      marca={marca}
      modelo={modelo}
      tipoVendedor={tipoVendedor}
      combustible={combustible}
      caja={caja}
      duenhos={duenhos}
      kilometrajeDesc={kilometrajeDesc}
      cuerpo={cuerpo}
      precioDesc={precioDesc}
      handleFilters={handleFilters}
      getFilter={getFilter}
      marcas={marcas}
      modelos={modelos}
      handleKilometraje={handleKilometraje}
      setActivePage={setActivePage}
      searchPublicaciones={searchPublicaciones}
      clearFilters={clearFilters}
      handleFilter={handleFilter}
      handleSelectAnhos={handleSelectAnhos}
      handleSelectColor={handleSelectColor}
      handleSelectCuerpo={handleSelectCuerpo}
      handleUso={handleUso}
      anhos={anhos}
      setAnhos={setAnhos}
      color={color}
      setColor={setColor}
      precioMin={precioMin}
      precioMax={precioMax}
      kilometrajeMax={kilometrajeMax}
      kilometrajeMin={kilometrajeMin}
      uso={uso}
      setUso={setUso}
    />
  );
}

export default Filtros;
