import React, { useEffect, useState } from "react";
import _ from "lodash";
import Header from "../../utils/Header";
import HeaderOrganizacion from "../../utils/HeaderOrganizacion";
import Filtros from "./Filtros";
import Footer from "../../utils/Footer";
import FooterOrganizacion from "../../utils/FooterOrganizacion";
import Results from "./Results";
function ResultadosView({
  instagram,
  colorPrincipal,
  colorSecundario,
  cotizacion,
  history,
  marcas,
  setMarca,
  marca,
  modelos,
  modelo,
  setModelo,
  setActivePage,
  searchPublicaciones,
  tipoVendedor,
  setTipoVendedor,
  combustible,
  setCombustible,
  caja,
  setCaja,
  duenhos,
  setDuenhos,
  kilometrajeMax,
  setKilometrajeMax,
  kilometrajeMin,
  setKilometrajeMin,
  cuerpo,
  setCuerpo,
  precioMax,
  setPrecioMax,
  setPrecioMin,
  precioMin,
  results,
  totalResults,
  activePage,
  orderby,
  setOrderBy,
  pageSize,
  searchPublicacionesQueryParams,
  handleFilter,
  perfil = false,
  isLoading,
  anhos,
  setAnhos,
  loadingMarcas,
  loadingModelos,
  color,
  setColor,
  precioDesc,
  setPrecioDesc,
  sugerencias,
  uso,
  setUso,
  outsideDomain = false
}) {
  return (
    <React.Fragment>
      <a onClick={() => handleFilter()} href="#" className="btn-filtrar">
        Filtrar
      </a>
      {!outsideDomain ? <Header history={history} /> : <HeaderOrganizacion history={history} organizacion={perfil}  colorPrincipal={colorPrincipal} colorSecundario={colorSecundario}/> }
      <section className="resultados-busqueda">
        <div className="container">
          <div className="row">
            <Filtros
              precioDesc={precioDesc}
              setPrecioDesc={setPrecioDesc}
              anhos={anhos}
              setAnhos={setAnhos}
              history={history}
              marcas={marcas}
              setMarca={setMarca}
              marca={marca}
              modelos={modelos}
              modelo={modelo}
              setModelo={setModelo}
              setActivePage={setActivePage}
              searchPublicaciones={searchPublicaciones}
              tipoVendedor={tipoVendedor}
              setTipoVendedor={setTipoVendedor}
              combustible={combustible}
              setCombustible={setCombustible}
              caja={caja}
              setCaja={setCaja}
              duenhos={duenhos}
              setDuenhos={setDuenhos}
              kilometrajeMax={kilometrajeMax}
              setKilometrajeMax={setKilometrajeMax}
              kilometrajeMin={kilometrajeMin}
              setKilometrajeMin={setKilometrajeMin}
              cuerpo={cuerpo}
              setCuerpo={setCuerpo}
              precioMax={precioMax}
              setPrecioMax={setPrecioMax}
              setPrecioMin={setPrecioMin}
              precioMin={precioMin}
              handleFilter={handleFilter}
              perfil={perfil}
              loadingModelos={loadingModelos}
              loadingMarcas={loadingMarcas}
              color={color}
              setColor={setColor}
              uso={uso}
              setUso={setUso}
              outsideDomain={outsideDomain}
            />
            <Results
              cotizacion={cotizacion}
              history={history}
              publicaciones={results}
              totalResults={totalResults}
              activePage={activePage}
              orderby={orderby}
              setOrderBy={setOrderBy}
              limit={pageSize}
              handlePagination={searchPublicacionesQueryParams}
              isLoading={isLoading}
              sugerencias={sugerencias}
            />
          </div>
        </div>
      </section>
      {/* <BannerVenderContraido history={history} /> */}
      {!outsideDomain ? <Footer history={history} /> : <FooterOrganizacion history={history} instagram={instagram}/>}
    </React.Fragment>
  );
}

export default ResultadosView;
