import React, { useEffect, useState } from "react";

const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || "vaun.com.py";

function Logout() {
  useEffect(() => {
    document.cookie =
      "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=" +
      COOKIE_DOMAIN;

    localStorage.removeItem("token");
    const url = '/login';

    window.location.assign(url);
  }, []);

  return <div />;
}

export default Logout;
