import createDuck, { createAction, createThunk } from "../../utils/actions";
import axios from "../../net/axios";
import config from "../../config";
let baseUrl = config.api.baseUrl;
const newActions = {
  test: (name) => createAction("NAME", name),
};

const newReducers = {
  NAME: (state, action) => {
    const nState = { data: action.payload, loading: false, error: null };
    return { ...state, ...nState };
  },
};

const duckCrearAutoInvitacion = createDuck("AUTOINVITACION_CREAR", newActions, newReducers);

const crearAutoInvitacionThunk = (data) => {
  const url = `${baseUrl}/crearAutoInvitacion/`;
  return createThunk(axios, "post", duckCrearAutoInvitacion.actions, url, data);
};

const duckRegistro = createDuck("REGISTRO2", newActions, newReducers);

const registroThunk = (data) => {
  const url = `${baseUrl}/signup`;
  return createThunk(axios, "post", duckRegistro.actions, url, data);
};

const duckOlvide = createDuck("OLVIDE_PASS", newActions, newReducers);

const olvidePassThunk = (data) => {
  const url = `${baseUrl}/olvidePass`;
  return createThunk(axios, "post", duckOlvide.actions, url, data);
};

const duckChangePassByToken = createDuck("CHANGE_PASS_BY_TOKEN", newActions, newReducers);

const changePassByTokenThunk = (data) => {
  const url = `${baseUrl}/updatePassByResetToken`;
  return createThunk(axios, "post", duckChangePassByToken.actions, url, data);
};

const duckCheckResetToken = createDuck("CHECK_RESET_TOKEN", newActions, newReducers);

const checkResetTokenThunk = (data) => {
  const url = `${baseUrl}/checkResetToken`;
  return createThunk(axios, "post", duckCheckResetToken.actions, url, {resetToken:data});
};

const exp = {
  duckCrearAutoInvitacion,
  crearAutoInvitacionThunk,  
  duckRegistro,
  registroThunk,
  duckOlvide,
  olvidePassThunk,
  duckChangePassByToken,
  changePassByTokenThunk,
  duckCheckResetToken,
  checkResetTokenThunk,
};

export default exp;
