/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import moment from "moment";
import Select from "react-select";
import { logger } from "../../utils/utils";
import { useHistory } from "react-router";
import queryString from "query-string";

function RenderSearch({
  customStyles,
  value,
  setValue,
  options,
  label,
  isLoading,
  noOptionsMessage,
  placeholder = "Seleccionar",
}) {
  return (
    <div
      className="subseleccionarContainer"
      style={{ paddingTop: "1rem", paddingBottom: "1.25rem", width: "100%" }}
    >
      <div className="subseleccionar vender">
        <text
          style={{
            color: "#A7A9AC",
            display: "block",
            fontSize: "0.85rem",
            lineHeight: "1rem",
            cursor: "pointer",
            paddingRight: "1.25rem",
            paddingLeft: "1.25rem",
          }}
        >
          {label}
        </text>

        <Select
          // isClearable
          noOptionsMessage={() => noOptionsMessage}
          isLoading={isLoading}
          styles={customStyles}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => (
              <img
                style={{ width: 18, height: 10, marginRight: 20 }}
                src="/images/down.png"
                alt=""
              />
            ),
          }}
          value={value}
          onChange={(selected) => {
            setValue(selected);
          }}
          isSearchable
          placeholder={placeholder}
          options={options}
        />
      </div>
    </div>
  );
}

function VenderRenderTop({
  marcas,
  marca,
  setMarca,
  modelos,
  modelo,
  setModelo,
  anho,
  setAnho,
  loadingMarcas,
  loadingModelos,
}) {
  let history = useHistory();

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      paddingRight: isMobile ? "0.5rem" : "1.25rem",
      paddingLeft: isMobile ? "0.5rem" : "1.25rem",
      fontStyle: "italic",
      fontSize: "1.15rem",
      ":hover": {
        color: "#FFD340",
      },
    }),
    dropdownIndicator: (base) => {
      return {
        ...base,
        color: "#2a2a2a", // Custom colour
      };
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: state.isFocused ? "#FFD340" : "#2a2a2a",
        fontStyle: "italic",
        fontSize: "1.15rem",
        ":hover": {
          color: "#FFD340",
        },
      };
    },

    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
      marginRight: isMobile ? "0.5rem" : 0,
      marginLeft: isMobile ? "0.5rem" : 0,
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "none",
      color: state.isFocused && "#2a2a2a",
      borderBottom: "1px solid #d2dde3",
      paddingRight: isMobile ? "1.1rem" : "1.3rem",
      paddingLeft: isMobile ? "1.1rem" : "1.3rem",
    }),
  };

  function handleMarca(selected) {
    setMarca(selected);
    setModelo(null);
  }

  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  const [years, setYears] = useState(getYearsFromSomeYear(1990));

  function getYearsFromSomeYear(initialYear) {
    const finalYear = moment().format("YYYY");
    const yearsAux = [];
    for (let i = initialYear; i <= Number(finalYear) + 1; i++) {
      if (i >= 1990) {
        const option = {
          value: i,
          label: i,
        };
        yearsAux.push(option);
      }
    }
    return yearsAux.reverse();
  }

  return (
    <section className="banner-vende vender-hero">
      <div className="container hero-content">
        <div className="row">
          <div className="col-lg-7">
            <h2>
              <strong>¿Qué vehículo querés vender?</strong>
            </h2>
            <div className="home-search vender">
              <RenderSearch
                placeholder="Seleccionar"
                noOptionsMessage="Marcas no encontradas"
                isLoading={loadingMarcas}
                isMobile={isMobile}
                width={width}
                label="Marca"
                customStyles={customStyles}
                value={marca}
                setValue={handleMarca}
                options={marcas}
              />
              <RenderSearch
                placeholder="Seleccionar"
                noOptionsMessage="Modelos no encotnrados"
                isLoading={loadingModelos}
                isMobile={isMobile}
                width={width}
                label="Modelo"
                customStyles={customStyles}
                value={modelo}
                setValue={setModelo}
                options={modelos}
              />
              <RenderSearch
                noOptionsMessage="Años no encontrados"
                isMobile={isMobile}
                width={width}
                label="Año"
                customStyles={customStyles}
                value={anho}
                setValue={setAnho}
                options={years}
              />
            </div>
            <div className="btn_container">
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  logger.debug({marca, modelo, anho});
                  const nextUrlParams = queryString.stringify({
                    marca: marca.value,
                    modelo: modelo.value,
                    anho: anho.value,
                  });
              

                  history.push(`/publicar/info?${nextUrlParams}`);
                }}
                className="btn btn-primary dark btn-start"
              >
                CONTINUAR
              </a>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="container-card">
              <article className="product-item">
                <figure className="img">
                  <a href="producto" className="img-link">
                    <img src="images/thumb-vender.jpg" alt="" />
                  </a>
                </figure>
                <div className="desc">
                  <h4 className="title">
                    <a href="producto" className="dummy">
                      <span className="desc-marca"></span>
                      <span className="desc-modelo"></span>
                    </a>
                    <span className="details dummy"></span>
                  </h4>
                  <p className="price"></p>
                </div>
                <div className="bottom">
                  <div className="bottom-item">
                    <img
                      src="images/ico-combustible.png"
                      alt="Combustible"
                      className="ico"
                    />
                    <div className="bottom-text"></div>
                  </div>
                  <div className="bottom-item">
                    <img src="images/ico-caja.png" alt="Caja" className="ico" />
                    <div className="bottom-text"></div>
                  </div>
                  <div className="bottom-item favoritos">
                    <a href="#" className="fav" title="Agregar a favoritos"></a>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default VenderRenderTop;
