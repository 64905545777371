import createDuck, { createAction, createThunk } from "../utils/actions";
import axios from "../net/axios";
import config from "../config";

const baseUrl = config.api.baseUrl;

const duckGetImagenesTemporalesV3 = createDuck("GET_IMG_TMP");

const getImagenesTemporalesV3Thunk = (data) => {
  const url = `${baseUrl}/getImagenesv3/${data.concepto}/${data.conceptoId}`;
  return createThunk(
    axios,
    "get",
    duckGetImagenesTemporalesV3.actions,
    url,
    null
  );
};

const duckEliminarImgTmpV3 = createDuck("ELIMINAR_IMG_TMP");

const eliminarImgTmpV3Thunk = (data) => {
  const url = `${baseUrl}/eliminarImgv3`;
  return createThunk(
    axios,
    "post",
    duckEliminarImgTmpV3.actions,
    url,
    data
  );
};

const exp = {
  duckGetImagenesTemporalesV3,
  getImagenesTemporalesV3Thunk,
  duckEliminarImgTmpV3,
  eliminarImgTmpV3Thunk,
};

export default exp;
