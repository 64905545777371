/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import Loader from "../../utils/Loader";
import Alert from "../../utils/Alert";
import { logger } from "../../utils/utils";

function ConfirmationRender({
  setCodigo,
  submit,
  isLoading,
  submitErrorMsg,
}) {
  return (
    <section className="banner-registro">
      <header className="header1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2">
              <a href="./" className="logo"></a>
            </div>
          </div>
        </div>
      </header>
      <div className="container-fluid hero-content">
        <div className="row align-items-stretch">
          <div className="col-lg-6">
            <div className="container-5"></div>
          </div>
          <div className="col-lg-6">
            <div className="container-login">
              <h2>
                <strong>Crear una cuenta</strong>
              </h2>
              {submitErrorMsg && (
                    <Alert
                      type="danger"
                      text={submitErrorMsg}
                      visible={true}
                      style={{ textAlign: "left" }}
                    />
                  )}
              <div className="datos-registro">
                <form action="#">
                  <div className="datos-content">
                    <h3 className="mb-3">Confirmación</h3>
                    <div className="form-group">
                      <label className="sr-only" for="c_nombre">
                        Codigo
                      </label>
                      <input
                        onChange={(evt) => setCodigo(evt.target.value)}
                        type="text"
                        className="form-control"
                        id="c_nombre"
                        placeholder="Codigo"
                      />
                    </div>
                  </div>

                  <button
                    onClick={() => submit()}
                    disabled={isLoading}
                    value="Confirmar"
                    className="btn btn-primary dark"
                  >
                    <Loader
                      style={{ paddinTop: 0, paddingBottom: 0 }}
                      isLoading={isLoading}
                    >
                      Confirmar
                    </Loader>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ConfirmationRender;
