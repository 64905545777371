import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useHistory, useParams } from "react-router-dom";
import controller from "../duck";
import ResultadosView from "./Resultados.view";
import {
  colorOptions,
  cuerpoOptions,
  duenhosOptions,
  precioOptions,
} from "../../utils/utils";
function Perfil({outsideDomain=false, url=null,  colorPrincipal=null, colorSecundario=null, instagram=null}) {
  let history = useHistory();
  const { id } = useParams(null);
  const dispatch = useDispatch();
  const modelOrganizacion = useSelector((state) => state.organizacionByUrl);
  const modelPublicaciones = useSelector((state) => state.publicaciones2);
  const modelCotizacion = useSelector((state) => state.getCotizacionActiva);
  const [cotizacion, setCotizacion] = useState(0);
  const modelMarcas = useSelector((state) => state.marcas);
  const modelModelos = useSelector((state) => state.modelos);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [marca, setMarca] = useState(null);
  const [modelo, setModelo] = useState(null);
  const [color, setColor] = useState([]);
  const [uso, setUso] = useState([]);
  const [precioMin, setPrecioMin] = useState(null);
  const [precioMax, setPrecioMax] = useState(null);
  const [duenhos, setDuenhos] = useState(null);
  const [kilometrajeMax, setKilometrajeMax] = useState(null);
  const [kilometrajeMin, setKilometrajeMin] = useState(null);
  const [caja, setCaja] = useState(null);
  const [combustible, setCombustible] = useState(null);
  const [cuerpo, setCuerpo] = useState([]);
  const [precioDesc, setPrecioDesc] = useState(null);
  const [tipoVendedor, setTipoVendedor] = useState(null);
  const [anhos, setAnhos] = useState([]);
  const [orderby, setOrderBy] = useState("ts_desc");
  const [tipoOrden, setTipoOrden] = useState(1);
  const [results, setResults] = useState(null);
  const [sugerencias, setSugerencias] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [showFiltrar, setShowFiltrar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("VAUN");
  const [organizacion, setOrganizacion] = useState(null);
  const [organizacionId, setOrganizacionId] = useState(null);
  const closeFilter = () => {
    document.body.classList.remove("show-filters");
    let el = document.querySelector(".sidebar-resultados");
    el.classList.remove("fade-in");
    if (window.innerWidth <= 991) {
      el.style.display = "none";
    } else {
      el.style.display = "block";
    }

    setShowFiltrar(false);
  };

  const handleOrder = (value) => {
    setOrderBy(value);
    setActivePage(1);
    searchPublicaciones(value);
  };
  const handleFilter = () => {
    if (showFiltrar) {
      closeFilter();
    } else {
      document.body.classList.add("show-filters");
      let el = document.querySelector(".sidebar-resultados");
      el.classList.add("fade-in");
      el.style.display = "block";
      setShowFiltrar(true);
    }
  };

  useEffect(() => {
    const data = modelCotizacion?.data?.data;
    const error = modelCotizacion?.error?.message;

    if (error) {
      alert(error);
      dispatch(controller.duckCotizacionActiva.actions.clear());
      setLoading(false);
    }
    if (data) {
      setCotizacion(data.data.valor);
      const params = new URLSearchParams(history.location.search);
      const orderQuery = params.get("order") || null;
      searchPublicacionesQueryParams(1, orderQuery);
      if (orderQuery) {
        setOrderBy(orderQuery);
      }
      dispatch(controller.duckCotizacionActiva.actions.clear());
    }
  }, [modelCotizacion]);

  useEffect(() => {
    const data = modelOrganizacion?.data?.data;
    const error = modelOrganizacion?.error?.response?.data?.message;

    if (error) {
      alert(error);
      dispatch(controller.duckOrganizacion.actions.clear());
    }
    if (data) {
      if(data.data){
        setOrganizacion(data.data)
        setOrganizacionId(data.data._id);
        setTitle(`${data.data.name} || VAUN`)
        dispatch(controller.getMarcasThunk());
        dispatch(controller.getCotizacionActivaThunk());
      }
      else{
        alert("Organizacion no encotnrada");
        history.push("/logout")
      }

      dispatch(controller.duckOrganizacion.actions.clear());
    }
  }, [modelOrganizacion]);
  useEffect(() => {
    const data = modelPublicaciones?.data?.data;
    const error = modelPublicaciones?.error?.message;

    if (error) {
      alert(error);
      dispatch(controller.duck.actions.clear());
      setLoading(false);
    }
    if (data) {
      setSugerencias(data.data.sugeridos);
      setResults(data.data.items);
      setTotalResults(data.data.total);
      dispatch(controller.duck.actions.clear());
      setLoading(false);
    }
  }, [modelPublicaciones]);

  useEffect(() => {
    const data = modelMarcas?.data?.data;
    const error = modelMarcas?.error?.message;

    if (error) {
      alert(error);
      dispatch(controller.duckMarcas.actions.clear());
    }
    if (data) {
      setMarcas(data.data);
      const params = new URLSearchParams(history.location.search);
      const orderQuery = params.get("order") || null;
      searchPublicacionesQueryParams(1, orderQuery);
  
      if (orderQuery) {
        setOrderBy(orderQuery);
      }
      dispatch(controller.duckMarcas.actions.clear());
    }
  }, [modelMarcas]);

  useEffect(() => {
    const data = modelModelos?.data?.data;
    const error = modelModelos?.error;
    const errorMessage = modelModelos?.error?.message;

    if (error) {
      const errorToShow =
        errorMessage || "Ocurrió un error al realizar la acción";
      alert(errorToShow);
      dispatch(controller.duckGetModelos.actions.clear());
    }

    if (data) {
      setModelos(data.data);
      dispatch(controller.duckGetModelos.actions.clear());
    }
  }, [modelModelos]);

  useEffect(() => {
    const data = id ? id : url;
    dispatch(controller.organizacionByUrlThunk(data));
    const analytics = getAnalytics();
    logEvent(analytics, "funnel", {
      action: "busqueda",
    });
  }, []);

  useEffect(() => {
    if (marca && marca.value) {
      dispatch(controller.getModelosThunk(marca.value));
    }
  }, [marca]);

  function searchPublicaciones(orderBy = null) {
    setLoading(true);
    closeFilter();
    let searchString = "";
    if (marca) {
      searchString =
        searchString.length === 0
          ? searchString + "marca=" + marca.value
          : searchString + "&marca=" + marca.value;
    }

    if (modelo) {
      searchString =
        searchString.length === 0
          ? searchString + "modelo=" + modelo.value
          : searchString + "&modelo=" + modelo.value;
    }

    if (precioMin || precioMax) {
      const firstPart = precioMin ? precioMin : 0;
      const secondPart = precioMax ? precioMax : 0;
      const concatenate = firstPart + "-" + secondPart;
      searchString =
        searchString.length === 0
          ? searchString + "precio=" + concatenate
          : searchString + "&precio=" + concatenate;
    }

    if (duenhos) {
      searchString =
        searchString.length === 0
          ? searchString + "duenhos=" + duenhos.value
          : searchString + "&duenhos=" + duenhos.value;
    }

    if (kilometrajeMax || kilometrajeMin) {
      const firstPart = kilometrajeMin ? kilometrajeMin : 0;
      const secondPart = kilometrajeMax ? kilometrajeMax : 0;
      const concatenate = firstPart + "-" + secondPart;

      searchString =
        searchString.length === 0
          ? searchString + "kilometraje=" + concatenate
          : searchString + "&kilometraje=" + concatenate;
    }
    if (caja) {
      searchString =
        searchString.length === 0
          ? searchString + "caja=" + caja.value
          : searchString + "&caja=" + caja.value;
    }

    if (color && color.length > 0) {
      const colorArray = color.map((row) => row.value);
      const cuerpoString = colorArray.toString();
      searchString =
        searchString.length === 0
          ? searchString + "color=" + cuerpoString
          : searchString + "&color=" + cuerpoString;
    }

    if (combustible) {
      searchString =
        searchString.length === 0
          ? searchString + "combustible=" + combustible.value
          : searchString + "&combustible=" + combustible.value;
    }

    if (cuerpo && cuerpo.length > 0) {
      const cuerpoArray = cuerpo.map((row) => row.value);
      const cuerpoString = cuerpoArray.toString();
      searchString =
        searchString.length === 0
          ? searchString + "cuerpo=" + cuerpoString
          : searchString + "&cuerpo=" + cuerpoString;
    }

    if (tipoVendedor) {
      searchString =
        searchString.length === 0
          ? searchString + "tipovendedor=" + tipoVendedor.value
          : searchString + "&tipovendedor=" + tipoVendedor.value;
    }
    if (anhos && anhos.length > 0) {
      searchString =
        searchString.length === 0
          ? searchString + "anhos=" + anhos
          : searchString + "&anhos=" + anhos;
    }
    const order = orderBy ? orderBy : orderby;
    searchString =
      searchString.length === 0
        ? searchString + "order=" + order
        : searchString + "&order=" + order;

    history.push({
      pathname: outsideDomain ? "/" : "/resultados",
      search: searchString.length > 0 ? searchString : null,
    });
    searchPublicacionesQueryParams(1, order);
  }

  function searchPublicacionesQueryParams(page, orderBy = null) {
    const params = new URLSearchParams(history.location.search);
    const marcaQuery = params.get("marca") || null;
    const modeloQuery = params.get("modelo") || null;
    const precioQuery = params.get("precio") || null;
    let precioMinQuery = null;
    let precioMaxQuery = null;
    if (precioQuery) {
      const values = precioQuery.split("-");
      precioMinQuery = values[0];
      precioMaxQuery = values[1];
    }
    const duenhosQuery = params.get("duenhos") || null;
    const kilometrajeQuery = params.get("kilometraje") || null;
    let kilometrajeMinQuery = null;
    let kilometrajeMaxQuery = null;
    if (kilometrajeQuery) {
      const values = kilometrajeQuery.split("-");
      kilometrajeMinQuery = values[0];
      kilometrajeMaxQuery = values[1];
    }
    const cajaQuery = params.get("caja") || null;
    const combustibleQuery = params.get("combustible") || null;
    const cuerpoQuery = params.get("cuerpo") || null;

    const tipoVendedorQuery = params.get("tipovendedor") || null;
    const colorQuery = params.get("color") || null;

    const anhoQuery = params.get("anhos") || null;

    let data = {
      organizacionID: organizacionId,
      marca: marcaQuery,
      modelo: modeloQuery,
      precioMin: precioMinQuery,
      precioMax: precioMaxQuery,
      duenhos: duenhosQuery,
      kilometrajeMin: kilometrajeMinQuery,
      kilometrajeMax: kilometrajeMaxQuery,
      caja: cajaQuery,
      combustible: combustibleQuery,
      cuerpo: cuerpoQuery,
      tipoVendedor: tipoVendedorQuery,
      color: colorQuery,
      anhos: anhoQuery,
      orderby: orderBy ? orderBy : orderby,
      tipoOrden,
      page: page ? page : activePage,
      limit: pageSize,
    };
   
    setMarca(marcaQuery ? { value: marcaQuery, label: marcaQuery } : null);
    setModelo(modeloQuery ? { value: modeloQuery, label: modeloQuery } : null);
    if (precioMinQuery && Number(precioMinQuery>0)) {
      setPrecioMin(precioMinQuery);
    }
    if (precioMaxQuery && Number(precioMaxQuery>0)) {
      setPrecioMax(precioMaxQuery);
    }

    const duenhosOptionSelected = duenhosOptions.filter(
      (element) => element.value == duenhosQuery
    );
    setDuenhos(duenhosQuery ? duenhosOptionSelected[0] : null);
    if (kilometrajeMinQuery && Number(kilometrajeMinQuery>0)) {
      setKilometrajeMin(kilometrajeMinQuery);
    }
    if (kilometrajeMaxQuery && Number(kilometrajeMaxQuery>0)) {
      setKilometrajeMax(kilometrajeMaxQuery);
    }

    setCaja(cajaQuery ? { value: cajaQuery, label: cajaQuery } : null);
    setCombustible(
      combustibleQuery
        ? { value: combustibleQuery, label: combustibleQuery }
        : null
    );
    const cuerpoSplit = cuerpoQuery ? cuerpoQuery.split(",") : [];
    const arrayCuerpo = cuerpoOptions.filter((element) =>
      cuerpoSplit.includes(element.value)
    );
    setCuerpo(arrayCuerpo);
    setTipoVendedor(
      tipoVendedorQuery
        ? { value: tipoVendedorQuery, label: tipoVendedorQuery }
        : null
    );
    const colorSplit = colorQuery ? colorQuery.split(",") : [];
    const arrayColor = colorOptions.filter((element) =>
      colorSplit.includes(element.value)
    );
    setColor(arrayColor);
    const anhosSplit = anhoQuery ? anhoQuery.split(",") : [];
    setAnhos(anhosSplit);
    setLoading(true);
    setResults([]);
    setSugerencias([]);
    setTotalResults(0);
    setActivePage(page);

    let titleConstructor = "Autos ";
    let tipo = "";

    if (uso.length === 0 || (uso.includes("Usados") && uso.includes("0 KM"))) {
      tipo = "| 0 KM y usados";
    } else if (uso.includes("Usados")) {
      tipo = "| Usados";
    } else if (uso.includes("0 KM")) {
      tipo = "| 0 KM";
    }

    titleConstructor = titleConstructor + tipo;
    if (marcaQuery) {
      titleConstructor = titleConstructor + " | " + marcaQuery;
    }
    if (modeloQuery) {
      titleConstructor = titleConstructor + " | " + modeloQuery;
    }
    if (anhoQuery) {
      titleConstructor = titleConstructor + " | " + params.get("anhos");
    }
    // setTitle(titleConstructor);
    if (marcaQuery) {
      const busqueda = modeloQuery
        ? `${marcaQuery} ${modeloQuery}`
        : `${marcaQuery}`;
      const analytics = getAnalytics();
      logEvent(analytics, "resultados_filters", {
        busqueda,
      });
    }

    dispatch(controller.searchPublicaciones2Thunk(data));
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta property="og:url" content={window.location.href}/>
        <meta property="og:title" content={title}/>
      </Helmet>
      <ResultadosView
        instagram={instagram}
        colorPrincipal={colorPrincipal}
        colorSecundario={colorSecundario}
        outsideDomain={outsideDomain}
        cotizacion={cotizacion}
        setPrecioDesc={setPrecioDesc}
        precioDesc={precioDesc}
        loadingMarcas={modelMarcas.loading}
        loadingModelos={modelModelos.loading}
        history={history}
        marcas={marcas}
        setMarca={setMarca}
        marca={marca}
        modelos={modelos}
        modelo={modelo}
        setModelo={setModelo}
        setActivePage={setActivePage}
        searchPublicaciones={searchPublicaciones}
        tipoVendedor={tipoVendedor}
        setTipoVendedor={setTipoVendedor}
        combustible={combustible}
        setCombustible={setCombustible}
        caja={caja}
        setCaja={setCaja}
        color={color}
        setColor={setColor}
        duenhos={duenhos}
        setDuenhos={setDuenhos}
        kilometrajeMax={kilometrajeMax}
        setKilometrajeMax={setKilometrajeMax}
        kilometrajeMin={kilometrajeMin}
        setKilometrajeMin={setKilometrajeMin}
        cuerpo={cuerpo}
        setCuerpo={setCuerpo}
        precioMax={precioMax}
        setPrecioMax={setPrecioMax}
        setPrecioMin={setPrecioMin}
        precioMin={precioMin}
        results={results}
        totalResults={totalResults}
        activePage={activePage}
        orderby={orderby}
        setOrderBy={handleOrder}
        pageSize={pageSize}
        searchPublicacionesQueryParams={searchPublicacionesQueryParams}
        handleFilter={handleFilter}
        isLoading={loading}
        anhos={anhos}
        setAnhos={setAnhos}
        sugerencias={sugerencias}
        uso={uso}
        setUso={setUso}
        perfil={organizacion}
      />
    </React.Fragment>
  );
}

export default Perfil;
