import React, { useEffect, useState } from "react";
import _ from "lodash";
import Slider from "react-slick";
import { getAnalytics, logEvent } from "firebase/analytics";
import { colorOptions, thousandSeparator, timeSince } from "../../utils/utils";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "../../utils/Loader";

function SliderRecientes({ history, publicaciones, cotizacion }) {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: false,
    infinite: publicaciones && publicaciones.length > 3 ? true : false,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          infinite: publicaciones && publicaciones.length > 2 ? true : false,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          infinite: publicaciones && publicaciones.length > 1 ? true : false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [x, setX] = useState(null);

  const handleMouseDown = (e) => {
    // store mouse position when click starts
    setX(e.screenX);
  };

  const handleClick = (e, row) => {
    const delta = Math.abs(e.screenX - x);

    if (delta > 10) {
      // If mouse moved more than threshold, ignore the click event

      e.preventDefault();
    } else {
      const analytics = getAnalytics();
      logEvent(analytics, "home_action", {
        action: "recientes",
      });
      history.push(
        `/publicacion/${row.attrs.marca}-${row.attrs.modelo.replace(
          "/",
          "_"
        )}-${row.attrs.anho}-${row.attrs.kilometraje}/${row._id}`
      );
    }
    setX(0);
  };
  return (
    <section className="home-recientes">
      <div className="container section-1">
        <div>
          <h2>Recién llegados</h2>
          <Loader
            loaderSize={30}
            isLoading={publicaciones && publicaciones.length > 0 ? false : true}
          >
            {publicaciones && publicaciones.length > 0 && (
              <div className="slider-recientes">
                <Slider {...settings}>
                  {publicaciones.map((row, index) => (
                    <div key={index} className="slide">
                      <article className="product-item">
                        <figure className="img">
                          <a
                            onMouseDown={handleMouseDown}
                            onClick={(event) => handleClick(event, row)}
                            className="img-link"
                          >
                            <img src={row?.imagenes?.[0]?.url} alt="" />
                          </a>
                        </figure>
                        <div className="desc">
                        <div style={{ marginBottom: 5, marginLeft:"1rem" }}>
                        <span className="row" style={{ fontSize: 10, alignItems:'center' }}>
                          <img
                            style={{ width: 15, height: 15 }}
                            alt=""
                            src="/images/clock.png"
                          />{" "}
                          {timeSince(row.ts)}
                        </span>
                      </div>

                          <h4 className="title">
                            <a
                              onMouseDown={handleMouseDown}
                              onClick={(event) => handleClick(event, row)}
                            >
                              {row.attrs.marca + " " + row.attrs.modelo}
                            </a>
                            {row.attrs.kilometraje == 0 ? (
                              <div
                                className="details"
                                style={{ marginTop: 10 }}
                              >
                                {/* <span>
                                  {row.attrs.anho + " | "}
                                </span> */}

                                <strong
                                  style={{
                                    backgroundColor: "#ffc107",
                                    borderRadius: 15,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  {row.attrs.anho +
                                    " | " +
                                    thousandSeparator(row.attrs.kilometraje) +
                                    " km"}
                                </strong>
                              </div>
                            ) : (
                              <div
                                className="details"
                                style={{ marginTop: 10 }}
                              >
                                {row.attrs.anho +
                                  " | " +
                                  thousandSeparator(row.attrs.kilometraje) +
                                  " km"}
                              </div>
                            )}
                          </h4>
                          <p className="price">
                            {"USD. " + thousandSeparator((row.precio), true)}
                          </p>
                          <p className="price sm">
                            {"GS. " + thousandSeparator((row.precio * cotizacion), false)}
                          </p>
                        </div>
                        <div className="bottom">
                          <div className="bottom-item">
                            <img
                              src="images/ico-combustible.png"
                              alt="Combulstible"
                              className="ico"
                            />
                            {row.attrs.combustible}
                          </div>
                          <div className="bottom-item">
                            <img
                              src="images/ico-caja.png"
                              alt="Caja"
                              className="ico"
                            />
                            {row.attrs.caja}
                          </div>
                          {/* <div className="bottom-item favoritos">
                          <a
                            href="#"
                            className="fav"
                            title="Agregar a favoritos"
                          ></a>
                        </div> */}
                        </div>
                      </article>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </Loader>
        </div>
      </div>
    </section>
  );
}

export default SliderRecientes;
