import React, { useState, useEffect } from "react";

export default function KilometrajeInput({
  title,
  inputStyle,
  inputContainerClassName,
  inputClassName,
  placeholder,
  setValue,
  value,
  unit,
}) {
  const [shownValue, setShownValue] = useState("");
  const [mostrarInput, setMostrarInput] = useState(false);

  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  useEffect(() => {
    if (value === 0 || value === "0") {
      setShownValue("0");
      setMostrarInput(false);
    } else if (value) {
      setShownValue(addCommas(removeNonNumeric(value)));
      setMostrarInput(true);
    } else { // undefined, null
      setShownValue("");
      setMostrarInput(true);
    }
  }, [value]);

  const handleChange = (value) => {
    setShownValue(addCommas(removeNonNumeric(value)));
    setValue(removeNonNumeric(value));
  };

  return (
    <React.Fragment>
      <h3>{title}</h3>

      <div className="multiple-filter" style={{marginBottom: '-1px'}}>
        <div className="filter-options">
          <ul className="list">
            <li
              onClick={() => {
                setValue("0");
                // setMostrarInput(false);
              }}
              className={mostrarInput ? "" : "active"}
            >
              0 Km.
            </li>
            <li
              onClick={() => {
                setValue("");
                // setMostrarInput(true);           
              }}
              className={mostrarInput ? "active" : "" }
            >
              Usado
            </li>
          </ul>
        </div>
      </div>

      {mostrarInput && (<div className={inputContainerClassName} id="kilometraje">
        <input
          type="text"
          className={inputClassName}
          placeholder={placeholder}
          value={shownValue}
          onChange={(evt) => handleChange(evt.target.value)}
          style={inputStyle}
        />
        <p>{unit}</p>
      </div>)}
    </React.Fragment>
  );
}
