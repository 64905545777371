/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import { logger } from "../utils/utils";
import controller from "./duck";
import CortarImagenes from "./CortarImagenes";
import { useHistory, useParams } from "react-router-dom";

import Alert from "../utils/Alert";
import Pasos from "./../publicaciones/publicar/Pasos";
import Footer from "../utils/Footer";

export default function CortarImagenesConcepto(props) {
  let history = useHistory();

  const { publicacionID } = useParams(null);

  logger.debug("CortarImagenesConcepto.render");

  const [token, setToken] = useState(() => localStorage.getItem("token"));
  logger.debug("CortarImagenesConcepto.token");
  logger.debug(token);

  const params = queryString.parse(history.location.search);
  logger.debug("CortarImagenesConcepto.parsedQueryString");
  logger.debug(params);

  const {
    concepto,
    conceptoId,
    saveInConceptUrl,
    cropConfig,
    lastStepUrl,
    rawUploadHeaders,
    cropUploadUrl,
    relatedImageId,
  } = params;

  logger.debug("CortarImagenesConcepto.params.cropConfig");
  logger.debug(cropConfig);

  const parsedCropConfig = JSON.parse(cropConfig);

  const {
    minCroppedWidth,
    minCroppedHeight,
    maxCroppedWidth,
    maxCroppedHeight,
    aspectRatioNumerator,
    aspectRatioDenominator,
  } = parsedCropConfig;

  logger.debug("CortarImagenesConcepto.parsedCropConfig");
  logger.debug({ parsedCropConfig });

  logger.debug("CortarImagenesConcepto.rawUploadHeaders");
  logger.debug(rawUploadHeaders);
  const uploadHeaders = JSON.parse(rawUploadHeaders);

  const dispatch = useDispatch();

  const [eliminacionLoading, setEliminacionLoading] = useState(false);
  const [imagenesTemporales, setImagenesTemporales] = useState([]);
  const [imgTmpRespuestaRecibida, setImgTmpRespuestaRecibida] = useState(false);
  const [generalErrorMsg, setGeneralErrorMsg] = useState("");
  const [submitErrorMsg, setSubmitErrorMsg] = useState("");

  useEffect(() => {
    // componentDidMount
    logger.debug("CortarImagenesConcepto.EmptyDepsUseEffect");
    dispatch(controller.getImagenesTemporalesV3Thunk({ concepto, conceptoId }));
    return () => {
      dispatch(controller.duckGetImagenesTemporalesV3.actions.clear());
    };
  }, []);

  const modelImagenesTemporales = useSelector((state) => {
    logger.debug("useSelector.modelImagenesTemporales.state", state);
    logger.debug(
      "useSelector.modelImagenesTemporales.state.imgTmp",
      state.imagenesTemporalesV3
    );
    return state.imagenesTemporalesV3;
  });

  useEffect(() => {
    logger.debug("useEffect(modelImagenesTemporales)", modelImagenesTemporales);

    // TODO usar "selectors" para data y error

    const data = modelImagenesTemporales?.data?.data?.data;
    const error = modelImagenesTemporales?.error;

    if (error) {
      const errorMessage = modelImagenesTemporales?.error?.response?.data?.message;
      alert(errorMessage);
      dispatch(controller.duckGetImagenesTemporalesV3.actions.clear());
      setImgTmpRespuestaRecibida(true);
    }
    if (data) {
      logger.debug("Se pudo traer imagenes temporales", data);

      setImagenesTemporales(data);

      dispatch(controller.duckGetImagenesTemporalesV3.actions.clear());
      setImgTmpRespuestaRecibida(true);
    }
  }, [modelImagenesTemporales, dispatch]);

  const modelEliminacionImgTmp = useSelector((state) => {
    logger.debug("useSelector.state", state);
    logger.debug("useSelector.state.eliminacionImgTmp", state.eliminacionImgTmp);
    return state.eliminacionImgTmp;
  });

  const doEliminarImgTmp = (imagenID) => {
    setSubmitErrorMsg("");
    logger.debug("doEliminarImgTmp.imagenID", imagenID);
    // logger.debug("doEliminarImgTmp.conceptoId", conceptoId);
    let data = {
      imagenID,
      // conceptoId,
      token,
    };
    dispatch(controller.eliminarImgTmpV3Thunk(data));
  };

  useEffect(() => {
    logger.debug("useEffect(modelEliminacionImgTmp)", modelEliminacionImgTmp);

    // TODO usar "selectors" para data y error

    const data = modelEliminacionImgTmp?.data?.data?.data;
    const error = modelEliminacionImgTmp?.error;

    logger.debug("useEffect.modelEliminacionImgTmp", modelEliminacionImgTmp);

    if (error) {
      const errorMessage = modelEliminacionImgTmp?.error?.response?.data?.message ||'Ocurrió un error al eliminar la imagen, por favor, intente de nuevo.';
      setSubmitErrorMsg(errorMessage);
      setEliminacionLoading(false);
      dispatch(controller.duckEliminarImgTmpV3.actions.clear());
    }

    if (data) {
      logger.debug("Se pudo eliminar", data);
      setImagenesTemporales([]);
      setImgTmpRespuestaRecibida(false);
      dispatch(
        controller.getImagenesTemporalesV3Thunk({ concepto, conceptoId })
      );
    }
  }, [modelEliminacionImgTmp, dispatch, concepto, conceptoId]);

  const onAllDone = () => {
    logger.debug(
      "onAllDone.redireccionando.imgTmpRespuestaRecibida",
      imgTmpRespuestaRecibida
    );
    
    history.push(lastStepUrl);
    return <>Redireccionando...</>;
  };

  return (
    <div>
      <section className="contenedor-pasos" style={{ minHeight: "100vh" }}>
        <header className="header2">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/");
                }}
                className="logo"
              ></a>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarNavAltMarkup"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a href="" className="nav-link">
                      <img
                        src="/images/ayuda.png"
                        alt=""
                        className="img-fluid"
                      />
                      Ayuda
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Pasos publicacionID={publicacionID} pasoNro={2} />
            </div>
            <div className="col-lg-9">
              <div className="container container-content">
                <div className="content-subirImg">
                  <div className="titulos-subirImg">
                    <h1 className="tit-subirImg">Recortar imágenes</h1>
                    {/* <p className="tit-subi">Paso 2 : Recorte</p> */}
                  </div>
                  <div className="enc-subImg">
                    <p className="copete-subImg">
                      Para guardar las imágenes, tenés que recortarlas. Las
                      imágenes que no recortes serán eliminadas. Las dimensiones
                      deben ser:
                      <ul>
                        <li>
                          Ancho min: {minCroppedWidth} px.
                        </li>
                        <li>
                          Alto min: entre {minCroppedHeight} px.
                        </li>
                      </ul>
                    </p>
                    {generalErrorMsg && (
                      <Alert
                        type="danger"
                        text={generalErrorMsg}
                        visible={true}
                        style={{ textAlign: "left" }}
                      />
                    )}
                  </div>
                  <CortarImagenes
                    imagenesTemporales={imagenesTemporales}
                    imgTmpRespuestaRecibida={imgTmpRespuestaRecibida}
                    uploadUrl={cropUploadUrl}
                    uploadHeaders={uploadHeaders}
                    minCroppedWidth={minCroppedWidth}
                    minCroppedHeight={minCroppedHeight}
                    maxCroppedWidth={maxCroppedWidth}
                    maxCroppedHeight={maxCroppedHeight}
                    aspectRatioNumerator={aspectRatioNumerator}
                    aspectRatioDenominator={aspectRatioDenominator}
                    setEliminacionLoading={setEliminacionLoading}
                    eliminacionLoading={eliminacionLoading}
                    onClickEliminar={doEliminarImgTmp}
                    onAllDone={onAllDone}
                    conceptoId={conceptoId}
                    saveInConceptUrl={saveInConceptUrl}
                    relatedImageId={relatedImageId}
                    submitErrorMsg={submitErrorMsg}
                    setSubmitErrorMsg={setSubmitErrorMsg}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
