/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderPublicar from "./HeaderPublicar";
import authController from "../../publicaciones/auth/duck";
import { useHistory } from "react-router";
import Footer from "../../utils/Footer";
import Loader from "../../utils/Loader";

export default function Gracias({ token }) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [nombre, setNombre] = useState(null);
  const [loading, setLoading] = useState(true);
  const modelUsuario = useSelector((state) => state.getUsuarioByCredencial);

  useEffect(() => {
    dispatch(authController.getUsuarioByCredencialThunk(token.decoded.id));
  }, []);

  useEffect(() => {
    const data = modelUsuario?.data?.data?.data;
    const error = modelUsuario?.error;

    if (error) {
      // const errorMessage =
      //   modelUsuario?.error?.response?.data?.message ||
      //   "Ocurrió un error al consultar datos de usuario. Por favor, intente nuevamente recargando la página";
      // setGeneralMsg({ text: errorMessage, type: "danger" });
      setNombre("");
      dispatch(authController.duckGetUsuarioByCredencial.actions.clear());
      setLoading(false);
    }

    if (data) {

      setNombre(data?.nombre || "");
      dispatch(authController.duckGetUsuarioByCredencial.actions.clear());
      setLoading(false);
    }
  }, [modelUsuario]);

  return (
    <Loader
      isLoading={loading ? true : false}
      loaderSize={30}
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <section className="contenedor-pasos contenedor-gracias">
        <HeaderPublicar />
        <div className="gracias">
          <div className="container">
            <figure>
              <img
                src="/images/ico-gracias.png"
                alt=""
                className="ico-gracias img-fluid"
              />
            </figure>
            {nombre ? (
              <h1>
                ¡Gracias por publicar,
                <br />
                <strong>{nombre}</strong>!
              </h1>
            ) : (
              <h1>¡Gracias por publicar!</h1>
            )}
          </div>
        </div>
      </section>
      <section className="gracias-pasos">
        <div className="container">
          <ul className="pasos-siguientes">
            <li className="paso">
              <figure className="ico">
                <img src="/images/ico-gracias-1.png" alt="" />
              </figure>
              <p>
                Ahora tu publicación ingresará a moderación para comprobar que
                cumpla con los todos los requisitos.
              </p>
            </li>
            <li className="paso">
              <figure className="ico">
                <img src="/images/ico-gracias-2.png" alt="" />
              </figure>
              <p>Te enviaremos un correo cuando tu publicación sea aceptada.</p>
            </li>
          </ul>
          <div className="text-center mt-5">
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                history.push("/publicaciones");
              }}
              className="btn btn-primary dark"
            >
              Ir a mis publicaciones
            </a>
          </div>
        </div>
      </section>
      <Footer history={history} />
    </Loader>
  );
}
