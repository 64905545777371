/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Alert from "../../utils/Alert";

export default function NiceFileInput({
  errorMessage,
  onFileChangeListener,
  placeholderIconUrl,
  tomaTipo,
  descripcion,
  posicionEnVista,
}) {
  return (
    <div className="drag-drop sin-imagen">
      <Alert
        {...errorMessage}
        style={{
          marginBottom: "4px",
          paddingTop: "0.4rem",
          paddingRight: "0.7rem",
          paddingBottom: "0.4rem",
          paddingLeft: "0.7rem",
        }}
      />
      <input
        type="file"
        accept="image/png, image/jpeg"
        onChange={(event) => {
          onFileChangeListener(event, tomaTipo, posicionEnVista);
        }}
      />
      <img src={placeholderIconUrl} alt="" className="img-fluid" />
      <span className="desc">{descripcion}</span>
      <p className="desc2">
        Arrastrar una imagen aquí o{" "}
        <a type="file" href="" className="link">
          Examinar
        </a>
      </p>
    </div>
  );
}
