import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import controller from "./duck";
import RegisterRender from "./RegisterRender";
import Footer from "../../utils/Footer";
import { logger, parseJwt, validateEmail } from "../../utils/utils";

const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || "vaun.com.py";

function Login() {
  let history = useHistory();
  const dispatch = useDispatch();
  const modelInvitacion = useSelector((state) => state.crearInvitacion);
  const [fullName, setFullName] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [password2, setPassword2] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('+595');
  const [submitErrorMsg, setSubmitErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  useEffect(() => {
    const data = modelInvitacion?.data?.data;
    const error = modelInvitacion?.error;
  
    const errorMessage = modelInvitacion?.error?.response?.data?.message;
    if (error) {
      let errorToShow;
      if (errorMessage) {
        errorToShow = errorMessage;
      } else {
        errorToShow = "Ocurrió un error al realizar la operación";
      }
      setSubmitErrorMsg(errorToShow);
      dispatch(controller.duckCrearAutoInvitacion.actions.clear());
      return;
    }
    if (data) {
    

      dispatch(controller.duckCrearAutoInvitacion.actions.clear());
      history.push(`/confirmation/${data.data._id}`)
    }
  }, [modelInvitacion]);

  function crearInvitacion() {
    if(!fullName || !phoneNumber || !password || !password2 || !username){
      setSubmitErrorMsg(
        "Por favor, complete todos los campos"
      );
      return;
    }
    if (!validateEmail(username)) {
      setSubmitErrorMsg(
        "Por favor, proporcioná un email con el formato correcto"
      );
      return;
    }

    if (password !== password2) {
      setSubmitErrorMsg(
        "Por favor, proporcioná un email con el formato correcto"
      );
      return;
    }
    setSubmitErrorMsg(null);
    let data = {
      username,
      password,
      nombre: fullName,
      phone: phoneNumber,
    };
   
    dispatch(controller.crearAutoInvitacionThunk(data));
  }

  return (
    <React.Fragment>
      <RegisterRender
        history={history}
        setPassword={setPassword}
        setFullName={setFullName}
        setPassword2={setPassword2}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
        setUsername={setUsername}
        crearInvitacion={crearInvitacion}
        isLoading={modelInvitacion.loading || modelInvitacion.loading}
        submitErrorMsg={submitErrorMsg}
        successMsg={successMsg}
      />
      <Footer />
    </React.Fragment>
  );
}

export default Login;
