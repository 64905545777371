import React, { useEffect, useState } from "react";
import _ from "lodash";
import Select from "react-select";

function RenderSearch({
  customStyles,
  value,
  setValue,
  options,
  label,
  isLoading,
  noOptionsMessage,
  placeholder,
  outerContainerStyle,
  innerContainerStyle,
  dropdownIndicatorStyle = { width: 18, height: 10, marginRight: 20 },
  labelStyle = {
    color: "#A7A9AC",
    display: "block",
    fontSize: "0.85rem",
    lineHeight: "1rem",
    cursor: "pointer",
    paddingRight: "1.25rem",
    paddingLeft: "0.7rem",
  },
}) {
  return (
    <div
      style={{
        ...outerContainerStyle,
      }}
    >
      <div style={innerContainerStyle}>
        {label && <span style={labelStyle}>{label}</span>}

        <Select
          noOptionsMessage={() => noOptionsMessage}
          isLoading={isLoading}
          loadingMessage={() => 'Cargando...'}
          styles={customStyles}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => (
              <img
                style={dropdownIndicatorStyle}
                src="/images/down.png"
                alt=""
              />
            ),
          }}
          value={value}
          onChange={(selected) => {
            setValue(selected);
          }}
          isSearchable
          placeholder={placeholder}
          options={options}
        />
      </div>
    </div>
  );
}

function FiltrosOptionRender({
  filter,
  options,
  onClick,
  label,
  isLoading = false,
  placeholder = "Seleccionar",
  outerContainerStyle,
  dropdownIndicatorStyle,
  innerContainerStyle = {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    textAlign: "left",
    border: "1px solid #d2dde3",
    borderRadius: "4px",
    marginBottom: 5,
  },
  valueContainerStyle = {
    fontStyle: "italic",
    fontSize: "1.15rem",
    ":hover": {
      color: "#FFD340",
    },
  },
  labelStyle,
  placeholderStyle,
  inputStyle,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      ...valueContainerStyle,
    }),
    dropdownIndicator: (base) => {
      return {
        ...base,
        color: "#2a2a2a", // Custom colour
      };
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: state.isFocused ? "#FFD340" : "#2a2a2a",
        fontStyle: "italic",
        fontSize: "1.15rem",
        ":hover": {
          color: "#FFD340",
        },
        ...placeholderStyle,
      };
    },

    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
      minHeight: 0,
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "none",
      color: state.isFocused && "#2a2a2a",
      borderBottom: "1px solid #d2dde3",
      paddingRight: isMobile ? "1.1rem" : "1.3rem",
      paddingLeft: isMobile ? "1.1rem" : "1.3rem",
    }),
    input: (provided) => ({
      ...provided,
      ...inputStyle,
    }),
  };

  return (
    <RenderSearch
      noOptionsMessage="Marcas no encontradas"
      isLoading={isLoading ? true : false}
      isMobile={isMobile}
      width={width}
      label={label}
      customStyles={customStyles}
      value={filter}
      setValue={onClick}
      options={options}
      customWidth={260}
      placeholder={placeholder}
      outerContainerStyle={outerContainerStyle}
      innerContainerStyle={innerContainerStyle}
      dropdownIndicatorStyle={dropdownIndicatorStyle}
      labelStyle={labelStyle}
    />
  );
}

export default FiltrosOptionRender;
