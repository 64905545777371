/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { parseJwt } from "./utils";

function Header({ history }) {
  const [addShowClassName, setAddShowClassName] = useState(false);
  const [token, setToken] = useState(() => {
    const rawToken = localStorage.getItem("token");
    if (!rawToken) {
      return null;
    }
    return {
      raw: rawToken,
      decoded: parseJwt(rawToken),
    };
  });
  const [menuIsActive, setMenuIsActive] = useState(false);
  return (
    <header id="header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-2">
            <a
              onClick={(e) => {
                e.preventDefault();
                history.push("/");
              }}
              className="logo"
            ></a>
          </div>

          <div className="col-lg-10">
            <nav className="navbar navbar-expand-lg navbar-light">
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => {
                  setAddShowClassName(!addShowClassName);
                }}
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className={
                  "collapse navbar-collapse" + (addShowClassName ? " show" : "")
                }
                id="navbarNav"
                // style={{ margin: 25 }}
              >
                <ul className="navbar-nav align-items-center navbar-right ml-auto">
                  <li className="nav-item nav-login">
                    <div
                      id="menu-5"
                      className={
                        menuIsActive
                          ? "drop-down-trigger active"
                          : "drop-down-trigger"
                      }
                    >
                      <div
                        className="menu-5mobile"
                        onClick={() => setMenuIsActive(!menuIsActive)}
                      >
                        <div className="avatar">
                          <img
                            alt=""
                            className="img-fluid"
                            src="/images/user.jpg"
                          />
                        </div>
                        <div className="text-usu">
                          {/* <p>Bienvenido/a,</p> */}
                          <h3>
                            Bienvenido
                            <span className="icon-down icon-arrow"></span>
                          </h3>
                        </div>
                      </div>
                      <ul
                        className="menu-down search-options"
                        style={{ display: menuIsActive ? "block" : "none" }}
                      >
                        {token?.decoded ? (
                          <React.Fragment>
                            {token?.decoded?.rol === "superadmin" && (
                              <li>
                                <a href={process.env.REACT_APP_ADMIN_URL}>
                                  Administración
                                </a>
                              </li>
                            )}
                            <li>
                              <a
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  history.push("/publicaciones");
                                }}
                              >
                                Publicaciones
                              </a>
                            </li>
                            <li>
                              <a href="/logout">Cerrar sesión</a>
                            </li>
                          </React.Fragment>
                        ) : (
                          <li>
                            <a href="/login">Ingresar</a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item nav-vender">
                    <a
                      className="nav-link"
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/vender");
                      }}
                    >
                      Vender un vehículo
                    </a>
                  </li>
                </ul>
                {/* <ul className="navbar-nav navbar-left">
                  <li className="nav-item">
                    <a onClick={()=>history.push('/talleres')} className={window.location.href.includes('talleres') ? "nav-link active" : "nav-link"}>
                      Talleres
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a onClick={()=>history.push('/resultados')} className="nav-link" data-offset="1000">
                      Nuevos
                    </a>
                  </li> 
                </ul>
                <ul className="navbar-nav  navbar-right ml-auto">
                  <li className="nav-item nav-login">
                    <a className="nav-link btn btn-secondary" href="#">
                      Ingresar
                    </a>
                  </li>
                  <li className="nav-item nav-vender">
                    <a className="nav-link" href="#">
                      Vender un vehículo
                    </a>
                  </li>
                </ul> */}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
