import React from "react";

export default function Alert({ visible, type, text, style }) {
  // message.type puede ser:
  // primary
  // success
  // danger
  // warning

  if (!visible) {
    return null;
  }

  const mType = type ? type : "primary";
  return (
    <div className={`alert alert-${mType}`} role="alert" style={style}>
      {text}
    </div>
  );
}
