import React, { useEffect, useState } from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function Phone({
  label,
  setValue,
  value,
  international,
  containerStyle,
  disabled=false,
}) {
  return (
    <React.Fragment>
      {label && (
        <p style={{ marginBottom: 5 }}>
          <strong>{label}</strong>
        </p>
      )}
      {international ? (
        <PhoneInput
          country={"py"}
          onChange={setValue}
          placeholder="Numero de teléfono"
          inputStyle={{ height: 33, borderRadius: 0, width: 100 }}
        />
      ) : (
        <PhoneInput
          countryCodeEditable={false}
          onlyCountries={["py"]}
          country={"py"}
          onChange={(phone) => {
          
            if (phone.length < 13) {
          
              setValue("+" + phone);
            }
          }}
          inputProps={{ value: value }}
          placeholder="+5959xxxxxxxx"
          disableDropdown={true}
          inputStyle={{ width: "100%" }}
          containerStyle={
            containerStyle
              ? containerStyle
              : { marginTop: "5px", marginBottom: "3px" }
          }
          disabled={disabled}
        />
      )}
    </React.Fragment>
  );
}
