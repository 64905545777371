import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import controller from "./duck";
import ReestablecerPassRender from "./ReestablecerPassRender";
import Footer from "../../utils/Footer";
import { logger, parseJwt, validateEmail } from "../../utils/utils";

const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || "vaun.com.py";

function Confirmation() {
  let history = useHistory();
  const dispatch = useDispatch();
  const modelOlvide = useSelector((state) => state.olvidePass);
  const [username, setUsername] = useState(null);
  const [submitErrorMsg, setSubmitErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);


  useEffect(() => {
    const data = modelOlvide?.data?.data;
    const error = modelOlvide?.error?.response?.data?.message;

    if (error) {
      setSuccessMsg(null);
      setSubmitErrorMsg(error);
      dispatch(controller.duckOlvide.actions.clear());
    }
    if (data) {
      setSuccessMsg("Se ha enviado un mail con el link para la recuperacion");
      setSubmitErrorMsg(null);
      dispatch(controller.duckOlvide.actions.clear());
    }
  }, [modelOlvide]);



  function submit() {

    if (!validateEmail(username)) {
      alert("Por favor proporcione un email con el formato correcto");
      return;
    }
    if (!username) {
      return alert("Por favor complete todos los campos");
    }
    let data = {
      username
    };
    dispatch(controller.olvidePassThunk(data));
  }

  return (
    <React.Fragment>
      <ReestablecerPassRender
        history={history}
        setUsername={setUsername}
        submit={submit}
        isLoading={modelOlvide.loading}
        submitErrorMsg={submitErrorMsg}
        successMsg={successMsg}
      />
      <Footer />
    </React.Fragment>
  );
}

export default Confirmation;
