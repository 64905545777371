import createDuck, { createAction, createThunk } from "../utils/actions";
import axios from "../net/axios";
import config from "../config";
import { logger } from "../utils/utils";

let baseUrl = config.api.baseUrl;

const newActions = {
  test: (name) => createAction("NAME", name),
};

const newReducers = {
  NAME: (state, action) => {
    const nState = { data: action.payload, loading: false, error: null };
    return { ...state, ...nState };
  },
};

const duck = createDuck("SEARCH_PUBLICACIONES", newActions, newReducers);

const searchPublicacionesThunk = (data) => {
  const url = `${baseUrl}/getProductosByAttrs`;
  return createThunk(axios, "post", duck.actions, url, data);
};

const duck2 = createDuck("SEARCH_PUBLICACIONES2", newActions, newReducers);

const searchPublicaciones2Thunk = (data) => {
  const url = `${baseUrl}/getProductosByAttrs`;
  return createThunk(axios, "post", duck2.actions, url, data);
};

const duckMarcas = createDuck("GET_MARCAS", newActions, newReducers);

const getMarcasThunk = () => {
  const url = `${baseUrl}/getMarcas`;
  return createThunk(axios, "get", duckMarcas.actions, url, null);
};

const duckGetPublicacionCompleta = createDuck(
  "GET_PUBLICACION_COMPLETA",
  newActions,
  newReducers
);

const getPublicacionCompletaThunk = (id, ssi) => {
  const url = `${baseUrl}/getPublicacionCompletaById/${id}/${ssi}`;
  return createThunk(
    axios,
    "get",
    duckGetPublicacionCompleta.actions,
    url,
    null
  );
};

const duckContenidoByOrigen = createDuck(
  "GET_CONTENIDOS_BY_ORIGEN",
  newActions,
  newReducers
);

const getContenidoByIdThunk = (origen) => {
  const url = `${baseUrl}/contenidosByOrigen/${origen}`;
  return createThunk(axios, "get", duckContenidoByOrigen.actions, url);
};

const duckGetModelos = createDuck("GET_MODELOS");

const getModelosThunk = (marca) => {
  const url = `${baseUrl}/marca/${marca}/modelos`;
  return createThunk(axios, "get", duckGetModelos.actions, url, {});
};

const duckGetSegurosForSelect = createDuck(
  "GET_SEGUROS_FOR_SELECT",
  newActions,
  newReducers
);

const getSegurosForSelectThunk = (page, limit) => {
  const url = `${baseUrl}/getSegurosForSelect`;
  return createThunk(axios, "get", duckGetSegurosForSelect.actions, url);
};

const duckGetTalleresBySeguro = createDuck(
  "TALLERES_BY_SEGURO",
  newActions,
  newReducers
);

const getTalleresBySeguroThunk = (data) => {
  const url = `${baseUrl}/getTalleresBySeguro`;
  return createThunk(axios, "post", duckGetTalleresBySeguro.actions, url, data);
};

const duckAddPublicacion = createDuck("ADD_PUBLICACION");

const addPublicacionThunk = (data) => {
  logger.debug("addPublicacionThunk");
  const url = `${baseUrl}/startPublicacion`;
  return createThunk(axios, "post", duckAddPublicacion.actions, url, data);
};

const duckGetOrganizaciones = createDuck("ORGANIZACION_GET_ALL");

const getOrganizacionesThunk = () => {
  const url = `${baseUrl}/organizacion/`;
  return createThunk(axios, "get", duckGetOrganizaciones.actions, url);
};

const duckGetPublicacionIncompleta = createDuck("GET_PUBLICACION_INCOMPLETA");

const getPublicacionIncompletaThunk = (id) => {
  const url = `${baseUrl}/getPublicacionIncompletaById/${id}`;
  return createThunk(axios, "get", duckGetPublicacionIncompleta.actions, url, null);
};

const duckUpdateProductDetails = createDuck("UPDATE_PRODUCT_DETAILS");

const updateProductDetailsThunk = (publicacion) => {
  const url = `${baseUrl}/updateProductDetails`;
  return createThunk(axios, "patch", duckUpdateProductDetails.actions, url, {publicacion});
};

const duckEliminarImgPublicacion = createDuck("ELIMINAR_IMG_PROFESIONAL");

const eliminarImgPublicacion = (data) => {
  const url = `${baseUrl}/eliminarImgPublicacionV3`;
  return createThunk(axios, "post", duckEliminarImgPublicacion.actions, url, data);
};

const duckOrdenarImagenes = createDuck("ORDENAR_IMAGENES");

const ordenarImagenesThunk = (data) => {
  const url = `${baseUrl}/updateImagesOrder`;
  return createThunk(axios, "post", duckOrdenarImagenes.actions, url, data);
};

const duckPublicacionesByUser = createDuck("GET_PUBLICACIONES_BY_USER");

const getPublicacionesByUserThunk = (token, activePage, limit) => {
  const url = `${baseUrl}/publicacionesByUser/${token}?page=${activePage}&limit=${limit}`;
  return createThunk(axios, "get", duckPublicacionesByUser.actions, url, {});
};

const duckOrganizacion = createDuck("ORGANIZACIONBYID");

const organizacionByIdThunk = (id) => {
  const url = `${baseUrl}/organizacion/${id}`;
  return createThunk(axios, "get", duckOrganizacion.actions, url, null);
};

const duckAddVisita = createDuck("ADD_VISITA");

const addVisitaThunk = (id) => {
  const url = `${baseUrl}/addVisita`;
  return createThunk(axios, "post", duckAddVisita.actions, url, {id});
};

const duckOrganizacionByUrl = createDuck("ORGANIZACIONBYID");

const organizacionByUrlThunk = (id) => {
  const url = `${baseUrl}/organizacionByUrl/${id}`;
  return createThunk(axios, "get", duckOrganizacionByUrl.actions, url, null);
};

const duckCotizacionActiva = createDuck(
  "GET_COTIZACION_ACTIVA",
  newActions,
  newReducers
);

const getCotizacionActivaThunk = () => {
  const url = `${baseUrl}/getCotizacionActiva`;
  return createThunk(axios, "post", duckCotizacionActiva.actions, url, null);
};

const duckUrlByDomain = createDuck(
  "GET_URL_BY_DOMAIN",
  newActions,
  newReducers
);

const getUrlByDomainThunk = (domain) => {
  const url = `${baseUrl}/getOrganizacionUrlByDomain`;
  return createThunk(axios, "post", duckUrlByDomain.actions, url, {domain});
};


const exp = {
  duck,
  duckMarcas,
  searchPublicacionesThunk,
  getMarcasThunk,
  duckGetPublicacionCompleta,
  getPublicacionCompletaThunk,
  duckContenidoByOrigen,
  getContenidoByIdThunk,
  duckGetModelos,
  getModelosThunk,
  duckGetSegurosForSelect,
  getSegurosForSelectThunk,
  duckGetTalleresBySeguro,
  getTalleresBySeguroThunk,
  duckAddPublicacion,
  addPublicacionThunk,
  duckGetOrganizaciones,
  getOrganizacionesThunk,
  duckGetPublicacionIncompleta,
  getPublicacionIncompletaThunk,
  duckUpdateProductDetails,
  updateProductDetailsThunk,  
  duckEliminarImgPublicacion,
  eliminarImgPublicacion,
  duckOrdenarImagenes,
  ordenarImagenesThunk,
  duckPublicacionesByUser,
  getPublicacionesByUserThunk,
  duckOrganizacion,
  organizacionByIdThunk,
  duckAddVisita,
  addVisitaThunk,
  duckOrganizacionByUrl,
  organizacionByUrlThunk,
  duckCotizacionActiva,
  getCotizacionActivaThunk,
  duckUrlByDomain,
  getUrlByDomainThunk,
  duck2,
  searchPublicaciones2Thunk
};

export default exp;
