/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { getLinkWithSize } from "../../utils/utils";

export default function ImagenSubida({
  showButtons = true,
  esPrincipal,
  imagenObj,
  doEliminarImagen,
}) {
  return (
    <div className="drag-drop">
      <img
        src={imagenObj?.url ? getLinkWithSize(imagenObj.url, "medium") : ""}
        alt=""
        className="img-subir"
      />
      {showButtons ? (
        <div className="cont-imgsub">
          {esPrincipal ? (
            <span className="img-datprincipal active">Principal</span>
          ) : null}
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              doEliminarImagen(imagenObj._id);
            }}
            className="eliminar-x"
          >
            <img src="/images/eliminar-x.png" alt="" className="img-fluid" />
          </a>
        </div>
      ) : null}
    </div>
  );
}
