const imgRestrictionsByConcepto = {
  publicacion: {
    minFileTotal: 1,
    upload: {
      maxFileCount: 1,
      maxFileSize: 4.3,
      minWidth: 404,
      minHeight: 300,
    },
    crop: {
      minCroppedWidth: 404,
      minCroppedHeight: 300,
      maxCroppedWidth: 2424,
      maxCroppedHeight: 1800,
      aspectRatioNumerator: 1,
      aspectRatioDenominator: 0.742,
    },
  },
};

const config = {
  api: {
    baseUrl: process.env.REACT_APP_API_URL,
    key: process.env.REACT_APP_API_KEY,
  },
  imgRestrictionsByConcepto,
};

export default config;
