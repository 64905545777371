import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import Header from "../../utils/Header";
import BannerWithSearch from "./BannerWithSearch";
import SliderTamanhos from "./SliderTamanhos";
import MarcasDestacadas from "./MarcasDestacadas";
import SliderRecientes from "./SliderRecientes";
import Encontra from "./Encontra";
import Populares from "./Populares";
import BannerVender from "./BannerVender";
import Footer from "../../utils/Footer";
import controller from "../duck";
import { parseJwt } from "../../utils/utils";

function Home() {

  let history = useHistory();
  const dispatch = useDispatch();
  const modelMarcas = useSelector((state) => state.marcas);
  const modelModelos = useSelector((state) => state.modelos);
  const modelCotizacion = useSelector((state) => state.getCotizacionActiva);
  const modelPublicaciones = useSelector((state) => state.publicaciones);
  const modelContenido = useSelector((state) => state.contenido);
  const [cotizacion, setCotizacion] = useState(0);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [marca, setMarca] = useState(null);
  const [modelo, setModelo] = useState(null);
  const [anho, setAnho] = useState(null);
  const [publicaciones, setPublicaciones] = useState(null);
  const [contenido, setContenido] = useState(null);
  useEffect(() => {
    const data = modelContenido?.data?.data;
    const error = modelContenido?.error?.message;

    if (error) {
      alert(error);
      dispatch(controller.duckContenidoByOrigen.actions.clear());
    }
    if (data) {
      setContenido(data.data);
      dispatch(controller.duckContenidoByOrigen.actions.clear());
    }
  }, [modelContenido]);

  useEffect(() => {
    const data = modelPublicaciones?.data?.data;
    const error = modelPublicaciones?.error?.message;

    if (error) {
      alert(error);
      dispatch(controller.duck.actions.clear());
    }
    if (data) {
      setPublicaciones(data.data.items);
      dispatch(controller.duck.actions.clear());
    }
  }, [modelPublicaciones]);

  useEffect(() => {
    const data = modelMarcas?.data?.data;
    const error = modelMarcas?.error?.message;

    if (error) {
      alert(error);
      dispatch(controller.duckMarcas.actions.clear());
    }
    if (data) {
      setMarcas(data.data);
      dispatch(controller.duckMarcas.actions.clear());
    }
  }, [modelMarcas]);

  useEffect(() => {
    const data = modelCotizacion?.data?.data;
    const error = modelCotizacion?.error?.message;

    if (error) {
      alert(error);
      dispatch(controller.duckCotizacionActiva.actions.clear())
    }
    if (data) {
      setCotizacion(data.data.valor);
      const datos = {
        limit: 6,
        page: 1,
        orderby: "ts",
        tipoOrden: -1,
      };
      dispatch(controller.searchPublicacionesThunk(datos));
      dispatch(controller.duckCotizacionActiva.actions.clear());
    }
  }, [modelCotizacion]);

  useEffect(() => {
    const data = modelModelos?.data?.data;
    const error = modelModelos?.error;
    const errorMessage = modelModelos?.error?.message;

    if (error) {
      const errorToShow =
        errorMessage || "Ocurrió un error al realizar la acción";
      alert(errorToShow);
      dispatch(controller.duckGetModelos.actions.clear());
    }

    if (data) {
      setModelos(data.data);
      dispatch(controller.duckGetModelos.actions.clear());
    }
  }, [modelModelos]);

  useEffect(() => {

    dispatch(controller.getContenidoByIdThunk("home"));
    dispatch(controller.getMarcasThunk());
    dispatch(controller.getCotizacionActivaThunk());
    const analytics = getAnalytics();
    logEvent(analytics, "funnel", {
      action: "home",
    });
  }, []);

  useEffect(() => {
    if (marca) {
      dispatch(controller.getModelosThunk(marca.value));
    }
  }, [marca]);

  function searchPublicaciones() {
    let searchString = "";
    if (marca) {
      searchString =
        searchString.length === 0
          ? searchString + "marca=" + marca.value
          : searchString + "&marca=" + marca.value;
    }

    if (modelo) {
      searchString =
        searchString.length === 0
          ? searchString + "modelo=" + modelo.value
          : searchString + "&modelo=" + modelo.value;
    }

    if (anho) {
      searchString =
        searchString.length === 0
          ? searchString + "anhos=" + anho.value
          : searchString + "&anhos=" + anho.value;
    }

    history.push({
      pathname: "/resultados",
      search: searchString.length > 0 ? searchString : null,
    });
  }

  function searchCuerpo(cuerpo, precio) {
    const analytics = getAnalytics();
    logEvent(analytics, "home_action", {
      action: "tamanho",
    });
    let searchString = "cuerpo=" + cuerpo + "&precio=" + precio + "-0";

    history.push({
      pathname: "/resultados",
      search: searchString.length > 0 ? searchString : null,
    });
  }

  function searchMarca(value) {
    const analytics = getAnalytics();
    logEvent(analytics, "home_action", {
      action: "marcadestacada",
    });
    let searchString = "marca=" + value;

    history.push({
      pathname: "/resultados",
      search: searchString.length > 0 ? searchString : null,
    });
  }

  function searchCategoria(value, origen) {
    const analytics = getAnalytics();
    logEvent(analytics, "home_action", {
      action: origen,
    });
    history.push({
      pathname: "/resultados",
      search: value.length > 0 ? value : null,
    });
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>VAUN | Venta de vehículos</title>
        <meta name="title" content="VAUN | Venta de vehículos" />
        <meta property="og:url" content={window.location.href}/>
        <meta property="og:title" content="VAUN | Venta de vehiculos"/>
      </Helmet>
      <Header history={history} />
      <BannerWithSearch
        history={history}
        marcas={marcas}
        marca={marca}
        setMarca={setMarca}
        modelos={modelos}
        modelo={modelo}
        setModelo={setModelo}
        anho={anho}
        setAnho={setAnho}
        searchPublicaciones={searchPublicaciones}
        contenido={contenido}
        loadingMarcas={modelMarcas.loading}
        loadingModedlos={modelModelos.loading}
      />
      <div className="bg-degradado-gris">
        <SliderTamanhos
          history={history}
          searchCuerpo={searchCuerpo}
          contenido={contenido}
        />
        <MarcasDestacadas
          history={history}
          searchMarca={searchMarca}
          contenido={contenido}
        />
        <SliderRecientes history={history} publicaciones={publicaciones} cotizacion={cotizacion}/>
      </div>
      <Encontra
        history={history}
        searchCategoria={searchCategoria}
        contenido={contenido}
      />
      <Populares
        history={history}
        searchCategoria={searchCategoria}
        contenido={contenido}
      />

      {/* <BannerVender history={history} /> */}
      <Footer history={history} />
    </React.Fragment>
  );
}

export default Home;
