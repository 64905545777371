import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import _ from "lodash";
import controller from "./publicaciones/duck";
import Home from "./publicaciones/home/Home";
import Landing from "./publicaciones/home/Landing";
import Resultados from "./publicaciones/results/Resultados";
import Publicacion from "./publicaciones/publicacion/Publicacion";
import Comparativo from "./publicaciones/comparativo/Comparativo";
import Login from "./publicaciones/auth/Login";
import Vender from "./publicaciones/vender/Vender";
import InformacionBasica from "./publicaciones/publicar/InformacionBasica";
import Detalles from "./publicaciones/publicar/Detalles";
import NotFound from "./utils/NotFound";
import TalleresMap from "./publicaciones/talleres/TalleresMap";
import SubirImagenes from "./imageUploadV3/SubirImagenes";
import CortarImagenesConcepto from "./imageUploadV3/CortarImagenesConcepto";
import ImagenesPaso1 from "./publicaciones/publicar/ImagenesPaso1";
import MisPublicaciones from "./publicaciones/mispublicaciones/MisPublicaciones";
import EditarPerfil from "./usuario/EditarPerfil";
import Gracias from "./publicaciones/publicar/Gracias";
import WithAuth from "./utils/WithAuth";
import Logout from "./auth/Logout";
import Perfil from "./publicaciones/results/Perfil";
import Register from "./publicaciones/register/Register";
import Confirmation from "./publicaciones/register/Confirmation";
import ReestablecerPass from "./publicaciones/register/ReestablecerPass";
import ChangePassByToken from "./publicaciones/register/ChangePassByToken";

const firebaseConfig = {
  apiKey: "AIzaSyBQtRSpoG3szG4wZUHpnAiAquyMFUUH3WY",
  authDomain: "vaun-334012.firebaseapp.com",
  projectId: "vaun-334012",
  storageBucket: "vaun-334012.appspot.com",
  messagingSenderId: "651898992576",
  appId: "1:651898992576:web:912dc176d7238a4b9e7a25",
  measurementId: "G-43HC0T7Y5L",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const dispatch = useDispatch();
  const modelUrlbyDomain = useSelector((state) => state.urlByDomain);
  const [datosOrg, setDatosOrg] = useState(null);
  const [loading, setLoading] = useState(true);
  const calledFrom = window.location.host.split(".")[0];

  // cambio para forzar deploy

  useEffect(() => {
    const data = modelUrlbyDomain?.data?.data;
    const error = modelUrlbyDomain?.error?.response?.data?.message;
    if (error) {
      dispatch(controller.duckUrlByDomain.actions.clear());
    }
    if (data) {
   
      setLoading(false);
      setDatosOrg(data?.data);
      dispatch(controller.duckUrlByDomain.actions.clear());
    }
  }, [modelUrlbyDomain]);

  useEffect(() => {
    if (calledFrom !== "vaun" || calledFrom !== "max") {
      dispatch(controller.getUrlByDomainThunk(calledFrom));
    } else {
      setLoading(false);
    }
  }, []);

  if(loading){
    return (
      <Router>
        <Switch>
          <Route component={Landing} />
        </Switch>
      </Router>
    );
  }
  if (datosOrg) {
    return (
      <Router>
        <Switch>
          <Route exact path="/publicacion/:desc/:id/:ssi?">
            <Publicacion
              outsideDomain={true}
              colorPrincipal={datosOrg.colorPrincipal}
              colorSecundario={datosOrg.colorSecundario}
              instagram={datosOrg.instagram}
            />
          </Route>
          <Route exact path="/resultados">
            <Perfil
              url={datosOrg.perfilLink}
              outsideDomain={true}
              colorPrincipal={datosOrg.colorPrincipal}
              colorSecundario={datosOrg.colorSecundario}
              instagram={datosOrg.instagram}
            />
          </Route>
          <Route exact path="/">
            <Perfil
              url={datosOrg.perfilLink}
              outsideDomain={true}
              colorPrincipal={datosOrg.colorPrincipal}
              colorSecundario={datosOrg.colorSecundario}
              instagram={datosOrg.instagram}
            />
          </Route>
          <Route exact path="/vaun">
            <Perfil
              url={datosOrg.perfilLink}
              outsideDomain={true}
              colorPrincipal={datosOrg.colorPrincipal}
              colorSecundario={datosOrg.colorSecundario}
              instagram={datosOrg.instagram}
            />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/logout">
          <Logout />
        </Route>
        <Route exact path="/talleres">
          <TalleresMap />
        </Route>
        <Route exact path="/resultados">
          <Resultados />
        </Route>
        <Route exact path="/editarPerfil">
          <WithAuth children={EditarPerfil} />
        </Route>
        <Route exact path="/publicacion/:desc/:id/:ssi?">
          <Publicacion />
        </Route>
        <Route exact path="/publicaciones">
          <WithAuth children={MisPublicaciones} />
        </Route>
        <Route exact path="/organizacion/:id">
          <Perfil />
        </Route>
        <Route exact path="/comparativo">
          <Comparativo />
        </Route>
        <Route exact path="/vender">
          <Vender />
        </Route>
        <Route exact path="/publicar/info/:publicacionID?">
          <WithAuth children={InformacionBasica} />
        </Route>
        <Route exact path="/publicar/imagenes/:publicacionID">
          <WithAuth children={ImagenesPaso1} />
        </Route>
        <Route exact path="/subirImagenes/">
          <WithAuth children={SubirImagenes} />
        </Route>
        <Route exact path="/cortarImagenes/">
          <WithAuth children={CortarImagenesConcepto} />
        </Route>
        <Route exact path="/publicar/detalles/:publicacionID">
          <WithAuth children={Detalles} />
        </Route>
        <Route exact path="/publicar/gracias">
          <WithAuth children={Gracias} />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/registro">
          <Register />
        </Route>
        <Route exact path="/confirmation/:id">
          <Confirmation />
        </Route>
        <Route exact path="/reestablecerpass">
          <ReestablecerPass />
        </Route>
        <Route exact path="/changePass/:resetToken">
          <ChangePassByToken />
        </Route>

        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
