import React from "react";
import Loader from "./Loader";
import { thousandSeparator } from "./utils";

function NoResults({ history, isLoading, sugerencias }) {
  return (
    <div className="col-lg-9">
      <section className="resultados-list">
        <div className="cards-resultados">
          <div className="row gutter-7">
            <div className="col-lg-8 offset-lg-2">
              <div className="text-center mt-5">
                <Loader
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30vh",
                  }}
                  isLoading={isLoading}
                  loaderSize={30}
                >
                  <figure className="ico-noresults mb-3">
                    <img src="/images/alto.png" alt="" />
                  </figure>
                  <h2>No se encontraron coincidencia</h2>
                  <p>
                    No encontramos coincidencias con las palabras buscadas.
                    Prueba otras palabras claves o filtros de busqueda.
                  </p>
                </Loader>
              </div>
            </div>
          </div>
        </div>
        {sugerencias && sugerencias.length > 0 ? (
          <>
            <h2 style={{ marginTop: 20 }}>Publicaciones sugeridas</h2>
            <div className="cards-resultados">
              <div className="row gutter-7">
                {sugerencias &&
                  sugerencias.length > 0 &&
                  sugerencias.map((row, index) => (
                    <div key={index} className="col-6 col-md-4">
                      <article className="product-item">
                        <figure className="img">
                          <a
                            onClick={() =>
                              history.push(
                                `/publicacion/${row.attrs.marca}-${row.attrs.modelo.replace('/', '_')}-${row.attrs.anho}-${row.attrs.kilometraje}/${row._id}`
                              )
                            }
                            className="img-link"
                          >
                            <img src={row.imagenes[0].url} alt="" />
                          </a>
                          {/* <a className="fav-container">
                  <span className="fav"></span>
                </a> */}
                        </figure>
                        <div className="desc">
                          <h4 className="title">
                            <a
                              onClick={() =>
                                history.push("/publicacion/" + row._id)
                              }
                            >
                              {row.attrs.marca + " " + row.attrs.modelo}
                            </a>
                            {row.attrs.kilometraje === 0 ? (
                              <div
                                className="details"
                                style={{ marginTop: 10 }}
                              >
                                {/* <span>
                                  {row.attrs.anho + " | "}
                                </span> */}

                                <strong
                                  style={{
                                    backgroundColor: "#ffc107",
                                    borderRadius: 15,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  {row.attrs.anho +
                                    " | " +
                                    thousandSeparator(row.attrs.kilometraje) +
                                    " km"}
                                </strong>
                              </div>
                            ) : (
                              <div
                                className="details"
                                style={{ marginTop: 10 }}
                              >
                                {row.attrs.anho +
                                  " | " +
                                  thousandSeparator(row.attrs.kilometraje) +
                                  " km"}
                              </div>
                            )}
                          </h4>
                          <p className="price">
                            USD. {thousandSeparator(row.precio, true)}
                          </p>
                        </div>
                        <div className="bottom">
                          <div className="bottom-item">
                            <img
                              src="images/ico-combustible.png"
                              alt="Combulstible"
                              className="ico"
                            />
                            {row.attrs.combustible}
                          </div>
                          <div className="bottom-item">
                            <img
                              src="images/ico-caja.png"
                              alt="Caja"
                              className="ico"
                            />
                            {row.attrs.caja}
                          </div>
                          {/* <div className="bottom-item favoritos">
                  <a
                    href="#"
                    className="fav"
                    title="Agregar a favoritos"
                  ></a>
                </div> */}
                        </div>
                      </article>
                    </div>
                  ))}
              </div>
              <div style={{ marginTop: 20, textAlign: "center" }}>
                <p style={{ textAlign: "center" }}>
                  ¿No encontraste lo que necesitas?
                </p>
                <a
                  style={{ color: "#343a40" }}
                  href=""
                  onClick={() =>
                      history.push('/resultados')
                  }
                >
                  <strong>
                    <u>Ver todas las publicaciones</u>
                  </strong>
                </a>
              </div>
            </div>
          </>
        ) : null}
      </section>
    </div>
  );
}

export default NoResults;
