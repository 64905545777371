import React, { useCallback, useEffect } from "react";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

export default function CortarImagen({
  cropperRef,
  cropperInstance,
  cropperStyle = { height: 400, width: "100%" },
  urlImagen,
  onCropperReady,
  minCroppedWidth,
  minCroppedHeight,
  maxCroppedWidth,
  maxCroppedHeight,
  aspectRatioNumerator,
  aspectRatioDenominator,
}) {

  useEffect(() => {
    //componentWillUnmount
    return () => {
      cropperInstance.current.destroy();
      cropperInstance.current = null;
    }
  }, [])

  const onInitialized = (instance) => {
    cropperInstance.current = instance;
  };

  const validateCropDimensions = () => {

    // Si todavía no hay instancia, no hacer nada.

    if (!cropperInstance.current) {
      return;
    }

    const data = cropperInstance.current.getData();

    const width = data.width;
    const height = data.height;

    // Si el usuario intentó croppear fuera de las
    // dimensiones permitidas, entonces
    // setear como valores las dimensiones límite.

    if (
      width < minCroppedWidth
      || height < minCroppedHeight
      // || width > maxCroppedWidth
      // || height > maxCroppedHeight
    ) {
      cropperInstance.current.setData({
        width: Math.max(minCroppedWidth, Math.min(maxCroppedWidth, width)),
        height: Math.max(minCroppedHeight, Math.min(maxCroppedHeight, height)),
      });
    }
  };

  const onCrop = useCallback(validateCropDimensions, [
    cropperInstance,
    minCroppedWidth,
    minCroppedHeight,
    // maxCroppedWidth,
    // maxCroppedHeight,
  ]);

  const onReady = () => {
    validateCropDimensions();
    onCropperReady && onCropperReady();
  }; 

  return (
    <Cropper
      style={cropperStyle}
      ref={cropperRef}
      ready={onReady}
      onInitialized={onInitialized}
      cropend={onCrop}
      src={urlImagen}
      aspectRatio={aspectRatioNumerator / aspectRatioDenominator}
      viewMode={1}
      guides={false}      
      rotatable={false}
      responsive={true}
      restore={true}
      zoomable={false}
    />
  );
}
