import React from "react";
import queryString from "query-string";
import { useHistory, useParams } from "react-router-dom";

import { logger } from "../utils/utils";
import UploaderWrapper from "./UploaderWrapper";

export default function SubirImagenes(props) {

  let history = useHistory();

  const params = queryString.parse(history.location.search);

  const {
      maxFileCount,
      maxFileSize,
      minWidth,
      minHeight,
      uploadUrl,
      rawUploadHeaders,
      nextStepUrl,
      pageTitle,
      pageSubtitle,
      nextButtonText,
      saveInConceptUrl,
      cropConfig,
      concepto,
      conceptoId,
      lastStepUrl,
      cropUploadUrl,
      relatedImageId
    } = params;

  logger.debug("SubirImagenes.parsedQueryString");
  logger.debug(params);

  // const {
  //   maxFileCount,
  //   maxFileSize,
  //   minWidth,
  //   minHeight,
  //   uploadUrl,
  //   rawUploadHeaders,
  //   nextStepUrl,
  //   pageTitle,
  //   pageSubtitle,
  //   nextButtonText,
  //   saveInConceptUrl,
  //   cropConfig,
  //   concepto,
  //   conceptoId,
  //   lastStepUrl,
  //   cropUploadUrl,
  //   relatedImageId,
  // } = params;

  // logger.debug('SubirImagenes.params');
  // logger.debug({params});

  const uploadHeaders = JSON.parse(rawUploadHeaders);  

  const navigateToNextStep = () => {
    const nextUrlParams = queryString.stringify({
      saveInConceptUrl,
      conceptoId,
      concepto,
      cropConfig,
      lastStepUrl,
      rawUploadHeaders,
      cropUploadUrl,
      relatedImageId,
    });

    logger.debug("SubirImagenes.nextUrlParams");
    logger.debug(nextUrlParams);

    history.push(`${nextStepUrl}?${nextUrlParams}`);
  };  

  const onUploadError = (responseText, response) => {
    let parsedResponse = JSON.parse(responseText);
    logger.debug("onUploadError.parsedResponse", parsedResponse);
    if (parsedResponse && parsedResponse.message) {
      alert(parsedResponse.message);
    }
  };

  const messageStyle = { backgroundColor: "orange", padding: 10, fontSize: 18 };

  return (
    <div className="SubidaImagen">
      <h3>{pageTitle}</h3>
      <h4>{pageSubtitle}</h4>
      <div style={messageStyle}>
        <p>Tamaño máximo de imagen: {maxFileSize} MB</p>
        <p>Ancho mínimo: {minWidth} px</p>
        <p>Alto mínimo: {minHeight} px</p>
      </div>
      <UploaderWrapper
        maxFileCount={maxFileCount}
        maxFileSize={maxFileSize}
        minWidth={minWidth}
        minHeight={minHeight}
        uploadUrl={uploadUrl}
        uploadHeaders={uploadHeaders}
        onUploadError={onUploadError}
      />
      <button onClick={navigateToNextStep}>{nextButtonText}</button>
    </div>
  );
}
