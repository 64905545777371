/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useHistory, useParams } from "react-router-dom";
import controller from "../duck";
import authController from "../auth/duck";
import {
  colorOptions,
  duenhosOptions,
  traccionOptions,
  financiacionOptions,
  aceptaUsadoOptions,
  estadoOptions,
  estadoOptionLabelsByValue,
  duenhosOptionLabelsByValue,
  financiacionOptionLabelsByViewValue,
  usadoOptionLabelsByViewValue,
  logger,
  parseJwt,
} from "../../utils/utils";

import Loader from "../../utils/Loader";
import HeaderPublicar from "./HeaderPublicar";
import Pasos from "./Pasos";
import Footer from "../../utils/Footer";
import Phone from "../../utils/Phone";
import PrecioInput from "./PrecioInput";
import Alert from "../../utils/Alert";
import FiltrosOptionRender from "./../results/FiltrosOptionRender";

const outerContainerStyle = {
  width: "100%",
  marginRight: "0.75rem",
  marginBottom: "0.5rem",
};

const innerContainerStyle = {
  padding: "0.5rem 0 0 0",
  borderRadius: "4px",
  position: "relative",
  border: "1px solid #d2dde3",
  textAlign: "left",
  cursor: "pointer",
  width: "100%",
  backgroundColor: "#ffffff",
};

const loaderStyle = {
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
};

const loaderSize = 30;

const getListForSelect = (data) => {
  if (data && data instanceof Array) {
    let resultado = data.map((element) => {
      return { value: element._id, label: element.name };
    });

    return resultado;
  }
  return [];
};

function Detalles({}) {
  const dispatch = useDispatch();
  let history = useHistory();
  const { publicacionID } = useParams(null);
  const decodedToken = parseJwt(localStorage.getItem("token"));

  const modelUpdate = useSelector((state) => state.updateProductDetails);
  const modelOrganizaciones = useSelector((state) => state.getOrganizaciones);
  const modelPublicacionIncompleta = useSelector(
    (state) => state.publicacionIncompleta
  );
  const modelUsuario = useSelector((state) => state.getUsuarioByCredencial);

  const [publicacion, setPublicacion] = useState("");
  const [estado, setEstado] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [precio, setPrecio] = useState("");
  const [duenhos, setDuenhos] = useState("");
  const [color, setColor] = useState("");
  const [traccion, setTraccion] = useState("");
  const [version, setVersion] = useState("");
  const [pasoAnterior, setPasoAnterior] = useState(false);
  const [organizacionID, setOrganizacionID] = useState("");
  const [organizacionesForSelect, setOrganizacionesForSelect] = useState([]);
  const [organizacionesOptionsById, setOrganizacionesOptionsById] =
    useState(null);
  const [telefono, setTelefono] = useState("+595");
  const [aceptaFinanciacion, setAceptaFinanciacion] = useState(null);
  const [aceptaUsado, setAceptaUsado] = useState(null);
  const [generalErrorMsg, setGeneralErrorMsg] = useState("");
  const [submitErrorMsg, setSubmitErrorMsg] = useState("");

  useEffect(() => {
    if (!publicacion) {
      dispatch(controller.getOrganizacionesThunk());
    }
  }, [publicacion]);

  useEffect(() => {
    if (!usuario) {
      dispatch(authController.getUsuarioByCredencialThunk(decodedToken.id));
    }
  }, [usuario]);

  useEffect(() => {
    const data = modelOrganizaciones?.data?.data;
    const error = modelOrganizaciones?.error;

    if (error) {
      const errorMessage =
        modelOrganizaciones?.error?.response?.data?.message ||
        "Ocurrió un error al obtener organizaciones.";
      setGeneralErrorMsg(errorMessage);
      dispatch(controller.duckGetOrganizaciones.actions.clear());
      return;
    }

    if (data) {
      const listForSelect = getListForSelect(data.data);
      const newOrgOptById = {};
      listForSelect.forEach((organizacionOption) => {
        const organizacionId = organizacionOption.value;
        newOrgOptById[organizacionId] = organizacionOption;
      });
      setOrganizacionesForSelect(listForSelect);
      logger.debug("modelOrganizaciones.useEffect.newOrgOptById");
      logger.debug(newOrgOptById);
      setOrganizacionesOptionsById(newOrgOptById);
      dispatch(controller.duckGetOrganizaciones.actions.clear());
      dispatch(controller.getPublicacionIncompletaThunk(publicacionID));
    }
  }, [modelOrganizaciones]);

  useEffect(() => {
    const data = modelPublicacionIncompleta?.data?.data;
    const error = modelPublicacionIncompleta?.error;

    if (error) {
      const errorMessage =
        modelPublicacionIncompleta?.error?.response?.data?.message ||
        "Ocurrió un error al obtener datos de publicación. Por favor, recargue la página";
      setGeneralErrorMsg(errorMessage);
      dispatch(controller.duck.actions.clear());
      return;
    }
    if (data) {
      logger.debug({ publicacionIncompleta: data });

      if (data.data?.estado === "ELIMINADO") {
        history.push("/");
        return;
      }

      if (
        data.data._id &&
        data.data.attrs &&
        data.data.attrs.marca &&
        data.data.attrs.modelo
      ) {
        if (
          !Array.isArray(data.data.imagenes) ||
          (Array.isArray(data.data.imagenes) && data.data.imagenes.length === 0)
        ) {
          history.push("/publicar/imagenes/" + publicacionID);
          return;
        }

        let orgId = null;

        if (data.data.organizacionID) {
          orgId = data.data.organizacionID;
        } else if (
          decodedToken.rol !== "superadmin" &&
          decodedToken.organizacionID &&
          decodedToken.organizacionID !== ""
        ) {
          orgId = decodedToken.organizacionID;
        }

        setOrganizacionID(orgId);
        setPublicacion(data.data);
        setEstado("VALIDANDO");
        setPrecio(data.data.precio ? data.data.precio : "");
        setAceptaFinanciacion(
          data.data.aceptaFinanciacion === false
            ? "no"
            : data.data.aceptaFinanciacion === true
            ? "si"
            : null
        );
        setAceptaUsado(
          data.data.aceptaUsado === false
            ? "no"
            : data.data.aceptaUsado === true
            ? "si"
            : null
        );

        if (data.data.attrs) {
          const pubAttrs = data.data.attrs;
          setDuenhos(pubAttrs.duenhos ? pubAttrs.duenhos : null);
          setColor(pubAttrs.color ? pubAttrs.color : null);
          setTraccion(pubAttrs.traccion ? pubAttrs.traccion : null);
          setVersion(pubAttrs.version ? pubAttrs.version : "");
        }

        dispatch(controller.duckGetPublicacionIncompleta.actions.clear());
      } else {
        const errorMessage =
          "Ha ocurrido un error al obtener la publicación, por favor intente nuevamente recargando la página";
        setGeneralErrorMsg(errorMessage);
        dispatch(controller.duckGetPublicacionIncompleta.actions.clear());
      }
    }
  }, [modelPublicacionIncompleta]);

  useEffect(() => {
    const data = modelUpdate?.data?.data;
    const error = modelUpdate?.error;

    if (error) {
      const errorMessage =
        modelUpdate?.error?.response?.data?.message ||
        "Ocurrió un error al actualizar datos de publicación. Por favor, intente nuevamente";
      setSubmitErrorMsg(errorMessage);
      dispatch(controller.duckUpdateProductDetails.actions.clear());
    }
    if (data) {
      dispatch(controller.duckUpdateProductDetails.actions.clear());
      logger.debug("finished");
      const analytics = getAnalytics();
      logEvent(analytics, "estadisticas", {
        action: "publicacionesNuevas",
      });
      history.push("/publicar/gracias");

    }
  }, [modelUpdate]);

  useEffect(() => {
    const data = modelUsuario?.data?.data?.data;
    const error = modelUsuario?.error;

    if (error) {
      const errorMessage =
        modelUsuario?.error?.response?.data?.message ||
        "Ocurrió un error al consultar datos de usuario. Por favor, intente nuevamente recargando la página";
      setGeneralErrorMsg(errorMessage);
      dispatch(authController.duckGetUsuarioByCredencial.actions.clear());
    }

    if (data) {
      logger.debug("modelUsuario.useEffect.ifData");
      logger.debug(data);
      setUsuario(data);
      dispatch(authController.duckGetUsuarioByCredencial.actions.clear());
    }
  }, [modelUsuario]);

  const submit = () => {
    logger.debug("submit");
    logger.debug({
      precio,
      duenhos,
      traccion,
      version,
      color,
      organizacionID,
      rol: decodedToken.rol,
      usuarioTelefono: usuario.phoneNumber,
      telefono,
      aceptaFinanciacion,
      aceptaUsado,
      estado,
    });

    setSubmitErrorMsg("");

    // [START chequear telefono]

    // Si usuario no es superadmin, y  le falta teléfono o
    // no es válido, se te tuvo que haber
    // mostrado el input de teléfono para cargar
    // la variable "telefono" y esa variable
    // tiene que tener un teléfono válido.
    // Si esa variable no tiene teléfono válido
    // entonces usuario no escribió un teléfono válido.
    // Si usuario es superadmin o si el teléfono que tiene
    // cargado en su usuario es válido, no actualizar
    // teléfono.

    let updateTelefonoEnUsuario = true;

    if (
      decodedToken.rol !== "superadmin" &&
      (!usuario?.phoneNumber || usuario?.phoneNumber?.length < 13) &&
      !usuario?.organizacionID
    ) {
      if (telefono.length < 13) {
        setSubmitErrorMsg(
          "El teléfono debe tener el formato +595xyzabcdef. Ejemplo: +595961222333"
        );
        return;
      }
    } else {
      updateTelefonoEnUsuario = false;
    }

    // [END chequear telefono]

    if (
      !precio ||
      !duenhos ||
      !traccion ||
      !version ||
      !color ||
      (decodedToken.rol === "superadmin" && !organizacionID) ||
      !aceptaFinanciacion ||
      !aceptaUsado ||
      !estado
    ) {
      logger.debug("Detalles.submit.ifNot");
      setSubmitErrorMsg("Por favor complete todos los campos");
      return;
    }

    if (!publicacion.imagenes || publicacion.imagenes.length === 0) {
      setSubmitErrorMsg(
        "La publicación no posee imágenes. Por favor, suba imágenes para poder guardar la publicación."
      );
      return;
    }

    const oldPublicacion = { ...publicacion };

    oldPublicacion.precio = precio;
    oldPublicacion.estado = estado;
    oldPublicacion.verifiedBy = null;
    oldPublicacion.attrs.duenhos = Number(duenhos);
    // oldPublicacion.attrs.kilometraje = Number(kilometraje);
    oldPublicacion.sattrs = {};
    oldPublicacion.attrs.color = color;
    oldPublicacion.attrs.traccion = traccion;
    oldPublicacion.attrs.version = version;
    oldPublicacion.organizacionID = organizacionID;
    oldPublicacion.credencialId = decodedToken.id;
    oldPublicacion.aceptaFinanciacion =
      aceptaFinanciacion === "si" ? true : false;
    oldPublicacion.aceptaUsado = aceptaUsado === "si" ? true : false;

    if (updateTelefonoEnUsuario) {
      oldPublicacion.telefono = telefono;
    }

   

    dispatch(controller.updateProductDetailsThunk(oldPublicacion));
  };
  const loading =
    modelOrganizaciones.loading ||
    modelPublicacionIncompleta.loading ||
    modelUsuario.loading ||
    (!publicacion && !generalErrorMsg);

  const mostrarSelectOrganizacion =
    parseJwt(localStorage.getItem("token")).rol === "superadmin" ? true : false;

  let mostrarInputTelefono = false;

  if (parseJwt(localStorage.getItem("token")).rol !== "superadmin") {
    if (
      (!usuario?.phoneNumber?.length || usuario?.phoneNumber?.length < 13) &&
      !usuario?.organizacionID
    ) {
      mostrarInputTelefono = true;
    }
  }

  // render

  return (
    <Loader style={loaderStyle} isLoading={loading} loaderSize={loaderSize}>
      <section className="contenedor-pasos" style={{ minHeight: "100vh" }}>
        <HeaderPublicar />
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Pasos publicacionID={publicacionID} pasoNro={3} />
            </div>
            <div className="col-lg-9">
              <div className="container container-content">
                <section style={{ paddingTop: "4.5rem" }}>
                  {generalErrorMsg && (
                    <Alert
                      type="danger"
                      text={generalErrorMsg}
                      visible={true}
                      style={{ textAlign: "left" }}
                    />
                  )}
                  <div className="contenedor-log fulllist">
                    <div className="box-log" style={{ overflow: "visible" }}>
                      <div className="pre-box nueva">
                        <div className="box-content">
                          <figure>
                            <img
                              src="/images/bell.png"
                              alt=""
                              className="ico-bell"
                            />
                          </figure>
                          <div
                            className="titulo-notis"
                            style={{ width: "100%" }}
                          >
                            <h3 style={{ margin: 0, fontSize: "30px" }}>
                              Estado
                            </h3>
                            <p className="titulo-dia">
                              {/* <img alt="" src="/images/clock.png"/> */}
                              <FiltrosOptionRender
                                filter={{
                                  label: estadoOptionLabelsByValue[estado],
                                  value: estado,
                                }}
                                label={""}
                                placeholder="Seleccionar"
                                options={estadoOptions}
                                onClick={(selected) => {
                                  setEstado(selected.value);
                                }}
                                isLoading={false}
                                outerContainerStyle={outerContainerStyle}
                                innerContainerStyle={innerContainerStyle}
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="content-otrosdatos">
                  <h1 className="titulo-otrodatos">Otros Datos del Vehículo</h1>
                  <div className="content-otrodato1">
                    <FiltrosOptionRender
                      filter={
                        traccion === null
                          ? null
                          : { label: traccion, value: traccion }
                      }
                      options={traccionOptions}
                      onClick={(optionObj) => setTraccion(optionObj.value)}
                      label="Tracción"
                      isLoading={false}
                      placeholder="Seleccionar"
                      outerContainerStyle={outerContainerStyle}
                      innerContainerStyle={innerContainerStyle}
                    />
                    <FiltrosOptionRender
                      filter={
                        color === null ? null : { label: color, value: color }
                      }
                      options={colorOptions}
                      onClick={(optionObj) => setColor(optionObj.value)}
                      label={"Color"}
                      isLoading={false}
                      placeholder="Seleccionar"
                      outerContainerStyle={outerContainerStyle}
                      innerContainerStyle={innerContainerStyle}
                    />
                    <FiltrosOptionRender
                      filter={
                        duenhos === null
                          ? null
                          : {
                              label: duenhosOptionLabelsByValue[duenhos],
                              value: duenhos,
                            }
                      }
                      options={duenhosOptions}
                      onClick={(optionObj) => setDuenhos(optionObj.value)}
                      label={"Dueños"}
                      isLoading={false}
                      placeholder="Seleccionar"
                      outerContainerStyle={{
                        ...outerContainerStyle,
                        marginRight: 0,
                      }}
                      innerContainerStyle={innerContainerStyle}
                    />
                  </div>
                  <div className="content-otrodato2">
                    <div
                      className="drop-down-trigger drop-down-filter"
                      id="version"
                    >
                      <label>Versión</label>
                      <input
                        type="text"
                        className="input-text"
                        placeholder="Ej. ACTIV 1.8 ECONO FLEX 5P A/T"
                        value={version}
                        onChange={(evt) => setVersion(evt.target.value)}
                      />
                    </div>
                  </div>
                  {(mostrarSelectOrganizacion || mostrarInputTelefono) && (
                    <section className="contenido-datovendedor">
                      <h2 className="titulo-datoven">Datos del Vendedor</h2>
                      <div className="content-datovend">
                        {mostrarSelectOrganizacion && (
                          <FiltrosOptionRender
                            filter={
                              organizacionID === null ||
                              !organizacionesOptionsById
                                ? null
                                : organizacionesOptionsById[organizacionID]
                            }
                            options={organizacionesForSelect}
                            onClick={(optionObj) =>
                              setOrganizacionID(optionObj.value)
                            }
                            label="Organización"
                            isLoading={false}
                            placeholder="Seleccionar"
                            outerContainerStyle={outerContainerStyle}
                            innerContainerStyle={innerContainerStyle}
                            inputStyle={{ margin: 0 }}
                          />
                        )}
                        {mostrarInputTelefono && (
                          <div className="drop-down-trigger drop-down-filter">
                            <label>Número de teléfono</label>
                            <Phone
                              value={telefono}
                              setValue={setTelefono}
                              international={false}
                            />
                            {/* <input
                              type="text"
                              placeholder="Ej. 0981365444"
                              value={telefono}
                              onChange={(evt) => setTelefono(evt.target.value)}
                            /> */}
                          </div>
                        )}
                      </div>
                    </section>
                  )}
                  <section className="contenido-precio">
                    <PrecioInput value={precio} setValue={setPrecio} />
                  </section>
                  <div className="content-otrodato1">
                    <FiltrosOptionRender
                      filter={
                        aceptaFinanciacion === null
                          ? null
                          : {
                              label:
                                financiacionOptionLabelsByViewValue[
                                  aceptaFinanciacion
                                ],
                              value: aceptaFinanciacion,
                            }
                      }
                      options={financiacionOptions}
                      onClick={(optionObj) =>
                        setAceptaFinanciacion(optionObj.value)
                      }
                      label="Ofrece financiación"
                      isLoading={false}
                      placeholder="Seleccionar"
                      outerContainerStyle={outerContainerStyle}
                      innerContainerStyle={innerContainerStyle}
                      inputStyle={{ margin: 0 }}
                    />
                    <FiltrosOptionRender
                      filter={
                        aceptaUsado === null
                          ? null
                          : {
                              label: usadoOptionLabelsByViewValue[aceptaUsado],
                              value: aceptaUsado,
                            }
                      }
                      options={aceptaUsadoOptions}
                      onClick={(optionObj) => setAceptaUsado(optionObj.value)}
                      label="Acepta usados como parte de pago"
                      isLoading={false}
                      placeholder="Seleccionar"
                      outerContainerStyle={outerContainerStyle}
                      innerContainerStyle={innerContainerStyle}
                      inputStyle={{ margin: 0 }}
                    />
                  </div>
                  {submitErrorMsg && (
                    <Alert
                      type="danger"
                      text={submitErrorMsg}
                      visible={true}
                      style={{ textAlign: "left" }}
                    />
                  )}
                  <div className="content-btncon">
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/publicar/imagenes/${publicacionID}`);
                      }}
                      className="link-volver"
                    >
                      <img alt="" src="/images/left.png" />
                      Volver
                    </a>
                    <button
                      onClick={() => submit()}
                      className="btn btn-primary dark"
                      disabled={modelUpdate.loading}
                    >
                      <Loader
                        isLoading={modelUpdate.loading}
                        loaderSize={19}
                        loaderColor="#fff"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Publicar
                      </Loader>
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer history={history} />
    </Loader>
  );
}

export default Detalles;
