import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import controller from "../duck";
import { logger } from "../../utils/utils";
import ImagenesListaPublicar from "./ImagenesListaPublicar";

function ImagenesPaso1() {
  let history = useHistory();
  const dispatch = useDispatch();

  const { publicacionID } = useParams(null);

  const { token, setToken } = useState(() => localStorage.getItem("token"));

  const [imagenes, setImagenes] = useState();
  const [imagenesListaLoading, setImagenesListaLoading] = useState(true);

  const [imagenesDnD, setImagenesDnD] = useState([]);
  const [isOrdering, setIsOrdering] = useState(false);

  const [generalErrorMsg, setGeneralErrorMsg] = useState("");

  const modelPublicacionIncompleta = useSelector(
    (state) => state.publicacionIncompleta
  );

  const modelEliminacionImgPublicacion = useSelector((state) => {
    logger.debug("useSelector.state", state);
    logger.debug(
      "useSelector.state.modelEliminacionImgPublicacion",
      state.eliminarImgPublicacion
    );
    return state.eliminarImgPublicacion;
  });

  const modelOrdenarImagenes = useSelector((state) => {
    return state.ordenarImagenes;
  });

  useEffect(() => {
    dispatch(controller.getPublicacionIncompletaThunk(publicacionID));
  }, []);

  useEffect(() => {
    const data = modelPublicacionIncompleta?.data?.data;
    const error = modelPublicacionIncompleta?.error;

    if (error) {
      const errorMessage =
        modelPublicacionIncompleta?.error?.response?.data?.message ||
        "Ocurrió un error al obtener datos de la publicación.";
      setGeneralErrorMsg(errorMessage);
      dispatch(controller.duck.actions.clear());
      setImagenesListaLoading(false);
    }
    if (data) {
      if (data?.data?.estado === "ELIMINADO") {
        history.push("/");
        return;
      }

      if (data?.data?._id) {
        logger.debug({ publicacionIncompleta: data });
        setImagenes(data.data.imagenes);
        setImagenesDnD(data.data.imagenes);
        setImagenesListaLoading(false);
        dispatch(controller.duckGetPublicacionIncompleta.actions.clear());
      } else {
        history.push("/publicar/info/" + publicacionID);
        return;
      }
    }
  }, [modelPublicacionIncompleta, dispatch]);

  const doEliminarImagen = (imagenID) => {
    setGeneralErrorMsg("");
    setImagenesListaLoading(true);
    logger.debug("doEliminarImagen.imagenID", imagenID);
    logger.debug("doEliminarImagen.publicacionID", publicacionID);
    let data = {
      imagenID,
      publicacionID: publicacionID,
      token,
    };
    dispatch(controller.eliminarImgPublicacion(data));
  };

  useEffect(() => {
    logger.debug(
      "EditServicioHijo.useEffect(modelEliminacionImgPublicacion)",
      modelEliminacionImgPublicacion
    );

    // TODO usar "selectors" para data y error

    const data = modelEliminacionImgPublicacion?.data?.data?.data;
    const error = modelEliminacionImgPublicacion?.error;

    logger.debug(
      "CortarImagenesServicio.useEffect.modelEliminacionImgPublicacion"
    );
    logger.debug(modelEliminacionImgPublicacion);

    if (error) {
      const errorMessage =
        modelEliminacionImgPublicacion?.error?.response?.data?.message ||
        "Ocurrió un error al eliminar imagen.";
      setGeneralErrorMsg(errorMessage);
      setImagenesListaLoading(false);
      dispatch(controller.duckEliminarImgPublicacion.actions.clear());
    }

    if (data) {
      logger.debug("Se pudo eliminar", data);
      dispatch(controller.getPublicacionIncompletaThunk(publicacionID));
    }
  }, [modelEliminacionImgPublicacion, dispatch]);

  useEffect(() => {
    const data = modelOrdenarImagenes?.data?.data?.data;
    const error = modelOrdenarImagenes?.error;

    if (error) {
      const errorMessage =
        modelOrdenarImagenes?.error?.response?.data?.message ||
        "Ocurrió un error al guardar el orden de las imágenes.";
      setGeneralErrorMsg(errorMessage);
      dispatch(controller.duckOrdenarImagenes.actions.clear());
    }
    if (data) {
      setImagenes([]);
      setImagenesDnD([]);
      setIsOrdering(false);
      dispatch(controller.getPublicacionIncompletaThunk(publicacionID));
      dispatch(controller.duckOrdenarImagenes.actions.clear());
    }
  }, [modelOrdenarImagenes]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(imagenesDnD);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setImagenesDnD(items);
  }

  function handleOrder(value) {
    logger.debug("handleOrder");
    if (value === true) {
      setIsOrdering(true);
    } else {
      setGeneralErrorMsg("");
      dispatch(
        controller.ordenarImagenesThunk({
          _id: publicacionID,
          imagenes: imagenesDnD,
        })
      );
    }
  }

  return (
    <ImagenesListaPublicar
      publicacionID={publicacionID}
      imagenes={imagenes}
      doEliminarImagen={doEliminarImagen}
      imagenesListaLoading={imagenesListaLoading}
      handleOnDragEnd={handleOnDragEnd}
      handleOrder={handleOrder}
      isOrdering={isOrdering}
      imagenesDnD={imagenesDnD}
      generalErrorMsg={generalErrorMsg}
    />
  );
}

export default ImagenesPaso1;
