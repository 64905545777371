import React, { useEffect, useState } from "react";
import _ from "lodash";

function Sugerencias({ history }) {
  return (
    <section className="sugerencias-comparar">
      <div className="container text-center">
        <h2>Comparar con otros similares</h2>
        <div className="row gutter-7">
          <div className="col-6 col-lg-3">
            <article className="product-item">
              <figure className="img">
                <a href="producto" className="img-link">
                  <img src="images/thumb.jpg" alt="" />
                </a>
                <a href="#" className="fav-container">
                  <span className="fav"></span>
                </a>
              </figure>
              <div className="desc">
                <h4 className="title">
                  <a href="producto">Chevrolet Onix Joy</a>
                  <span className="details">2010 | 125.478 km</span>
                </h4>
                <p className="price">Gs. 34.0000.000</p>
              </div>
              <div className="bottom">
                <div className="bottom-item">
                  <img
                    src="images/ico-combustible.png"
                    alt="Combulstible"
                    className="ico"
                  />
                  Flex
                </div>
                <div className="bottom-item">
                  <img src="images/ico-caja.png" alt="Caja" className="ico" />
                  Mecanico
                </div>
                <div className="bottom-item favoritos">
                  <a href="#" className="fav" title="Agregar a favoritos"></a>
                </div>
              </div>
            </article>
          </div>
          <div className="col-6 col-lg-3">
            <article className="product-item">
              <figure className="img">
                <a href="producto" className="img-link">
                  <img src="images/thumb.jpg" alt="" />
                </a>
                <a href="#" className="fav-container">
                  <span className="fav"></span>
                </a>
              </figure>
              <div className="desc">
                <h4 className="title">
                  <a href="producto">Chevrolet Onix Joy</a>
                  <span className="details">2010 | 125.478 km</span>
                </h4>
                <p className="price">Gs. 34.0000.000</p>
              </div>
              <div className="bottom">
                <div className="bottom-item">
                  <img
                    src="images/ico-combustible.png"
                    alt="Combulstible"
                    className="ico"
                  />
                  Flex
                </div>
                <div className="bottom-item">
                  <img src="images/ico-caja.png" alt="Caja" className="ico" />
                  Mecanico
                </div>
                <div className="bottom-item favoritos">
                  <a href="#" className="fav" title="Agregar a favoritos"></a>
                </div>
              </div>
            </article>
          </div>
          <div className="col-6 col-lg-3">
            <article className="product-item">
              <figure className="img">
                <a href="producto" className="img-link">
                  <img src="images/thumb.jpg" alt="" />
                </a>
                <a href="#" className="fav-container">
                  <span className="fav"></span>
                </a>
              </figure>
              <div className="desc">
                <h4 className="title">
                  <a href="producto">Chevrolet Onix Joy</a>
                  <span className="details">2010 | 125.478 km</span>
                </h4>
                <p className="price">Gs. 34.0000.000</p>
              </div>
              <div className="bottom">
                <div className="bottom-item">
                  <img
                    src="images/ico-combustible.png"
                    alt="Combulstible"
                    className="ico"
                  />
                  Flex
                </div>
                <div className="bottom-item">
                  <img src="images/ico-caja.png" alt="Caja" className="ico" />
                  Mecanico
                </div>
                <div className="bottom-item favoritos">
                  <a href="#" className="fav" title="Agregar a favoritos"></a>
                </div>
              </div>
            </article>
          </div>
          <div className="col-6 col-lg-3">
            <article className="product-item">
              <figure className="img">
                <a href="producto" className="img-link">
                  <img src="images/thumb.jpg" alt="" />
                </a>
                <a href="#" className="fav-container">
                  <span className="fav"></span>
                </a>
              </figure>
              <div className="desc">
                <h4 className="title">
                  <a href="producto">Chevrolet Onix Joy</a>
                  <span className="details">2010 | 125.478 km</span>
                </h4>
                <p className="price">Gs. 34.0000.000</p>
              </div>
              <div className="bottom">
                <div className="bottom-item">
                  <img
                    src="images/ico-combustible.png"
                    alt="Combulstible"
                    className="ico"
                  />
                  Flex
                </div>
                <div className="bottom-item">
                  <img src="images/ico-caja.png" alt="Caja" className="ico" />
                  Mecanico
                </div>
                <div className="bottom-item favoritos">
                  <a href="#" className="fav" title="Agregar a favoritos"></a>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sugerencias;
