import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  cajaOptions,
  combustibleOptions,
  tipoVendedorOptions,
  duenhosOptions,
  kilometrajeOptions,
  cuerpoOptions,
  precioOptions,
  colorOptions,
} from "../../utils/utils";
import FiltrosOptionRender from "./FiltrosOptionRender";
import FiltrosInputRender from "./FiltrosInputRender";
import moment from "moment";

function FiltrosView({
  perfil,
  setMarca,
  setModelo,
  setTipoVendedor,
  setCombustible,
  setCaja,
  setDuenhos,
  setKilometrajeMax,
  setKilometrajeMin,
  setKilometrajeDesc,
  setCuerpo,
  setPrecioDesc,
  setPrecioMin,
  setPrecioMax,
  marca,
  modelo,
  tipoVendedor,
  combustible,
  caja,
  duenhos,
  kilometrajeDesc,
  cuerpo,
  precioDesc,
  handleFilters,
  getFilter,
  marcas,
  modelos,
  handleKilometraje,
  setActivePage,
  searchPublicaciones,
  clearFilters,
  handleFilter,
  handleSelectAnhos,
  handleSelectCuerpo,
  handleSelectColor,
  anhos,
  setAnhos,
  loadingMarcas,
  loadingModelos,
  color,
  setColor,
  precioMin,
  precioMax,
  kilometrajeMax,
  kilometrajeMin,
  uso,
  setUso,
  handleUso,
  outsideDomain,
}) {
  const images = {
    van: "/images/ico-van.png",
    camioneta: "/images/ico-camioneta.png",
    camion: "/images/ico-camion.png",
    hatchback: "/images/ico-hatchback.png",
    pickup: "/images/ico-pickup.png",
    sedan: "/images/ico-sedan.png",
    suv: "/images/ico-suv.png",
  };
  const step = 3;
  const step2 = 5;
  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const [years, setYears] = useState(
    getYearsFromSomeYear(Number(moment().format("YYYY")) - step)
  );

  const [showYears, setShowYears] = useState(true);
  const [showUso, setShowUso] = useState(true);
  const [showColors, setShowColors] = useState(false);

  function getYearsFromSomeYear(initialYear) {
    const finalYear = moment().format("YYYY");
    const yearsAux = [];
    for (let i = initialYear; i <= Number(finalYear) + 1; i++) {
      if (i >= 1990) {
        yearsAux.push(i);
      }
    }
    return yearsAux.reverse();
  }

  function handleMoreYears() {
    const lastYearUsed = years[years.length - 1];
    setYears(getYearsFromSomeYear(Number(lastYearUsed) - step2));
  }

  const getImage = (cuerpo) => {
    const cuerpoLowerCase = cuerpo ? cuerpo.toLowerCase() : "";
    if (cuerpoLowerCase.includes("hatchback")) {
      return images["hatchback"];
    } else if (cuerpoLowerCase.includes("pickup")) {
      return images["pickup"];
    } else if (cuerpoLowerCase.includes("sedan")) {
      return images["sedan"];
    } else if (cuerpoLowerCase.includes("camioneta")) {
      return images["camioneta"];
    } else if (cuerpoLowerCase.includes("camion")) {
      return images["camion"];
    } else if (cuerpoLowerCase.includes("van")) {
      return images["van"];
    } else {
      return images["suv"];
    }
  };

  return (
    <div className="col-lg-3">
      {perfil ? (
        <section className="logo-vendedor">
          <div
            className="ficha-vendedor-res"
            style={
              outsideDomain
                ? {
                    backgroundImage: "url(/images/cropped.jpeg)",
                    backgroundSize: "cover",
                  }
                : {}
            }
          >
            <div className="ficha-title clearfix"></div>
            <div className="info-vendedor">
              <figure className="avatar">
                <img
                  src={
                    perfil && perfil.imagenes && perfil.imagenes[0]
                      ? perfil.imagenes[0].url
                      : "/images/user.jpg"
                  }
                  alt="Vendedor"
                />
              </figure>
            </div>
            <h4 className="name-com" style={outsideDomain ? {color:"#ffffff"} : {}}><span>{perfil.name}</span></h4>
            {perfil?.address?.direccion ? (
              <div className="ubicacion shadowBackground">
                <a
                  target="_blank"
                  href={`http://www.google.com/maps/place/${perfil.address.lat},${perfil.address.lng}`}
                  className="ciudad-res link"
                  rel="noreferrer"
                >
                  {perfil.address.direccion}
                </a>
              </div>
            ) : null}

            <div className="acciones">
              <a
                href={"tel:" + perfil.phoneNumber}
                className="btn btn-contactar"
              >
                Llamar
              </a>
              <a
                href={
                  "https://api.whatsapp.com/send?phone=" + perfil.phoneNumber
                }
                className="btn btn-whatsapp"
              >
                <img
                  src="/images/logo-what.png"
                  alt="icono"
                  className="ico-what"
                />
                Whatsapp
              </a>
            </div>
          </div>
        </section>
      ) : null}
      <aside className="sidebar-resultados">
        <div className="mobile-header">
          <h2>Filtrar resultados</h2>
          <a
            onClick={() => handleFilter()}
            href="#"
            className="close-filtrar"
          ></a>
        </div>
        <section className="filtros-activos">
          <div className="header clearfix">
            <h3 className="title">Filtros activos</h3>
            <a
              onClick={() => {
                clearFilters();
              }}
              className="clear-filters link"
            >
              Limpiar
            </a>
          </div>
          <div className="filters">
            <ul className="activos-list">
              {marca && (
                <li onClick={() => setMarca(null)}>
                  <a href="#">{marca.label}</a>
                </li>
              )}
              {modelo && (
                <li onClick={() => setModelo(null)}>
                  <a href="#">{modelo.label}</a>
                </li>
              )}

              {tipoVendedor && (
                <li onClick={() => setTipoVendedor(null)}>
                  <a href="#">{tipoVendedor.label}</a>
                </li>
              )}
              {combustible && (
                <li onClick={() => setCombustible(null)}>
                  <a href="#">{combustible.label}</a>
                </li>
              )}

              {caja && (
                <li onClick={() => setCaja(null)}>
                  <a href="#">{caja.label}</a>
                </li>
              )}
              {duenhos && (
                <li onClick={() => setDuenhos(null)}>
                  <a href="#">{duenhos.label}</a>
                </li>
              )}

              {anhos &&
                anhos.length > 0 &&
                anhos.map((row) => (
                  <li
                    key={row}
                    onClick={() => {
                      const arrayAnhos = anhos.filter(
                        (element) => element != row
                      );
                      setAnhos(arrayAnhos);
                    }}
                  >
                    <a href="#">{row}</a>
                  </li>
                ))}

              {uso &&
                uso.length > 0 &&
                uso.map((row) => (
                  <li
                    key={row}
                    onClick={() => {
                      const arrayAnhos = uso.filter(
                        (element) => element != row
                      );
                      setUso(arrayAnhos);
                    }}
                  >
                    <a href="#">{row}</a>
                  </li>
                ))}

              {cuerpo &&
                cuerpo.length > 0 &&
                cuerpo.map((row) => (
                  <li
                    key={row.value}
                    onClick={() => {
                      const arrayCuerpo = cuerpo.filter(
                        (element) => element.value != row.value
                      );
                      setCuerpo(arrayCuerpo);
                    }}
                  >
                    <a href="#">{row.label}</a>
                  </li>
                ))}

              {color &&
                color.length > 0 &&
                color.map((row) => (
                  <li
                    key={row.value}
                    onClick={() => {
                      const arrayColor = color.filter(
                        (element) => element.value != row.value
                      );
                      setColor(arrayColor);
                    }}
                  >
                    <a href="#">{row.label}</a>
                  </li>
                ))}

              {precioMin && Number(precioMin) > 0 && (
                <li
                  onClick={() => {
                    setPrecioMin(null);
                  }}
                >
                  <a href="#">{"Min " + addCommas(precioMin) + " USD."}</a>
                </li>
              )}

              {precioMax && Number(precioMax) > 0 && (
                <li
                  onClick={() => {
                    setPrecioMax(null);
                  }}
                >
                  <a href="#">{"Max " + addCommas(precioMax) + " USD."}</a>
                </li>
              )}

              {kilometrajeMin && Number(kilometrajeMin) > 0 && (
                <li
                  onClick={() => {
                    setKilometrajeMin(null);
                  }}
                >
                  <a href="#">{"Min " + addCommas(kilometrajeMin) + " km."}</a>
                </li>
              )}

              {kilometrajeMax && Number(kilometrajeMax) > 0 && (
                <li
                  onClick={() => {
                    setKilometrajeMax(null);
                  }}
                >
                  <a href="#">{"Max " + addCommas(kilometrajeMax) + " km."}</a>
                </li>
              )}
            </ul>
          </div>
        </section>
        <button
          style={{ marginBottom: 15 }}
          onClick={() => {
            if (kilometrajeMin > 0 && uso && uso.includes("0 KM")) {
              handleUso("0 KM");
            }
            handleFilter();
            setActivePage(1);
            searchPublicaciones();
          }}
          className="btn btn-primary dark"
        >
          Filtrar
        </button>
        <section className="filtros-opciones">
          <div className="header clearfix">
            <h3 className="title">Filtros de búsqueda</h3>
          </div>

          <div className="multiple-filter">
            <label onClick={() => setShowUso(!showUso)}>
              Vehiculo <span className="active-filter">({uso.length})</span>
            </label>
            {showUso && (
              <div className="filter-options">
                <ul className="list">
                  <li
                    onClick={() => handleUso("0 KM")}
                    className={uso && uso.includes("0 KM") ? "active" : ""}
                  >
                    {"0 KM"}
                    {/* <span className="qty">(258)</span> */}
                  </li>
                  <li
                    key={"Usados"}
                    onClick={() => handleUso("Usados")}
                    className={uso && uso.includes("Usados") ? "active" : ""}
                  >
                    {"Usados"}
                    {/* <span className="qty">(258)</span> */}
                  </li>
                </ul>
              </div>
            )}
          </div>

          <FiltrosOptionRender
            handleFilters={handleFilters}
            getFilter={getFilter}
            filter={marca}
            filterString={"marca"}
            options={marcas}
            onClick={setMarca}
            label={"Marca"}
            isLoading={loadingMarcas}
            placeholder="Todas las marcas"
          />

          <FiltrosOptionRender
            handleFilters={handleFilters}
            getFilter={getFilter}
            filter={modelo}
            filterString={"modelo"}
            options={modelos}
            onClick={setModelo}
            label={"Modelo"}
            isLoading={loadingModelos}
            placeholder="Todos los modelos"
          />

          <div className="multiple-filter">
            <label onClick={() => setShowYears(!showYears)}>
              Año <span className="active-filter">({anhos.length})</span>
            </label>
            {showYears && (
              <div className="filter-options">
                <ul className="list">
                  {years.map((row) => {
                    return (
                      <li
                        key={row}
                        onClick={() => handleSelectAnhos(row)}
                        className={
                          anhos &&
                          (anhos.includes(row) ||
                            anhos.includes(row.toString()))
                            ? "active"
                            : ""
                        }
                      >
                        {row}
                        {/* <span className="qty">(258)</span> */}
                      </li>
                    );
                  })}
                </ul>
                {years[years.length - 1] > 1990 ? (
                  <li
                    onClick={handleMoreYears}
                    style={{
                      textAlign: "center",
                      listStyle: "none",
                      borderTop: "1px solid #d2dde3",
                      fontSize: "0.85rem",
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 10,
                    }}
                  >
                    Ver mas
                  </li>
                ) : null}
              </div>
            )}
          </div>

          <FiltrosInputRender
            value={precioMin}
            setValue={setPrecioMin}
            label={"Precio Min. USD."}
            placeholder="Precio minimo"
          />

          <FiltrosInputRender
            value={precioMax}
            setValue={setPrecioMax}
            label={"Precio Max. USD."}
            placeholder="Precio maximo"
          />

          <div className="icon-filter">
            <label>Cuerpo del vehiculo</label>
            <div className="filter-options">
              <ul className="list">
                {cuerpoOptions &&
                  cuerpoOptions.map((row, index) => {
                    const isIncluded = cuerpo.filter(
                      (element) => element.value === row.value
                    );
                    return (
                      <li
                        key={index}
                        className={isIncluded.length > 0 ? "active" : ""}
                        title={row.label}
                      >
                        <a onClick={() => handleSelectCuerpo(row)} href="#">
                          <img src={getImage(row.value)} alt={row.label} />
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>

          <FiltrosInputRender
            value={kilometrajeMin}
            setValue={setKilometrajeMin}
            label={"Kilometraje Min."}
            placeholder="Kilometraje minimo"
          />

          <FiltrosInputRender
            value={kilometrajeMax}
            setValue={setKilometrajeMax}
            label={"Kilometraje Max."}
            placeholder="Kilometraje maximo"
          />

          <FiltrosOptionRender
            handleFilters={handleFilters}
            getFilter={getFilter}
            filter={caja}
            filterString={"caja"}
            options={cajaOptions}
            onClick={setCaja}
            label={"Caja"}
          />

          <FiltrosOptionRender
            handleFilters={handleFilters}
            getFilter={getFilter}
            filter={combustible}
            filterString={"combustible"}
            options={combustibleOptions}
            onClick={setCombustible}
            label={"Combustible"}
          />

          <div className="multiple-filter">
            <label onClick={() => setShowColors(!showColors)}>
              Color <span className="active-filter">({color.length})</span>
            </label>
            {showColors && (
              <div className="filter-options">
                <ul className="list">
                  {colorOptions.map((row) => {
                    const isIncluded = color.filter(
                      (element) => element.value === row.value
                    );
                    return (
                      <li
                        key={row.value}
                        onClick={() => handleSelectColor(row)}
                        className={isIncluded.length > 0 ? "active" : ""}
                      >
                        {row.label}
                        {/* <span className="qty">(258)</span> */}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>

          <FiltrosOptionRender
            handleFilters={handleFilters}
            getFilter={getFilter}
            filter={tipoVendedor}
            filterString={"tipovendedor"}
            options={tipoVendedorOptions}
            onClick={setTipoVendedor}
            label={"Tipo de vendedor"}
          />

          <FiltrosOptionRender
            handleFilters={handleFilters}
            getFilter={getFilter}
            filter={duenhos}
            filterString={"duenhos"}
            options={duenhosOptions}
            onClick={setDuenhos}
            label={"Dueños anteriores"}
          />

          <button
            style={{ marginTop: 15 }}
            onClick={() => {
              if (kilometrajeMin > 0 && uso && uso.includes("0 KM")) {
                handleUso("0 KM");
              }
              handleFilter();
              setActivePage(1);
              searchPublicaciones();
            }}
            className="btn btn-primary dark"
          >
            Filtrar
          </button>
        </section>
      </aside>
    </div>
  );
}

export default FiltrosView;
