import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Header from "../../utils/Header";
import BannerWithSearch from "./BannerWithSearch";
import SliderTamanhos from "./SliderTamanhos";
import MarcasDestacadas from "./MarcasDestacadas";
import SliderRecientes from "./SliderRecientes";
import Encontra from "./Encontra";
import Populares from "./Populares";
import BannerVender from "./BannerVender";
import Footer from "../../utils/Footer";
import Loader from "../../utils/Loader";
import controller from "../duck";

function Landing() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#ffc107",
      }}
    >
      <div style={{ position: "absolute" }}>
        <p style={{ textAlign: "center" }}>
          <img
            src="/images/logo-vaun.png"
            style={{ verticalAlign: "center" }}
          />
        </p>
        <p style={{ textAlign: "center" }}>
        <Loader loaderSize={30} isLoading={true}/>
          {/* <a
            href="https://instagram.com/vaun_py"
            target="_BLANK"
            title="Seguinos en Instagram:"
            rel="noreferrer"
          >
            <img src="/images/instagram.png" alt="Instagram" />
          </a> */}
        </p>
      </div>
    </div>
  );
}

export default Landing;
