/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Loader from "../../utils/Loader";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HeaderPublicar from "./HeaderPublicar";
import Pasos from "./Pasos";
import Footer from "../../utils/Footer";
import ImagenSubida from "./ImagenSubida";
import Alert from "../../utils/Alert";

const loaderStyle = {
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
};

const loaderSize = 30;

export default function ImagenesListaPublicarView({
  isLoading,
  imagenesListaLoading,
  publicacionID,
  isOrdering,
  config,
  imagenesDnD,
  handleOnDragEnd,
  imagenes,
  elements,
  handleOrder,
  buildImageRows,
  history,
  generalErrorMsg,
}) {
  return (
    <Loader
      style={loaderStyle}
      isLoading={isLoading || imagenesListaLoading}
      loaderSize={loaderSize}
    >
      <section className="contenedor-pasos" style={{ minHeight: "100vh" }}>
        <HeaderPublicar />
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Pasos publicacionID={publicacionID} pasoNro={2} />
            </div>
            <div className="col-lg-9">
              <div className="container container-content">
                <div className="content-subirImg">
                  <div className="titulos-subirImg">
                    <h1 className="tit-subirImg">
                      {!isOrdering ? "Subir imágenes" : "Ordenar imágenes"}
                    </h1>
                    <p className="tit-subi">
                      {!isOrdering
                        ? `(Máximo ${config.imgRestrictionsByConcepto.publicacion.upload.maxFileSize}MB)`
                        : 'Clic en "Aplicar cambios" para guardar'}
                    </p>
                  </div>
                  {!isOrdering && (
                    <div className="enc-subImg">
                      <p className="copete-subImg">
                        Debes agregar al menos{" "}
                        {
                          config.imgRestrictionsByConcepto.publicacion
                            .minFileTotal
                        }{" "}
                        {config.imgRestrictionsByConcepto.publicacion
                          .minFileTotal > 1
                          ? "imágenes"
                          : "imagen"}
                        . Prestá atención a la calidad y tamaño de las imágenes.
                        Asegurate que se puedan apreciar todos los detalles.
                      </p>
                      <p className="cop-subImg">
                        <strong>{imagenes?.length}</strong> imagenes de{" "}
                        <strong>6</strong>
                      </p>
                    </div>
                  )}
                  {generalErrorMsg && (
                    <Alert
                      type="danger"
                      text={generalErrorMsg}
                      visible={true}
                      style={{ textAlign: "left" }}
                    />
                  )}
                  <div className="contenedor-archivos">
                    {isOrdering && imagenesDnD && imagenesDnD.length ? (
                      <div>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                          <Droppable droppableId="characters">
                            {(provided) => (
                              <ul
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {imagenesDnD
                                  ? imagenesDnD.map(({ _id, url }, index) => {
                                      return (
                                        <Draggable
                                          key={_id}
                                          draggableId={_id}
                                          index={index}
                                        >
                                          {(provided) => (
                                            <li
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <div
                                                  className="content-upload"
                                                  style={{ maxWidth: 370 }}
                                                >
                                                  <ImagenSubida
                                                    showButtons={false}
                                                    imagenObj={{
                                                      url,
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </li>
                                          )}
                                        </Draggable>
                                      );
                                    })
                                  : null}
                                {provided.placeholder}
                              </ul>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    ) : null}
                    {imagenes ? (
                      <button
                        disabled={
                          imagenesListaLoading ||
                          !imagenes ||
                          !(imagenes.length > 1)
                        }
                        onClick={() => handleOrder(!isOrdering)}
                        className="btn btn-primary"
                      >
                        {isOrdering ? (
                          <Loader
                            loaderColor="#ffffff"
                            isLoading={imagenesListaLoading}
                          >
                            Aplicar cambios
                          </Loader>
                        ) : (
                          <Loader
                            loaderColor="#ffffff"
                            isLoading={imagenesListaLoading}
                          >
                            Ordenar imágenes
                          </Loader>
                        )}
                      </button>
                    ) : null}
                    {!isOrdering && elements ? buildImageRows(elements) : null}
                  </div>
                  <div className="content-btncon">
                    {!isOrdering ? (
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/publicar/info/${publicacionID}`);
                        }}
                        className="link-volver"
                      >
                        <img alt="" src="/images/left.png" />
                        Volver
                      </a>
                    ) : null}
                    {imagenes?.length >=
                      config.imgRestrictionsByConcepto.publicacion
                        .minFileTotal && !isOrdering ? (
                      <a
                        href=""
                        onClick={() =>
                          history.push(`/publicar/detalles/${publicacionID}`)
                        }
                        className="btn btn-primary dark"
                      >
                        CONTINUAR
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Loader>
  );
}
