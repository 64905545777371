/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { logger } from "../../utils/utils";
import FileUploadService from "./FileUploadService";
import config from "../../config";
import ImagenSubida from "./ImagenSubida";
import NiceFileInput from "./NiceFileInput";
import ImagenesListaPublicarView from "./ImagenesListaPublicar.view";

const initialErrorMessage = {
  visible: false,
  text: null,
  type: "warning",
};

const loadImage = (file) => {
  logger.debug("loadImage");
  const promise = new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onerror = function (error) {
        reject(error);
      };
      reader.onload = function (event) {
        const image = new Image();
        image.onload = function () {
          resolve(image);
        };
        image.onerror = function (error) {
          reject(error);
        };
        // Esta linea ejecuta image.onload
        image.src = event.target.result;
      };
      // Esta linea ejecuta reader.onload
      reader.readAsDataURL(file);
    } catch (err) {
      logger.debug(err);
      reject(err);
    }
  });
  return promise;
};

// Main component

export default function ImagenesListaPublicar({
  publicacionID,
  imagenes,
  doEliminarImagen,
  imagenesListaLoading,
  handleOnDragEnd,
  handleOrder,
  isOrdering,
  imagenesDnD,
  generalErrorMsg,
}) {

  let history = useHistory();

  const [token, setToken] = useState(() => localStorage.getItem("token"));

  const [elements, setElements] = useState();
  const [viewOrder, setViewOrder] = useState();

  const [isLoading, setIsLoading] = useState();

  const [perspectivaErrorMessage, setPerspectivaErrorMessage] =
    useState(initialErrorMessage);
  const [costadoErrorMessage, setCostadoErrorMessage] =
    useState(initialErrorMessage);
  const [frenteErrorMessage, setFrenteErrorMessage] =
    useState(initialErrorMessage);
  const [atrasErrorMessage, setAtrasErrorMessage] =
    useState(initialErrorMessage);
  const [interiorErrorMessage, setInteriorErrorMessage] =
    useState(initialErrorMessage);
  const [otraErrorMessage, setOtraErrorMessage] = useState(initialErrorMessage);

  useEffect(() => {
    logger.debug("imagenes.useEffect");

    const elements = {
      perspectiva: { imagen: null },
      costado: { imagen: null },
      frente: { imagen: null },
      atras: { imagen: null },
      interior: { imagen: null },
      otra: { imagen: null },
    };

    const ordenOriginal = [
      "perspectiva",
      "costado",
      "frente",
      "atras",
      "interior",
      "otra",
    ];

    let viewOrdenCustom = [...ordenOriginal];

    if (imagenes) {
      logger.debug("imagenes.useEffect.if");

      // [START ordenar imagenes para mostrar]

      let indicesSegunOrdenOriginal = [];

      let tomasEnBD = [];

      imagenes.forEach((imagen) => {
        if (
          [
            "perspectiva",
            "costado",
            "frente",
            "atras",
            "interior",
            "otra",
          ].includes(imagen.tomaTipo)
        ) {
          tomasEnBD.push(imagen.tomaTipo);
          indicesSegunOrdenOriginal.push(
            ordenOriginal.indexOf(imagen.tomaTipo)
          );
        }
      });

      let ordenAUsar = [...indicesSegunOrdenOriginal];

      // ordenar ascendente
      ordenAUsar.sort((a, b) => {
        return a - b;
      });

      let i = 0;

      ordenOriginal.forEach((element, index) => {
        if (indicesSegunOrdenOriginal.includes(index)) {
          viewOrdenCustom[index] = ordenOriginal[indicesSegunOrdenOriginal[i]];
          i = i + 1;
        }
      });



      // [END ordenar imagenes para mostrar]

      let firstElementSwitch = true;

      imagenes.forEach((imagen) => {
        logger.debug("ImagenesListaPublicar.imagenes.useEffect.forEach");
        logger.debug(imagen.tomaTipo);
        if (
          [
            "perspectiva",
            "costado",
            "frente",
            "atras",
            "interior",
            "otra",
          ].includes(imagen.tomaTipo)
        ) {
          elements[imagen.tomaTipo].imagen = imagen;
          if (firstElementSwitch) {
            elements[imagen.tomaTipo].imagen.esLaPrimera = true;
            firstElementSwitch = false;
          }
        }
      });
    }

 

    setViewOrder(viewOrdenCustom);

    setElements(elements);
  }, [imagenes]);

  const redirectARecortar = (tomaTipo, posicionEnVista) => {
    const baseUrl = config.api.baseUrl;
    const key = config.api.key;
    const uploadHeaders = {
      "x-access-token": key,
    };

    const concepto = "publicacion";
    const conceptoId = publicacionID;

    const cropConfigVariant = "crop";

    const cropUploadUrl = `${baseUrl}/agregarImagen/${concepto}/${conceptoId}/?userToken=${token}&esTemporal=false&configVariant=${cropConfigVariant}`;
    const cropConfig =
      config.imgRestrictionsByConcepto[concepto][cropConfigVariant];

    const additionalParams = {
      tomaTipo,
      posicionEnVista,
    };
    const stringyfiedAdditionalParams = additionalParams
      ? "?" + queryString.stringify(additionalParams)
      : "";

    const lastStepUrl = `/publicar/imagenes/${conceptoId}`;
    const relatedImageId = null;

    const params = {
      concepto,
      conceptoId,
      cropConfig: JSON.stringify(cropConfig),
      lastStepUrl,
      rawUploadHeaders: JSON.stringify(uploadHeaders),
      cropUploadUrl,
      saveInConceptUrl: `${baseUrl}/agregarImagenAPublicacionV3/${stringyfiedAdditionalParams}`,
      relatedImageId,
    };

    const paramsQS = queryString.stringify(params);



    history.push(`/cortarImagenes/?${paramsQS}`);
  };

  const handleSetErrorMessage = (tomaTipo, message) => {
    const setters = {
      perspectiva: setPerspectivaErrorMessage,
      costado: setCostadoErrorMessage,
      frente: setFrenteErrorMessage,
      atras: setAtrasErrorMessage,
      interior: setInteriorErrorMessage,
      otra: setOtraErrorMessage,
    };
    setters[tomaTipo](message);
  };

  const upload = async (tomaTipo, posicionEnVista, selectedFile) => {
    try {
      const baseUrl = config.api.baseUrl;
      const key = config.api.key;
      const result = await FileUploadService.upload(
        `${baseUrl}/agregarImagen/publicacion/${publicacionID}/?userToken=${token}&esTemporal=true&configVariant=upload`,
        { "x-access-token": key },
        selectedFile,
        (event) => {
          logger.debug("upload.percentage");
          logger.debug(Math.round((100 * event.loaded) / event.total));
        }
      );

      logger.debug({ result });

      if (result.status === 200) {
        logger.debug("upload.ok");
        setIsLoading(false);
        redirectARecortar(tomaTipo, posicionEnVista);
        return;
      }

      logger.debug("upload status is not 200");
      handleSetErrorMessage(tomaTipo, {
        visible: true,
        text: "Ocurrió un error al subir la imagen",
        type: "warning",
      });
    } catch (err) {
      logger.debug("upload exception");
  
      setIsLoading(false);
      handleSetErrorMessage(tomaTipo, {
        visible: true,
        text: "Ocurrió un error al subir la imagen",
        type: "warning",
      });
    }
  };

  const onFileChangeListener = (event, tomaTipo, posicionEnVista) => {
 

    setIsLoading(true);

    handleSetErrorMessage(tomaTipo, {
      visible: false,
      text: null,
      type: "warning",
    });

    const file = event?.target?.files?.[0];
    if (!file) {
      logger.debug("no file selected");
      setIsLoading(false);
      return;
    }
    const promise = loadImage(file);
    promise.then((image) => {

      const concepto = "publicacion";
      const uploadConfigVariant = "upload";
      const imageRestrictionsByConcepto =
        config.imgRestrictionsByConcepto[concepto][uploadConfigVariant];


      const fileSizeMB = file.size / 1024 / 1024;

      if (fileSizeMB > imageRestrictionsByConcepto.maxFileSize) {
        handleSetErrorMessage(tomaTipo, {
          visible: true,
          text: `La imagen debe tener un tamaño menor a ${imageRestrictionsByConcepto.maxFileSize} MB.`,
          type: "warning",
        });
        setIsLoading(false);
        return;
      } else if (
        image.width < imageRestrictionsByConcepto.minWidth ||
        image.height < imageRestrictionsByConcepto.minHeight
      ) {
        handleSetErrorMessage(tomaTipo, {
          visible: true,
          text: `La imagen debe tener ${imageRestrictionsByConcepto.minWidth} px. de ancho y ${imageRestrictionsByConcepto.minWidth} px. de alto`,
          type: "warning",
        });
        setIsLoading(false);
        return;
      } else {
      
        upload(tomaTipo, posicionEnVista, file);
      }
    });
    promise.catch((error) => {
  
    });
  };

  const buildImageRows = (elements) => {
    if (!elements) {
      return null;
    }

    const elementsKeys = viewOrder;

    let imageRows = [];

    for (let c = 0; c < elementsKeys.length; c = c + 2) {
      let currentKey = elementsKeys[c];
      let nextKey = elementsKeys?.[c + 1];

      let currentElement = elements[currentKey];
      let nextElement = nextKey ? elements?.[nextKey] : null;

      let errorMessagesByToma = {
        perspectiva: perspectivaErrorMessage,
        costado: costadoErrorMessage,
        frente: frenteErrorMessage,
        atras: atrasErrorMessage,
        interior: interiorErrorMessage,
        otra: otraErrorMessage,
      };

      let imagesByToma = {
        perspectiva: "/images/perspectiva-auto.png",
        costado: "/images/costado-auto.png",
        frente: "/images/frente-auto.png",
        atras: "/images/atras-auto.png",
        interior: "/images/interior-auto.png",
        otra: "/images/otra-imagen.png",
      };

      let descriptionByToma = {
        perspectiva: "Perspectiva del vehículo",
        costado: "Costado del vehículo",
        frente: "Frente del vehículo",
        atras: "Atras del vehículo",
        interior: "Interior del vehículo",
        otra: "Otra imagen",
      };



      let leftElement = currentElement?.imagen ? (
        <ImagenSubida
          esPrincipal={currentElement.imagen?.esLaPrimera ? true : false}
          imagenObj={currentElement.imagen}
          doEliminarImagen={doEliminarImagen}
        />
      ) : (
        <NiceFileInput
          errorMessage={errorMessagesByToma[currentKey]}
          onFileChangeListener={onFileChangeListener}
          placeholderIconUrl={imagesByToma[currentKey]}
          tomaTipo={currentKey}
          descripcion={descriptionByToma[currentKey]}
          posicionEnVista={c}
        />
      );

      let rightElement = nextElement?.imagen ? (
        <ImagenSubida
          esPrincipal={nextElement.imagen?.esLaPrimera ? true : false}
          imagenObj={nextElement.imagen}
          doEliminarImagen={doEliminarImagen}
        />
      ) : (
        <NiceFileInput
          errorMessage={errorMessagesByToma[nextKey]}
          onFileChangeListener={onFileChangeListener}
          placeholderIconUrl={imagesByToma[nextKey]}
          tomaTipo={nextKey}
          descripcion={descriptionByToma[nextKey]}
          posicionEnVista={c + 1}
        />
      );

      imageRows.push(
        <div className="content-upload" key={c}>
          {leftElement}
          {rightElement}
        </div>
      );
    }

    return imageRows;
  };

  // Render

  return (
    <ImagenesListaPublicarView
      isLoading={isLoading}
      imagenesListaLoading={imagenesListaLoading}
      publicacionID={publicacionID}
      isOrdering={isOrdering}
      config={config}
      imagenesDnD={imagenesDnD}
      handleOnDragEnd={handleOnDragEnd}
      imagenes={imagenes}
      elements={elements}
      handleOrder={handleOrder}
      buildImageRows={buildImageRows}
      history={history}
      generalErrorMsg={generalErrorMsg}
    />
  );
}
