import React, { useEffect, useState } from "react";
import _ from "lodash";

function ComparativoRender({ history }) {
  return (
    <section className="comparar">
      <div className="container">
        <div className="table-responsive">
          <table className="table-hover">
            <tr>
              <th className="columna-1 un_age">
                <div className="titulo_com">
                  <h2>
                    <strong>Comparar Vehiculos</strong>
                  </h2>
                </div>
              </th>
              <th>
                <a href="" className="text-center" style={{ display: "block" }}>
                  <img src="images/delete1.png" className="imgx" alt="" />
                </a>
                <div className="container-card">
                  <article className="product-item-2">
                    <figure className="img">
                      <a href="producto" className="img-link">
                        <img src="images/thumb.jpg" alt="" />
                      </a>
                      <a href="#" className="fav-container">
                        <span className="fav"></span>
                      </a>
                    </figure>
                    <div className="desc-3">
                      <h4 className="titlee">
                        <span className="details">2010 | 125.478 km</span>
                        <a href="producto">Chevrolet Onix Joy</a>
                      </h4>
                      <p className="price">Gs. 34.0000.000</p>
                    </div>
                    <a href="#" className="btn-2 btn-primary dark">
                      LLAMAR
                    </a>
                    <a
                      href="https://api.whatsapp.com/send?phone=0123456789"
                      className="btn btn-whatsapp"
                    >
                      <img
                        src="images/logo-what.png"
                        alt="icono"
                        className="ico-what-1"
                      />
                      Whatsapp
                    </a>
                  </article>
                </div>
              </th>
              <th>
                <a href="" className="text-center" style={{ display: "block" }}>
                  <img src="images/delete1.png" className="imgx" alt="" />
                </a>
                <div className="container-card">
                  <article className="product-item-2">
                    <figure className="img">
                      <a href="producto" className="img-link">
                        <img src="images/thumb.jpg" alt="" />
                      </a>
                      <a href="#" className="fav-container">
                        <span className="fav"></span>
                      </a>
                    </figure>
                    <div className="desc-3">
                      <h4 className="titlee">
                        <span className="details">2010 | 125.478 km</span>
                        <a href="producto">Chevrolet Onix Joy</a>
                      </h4>
                      <p className="price">Gs. 34.0000.000</p>
                    </div>
                    <a href="#" className="btn-2 btn-primary dark">
                      LLAMAR
                    </a>
                    <a
                      href="https://api.whatsapp.com/send?phone=0123456789"
                      className="btn btn-whatsapp"
                    >
                      <img
                        src="images/logo-what.png"
                        alt="icono"
                        className="ico-what-1"
                      />
                      Whatsapp
                    </a>
                  </article>
                </div>
              </th>
              <th className="un_age">
                <div className="fuploader">
                  <img src="images/add.png" className="img" alt="" />
                </div>
                <h3>Añada un vehículo</h3>
              </th>
            </tr>
            <tr>
              <td className="columna-1 un_age">Caracteristica</td>
              <td className="un">
                <span className="title-td d-lg-none">Caracteristica</span>
                Chevrolet
              </td>
              <td className="un">
                <span className="title-td d-lg-none">Caracteristica</span>
                Kia
              </td>
              <td className="un"></td>
            </tr>
            <tr>
              <td className="columna-1 un_age">Motor</td>
              <td>
                <span className="title-td d-lg-none">Motor</span>
                1.6
              </td>
              <td>
                <span className="title-td d-lg-none">Motor</span>
                1.8
              </td>
              <td className="un"></td>
            </tr>
            <tr>
              <td className="columna-1 un_age">Caja</td>
              <td>
                <span className="title-td d-lg-none">Caja</span>
                automatico
              </td>
              <td>
                <span className="title-td d-lg-none">Caja</span>
                mecanico
              </td>
              <td className="un"></td>
            </tr>
            <tr className="tb-fondo">
              <td className="columna-1 un_age"></td>
              <td>
                <a href="#" className="btn-2 btn-primary dark">
                  LLAMAR
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=0123456789"
                  className="btn btn-whatsapp"
                >
                  <img
                    src="images/logo-what.png"
                    alt="icono"
                    className="ico-what-1"
                  />
                  Whatsapp
                </a>
                <div className="comparar-link">
                  <a href="#" className="link mt-3">
                    Eliminar
                  </a>
                </div>
              </td>
              <td>
                <a href="#" className="btn-2 btn-primary dark">
                  LLAMAR
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=0123456789"
                  className="btn btn-whatsapp"
                >
                  <img
                    src="images/logo-what.png"
                    alt="icono"
                    className="ico-what-1"
                  />
                  Whatsapp
                </a>
                <div className="comparar-link">
                  <a href="#" className="link mt-3">
                    Eliminar
                  </a>
                </div>
              </td>
              <td className="un"></td>
            </tr>
          </table>
        </div>

        <a href="resultados.php" className="add-mobile-car d-lg-none">
          <div className="fuploader">
            <img src="images/add.png" className="img" alt="" />
          </div>
          <h3>Añada un vehículo</h3>
        </a>
      </div>
    </section>
  );
}

export default ComparativoRender;
