import React, { useEffect, useState } from "react";
import Loader from "../../utils/Loader";

function VenderRenderBottom({ history, contenido }) {

  const [activeList, setActiveList] = useState([]);


  function setList(id) {
    const isAlreadyIn = activeList.filter((element) => element == id);
    if (isAlreadyIn.length > 0) {
      const activos = activeList.filter((element) => element != id);
      setActiveList(activos);
    } else {
      setActiveList([...activeList, id]);
    }
  }
  
  return contenido ? (
    <section className="vender-final">
      <div className="container section-1">
        <div className="row">
          <div className="col-lg-12">
            <div className="text1">
              <p className="caption1">{contenido["dudas"][0].titulo}</p>
              <h2>{contenido["dudas"][0].subtitulo}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div id="about-faq">
              {contenido["preguntas"].map((row) => (
                <div className="preguntas-frecuentes">
                  <div className={activeList.includes(row._id) ? "box active" : 'box'}>
                    <h2 className="pre-tit" onClick={()=> setList(row._id)}>{row.titulo}</h2>
                    <div className="respuesta" style={activeList.includes(row._id) ? {display:'block'} : {display:'none'}}>
                      <p className="pre-sub">
                      {row.subtitulo}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : null;
}

export default VenderRenderBottom;
