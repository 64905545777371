import React, { useEffect, useState } from "react";
import Loader from "../../utils/Loader";

function VenderRenderMiddle({ history, contenido }) {


  return (
    <React.Fragment>
      <section className="vender-seccion">
        <div className="container section-2">
          <Loader loaderSize={30} isLoading={contenido ? false : true}>
            {contenido && (
              <>
                <h2>
                  <strong>{contenido["porque vender"][0].titulo}</strong>
                </h2>
                <p className="text1">
                  {contenido["porque vender"][0].subtitulo}
                </p>
              </>
            )}

            {contenido && (
              <div className="row">
                <div className="col-lg-4">
                  <div className="destacados">
                    <figure className="img">
                      <img
                        src="images/image-raiz-1"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                    <div className="desc">
                      <h3 className="categoria">
                        {contenido["categorias inteligentes"][0].titulo}{" "}
                        <strong>
                          {contenido["categorias inteligentes"][0].subtitulo}
                        </strong>
                      </h3>
                      <p className="subtext">
                        {contenido["categorias inteligentes"][0].descripcion}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="destacados">
                    <figure className="img">
                      <img
                        src="images/image-raiz-1"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                    <div className="desc">
                      <h3 className="categoria">
                        {contenido["categorias inteligentes"][1].titulo}{" "}
                        <strong>
                          {contenido["categorias inteligentes"][1].subtitulo}
                        </strong>
                      </h3>
                      <p className="subtext">
                        {contenido["categorias inteligentes"][1].descripcion}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="destacados">
                    <figure className="img">
                      <img
                        src="images/image-raiz-1"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                    <div className="desc">
                      <h3 className="categoria">
                        {contenido["categorias inteligentes"][2].titulo}{" "}
                        <strong>
                          {contenido["categorias inteligentes"][2].subtitulo}
                        </strong>
                      </h3>
                      <p className="subtext">
                        {contenido["categorias inteligentes"][2].descripcion}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Loader>

        </div>
      </section>
      {/* <section id="requisitos-videos" className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <h2>Te mostramos paso a paso como podes vender</h2>
            </div>
            <div className="col-lg-8 text-center">
              <div className="pasoapaso-slider">
			  <Slider {...imgSlider}>
			  <div className="slide">
                  <article className="video-pasoapaso">
                    <figure className="figure">
                      <a href="youtube.com" target="_BLANK">
                        <img
                          src="images/video.jpg"
                          alt=""
                          className="img-responsive"
                        />
                      </a>
                    </figure>
                    <div className="desc">
                      <div className="title">
                        <h3>¿Cómo puedo vender si ya estoy registrado?</h3>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="slide">
                  <article className="video-pasoapaso">
                    <figure className="figure">
                      <a href="youtube.com" target="_BLANK">
                        <img
                          src="images/video.jpg"
                          alt=""
                          className="img-responsive"
                        />
                      </a>
                    </figure>
                    <div className="desc">
                      <div className="title">
                        <h3>¿Cómo puedo vender si ya estoy registrado?</h3>
                      </div>
                    </div>
                  </article>
                </div>
                </Slider>
               
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </React.Fragment>
  );
}

export default VenderRenderMiddle;
