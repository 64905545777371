import React, { useState, useEffect } from "react";

export default function PrecioInput({
  setValue,
  value,
}) {
  const [valor, setValor] = useState("");

  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  useEffect(() => {
    if (value) {
      setValor(addCommas(removeNonNumeric(value)));
    } else {
      setValor("");
    }
  }, [value]);

  const handleChange = (value) => {
    setValor(addCommas(removeNonNumeric(value)));
    setValue(removeNonNumeric(value));
  };

  return (
    <React.Fragment>
      {/* <h3>{title}</h3>
      <div className={inputContainerClassName} id="kilometraje">
        <input
          type="text"
          className={inputClassName}
          placeholder={placeholder}
          value={valor}
          onChange={(evt) => handleChange(evt.target.value)}
          style={inputStyle}
        />
        <p>{unit}</p>
      </div>       */}
      <h2 className="titulo-precio">Precio</h2>
      <div className="content-price">
        <div
          className="drop-down-trigger drop-down-filter"
          id="text-monedas"
        >
          <p>USD</p>
          <input
            type="text"
            name="version"
            value={valor}
            onChange={(evt) => handleChange(evt.target.value)}
            autoComplete="on"
            className="input-text"
            placeholder="Ej. 10.000"
          />
        </div>
      </div>
    </React.Fragment>
  );
}
