import React, { useEffect, useState } from "react";
import _ from "lodash";

import { logger, getLinkWithSize } from "../../utils/utils";

function MarcasDestacadas({ history, searchMarca, contenido }) {

  const images = {
    chevrolet: "/images/chevrolet.png",
    hyundai: "/images/hyundai.png",
    kia: "/images/kia.png",
    vw: "/images/vw.png",
  };

  const getImage = (marca)=>{
    const marcaLowerCase = marca ? marca.toLowerCase() : '';
    if (marcaLowerCase.toLowerCase().includes("chevrolet")) {
      return images['chevrolet']
    } else if (marcaLowerCase.includes("hyundai")) {
      return images['hyundai']
    } else if (marcaLowerCase.includes("kia")) {
      return images['kia']
    } else {
      return images['vw']
    }
  }

  const getImgUrl = (row) => {
    const urlImagenV2 = row?.imagenes?.[0]?.url;  
    const finalImgUrl = urlImagenV2 ? getLinkWithSize(urlImagenV2, 'xsmall') : null;
    return finalImgUrl;
  };

  return (
    <section className="home-brands">
      <div className="container section-start">
        <div className="marcas-destacadas">
          <ul>
            {contenido
              ? contenido["marcas"].map((row, index) => {
                return (<li key={index} className="marca">
                  <a onClick={() => searchMarca(row.titulo)}>
                    <img
                      src={getImgUrl(row)}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                </li>);
              })
              : null}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default MarcasDestacadas;
