import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import Header from "../../utils/Header";
import Footer from "../../utils/Footer";
import HeaderOrganizacion from "../../utils/HeaderOrganizacion";
import FooterOrganizacion from "../../utils/FooterOrganizacion";
import Sugerencias from "./Sugerencias";
import PublicacionDetails from "./PublicacionDetails";
import controller from "../duck";

const imgSlider = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  infinte: false,
  centerMode: false,
  adaptiveHeight: true,
  asNavFor: ".img-thumbs-slider",
};
const imgThumbs = {
  slidesToShow: 5,
  slidesToScroll: 1,
  infinte: true,
  asNavFor: ".img-slider",
  arrows: false,
  dots: false,
  adaptiveHeight: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};
function Publicacion({outsideDomain=false, colorPrincipal=null, colorSecundario=null, instagram=null}) {
  let history = useHistory();
  const dispatch = useDispatch();
  const modelPublicacion = useSelector((state) => state.publicacionDetail);
  const modelCotizacion = useSelector((state) => state.getCotizacionActiva);
  const [cotizacion, setCotizacion] = useState(0);
  const [publicacion, setPublicacion] = useState(null);
  const [title, setTitle] = useState('VAUN');
  const { id, ssi } = useParams();

  useEffect(() => {
    const data = modelCotizacion?.data?.data;
    const error = modelCotizacion?.error?.message;

    if (error) {
      alert(error);
      dispatch(controller.duckCotizacionActiva.actions.clear())
    }
    if (data) {
      setCotizacion(data.data.valor);
      dispatch(controller.getPublicacionCompletaThunk(id, ssi));
      dispatch(controller.duckCotizacionActiva.actions.clear());
    }
  }, [modelCotizacion]);

  useEffect(() => {
    const data = modelPublicacion?.data?.data;
    const error = modelPublicacion?.error?.message;

    if (error) {
      alert(error);
      dispatch(controller.duck.actions.clear());
    }
    if (data) {
      if (!data.data) {
        dispatch(controller.duck.actions.clear());
        alert("Publicacion no encontrada");
        history.goBack();
        return;
      }
      let titleConstructor = `${data.data.attrs.marca} | ${data.data.attrs.modelo} | ${data.data.attrs.anho} | ${data.data.organizacion} | ${data.data._id} | VAUN`;
      setTitle(titleConstructor)

      const analytics = getAnalytics();

      logEvent(analytics, `${data.data.organizacion}`, {
        marca: data.data.attrs.marca,
        modelo: data.data.attrs.marca + " " + data.data.attrs.modelo,
        anho: data.data.attrs.anho,
      });
  
      setPublicacion(data.data);
      dispatch(controller.duck.actions.clear());
    }
  }, [modelPublicacion]);

  useEffect(() => {
    dispatch(controller.getCotizacionActivaThunk());
    dispatch(controller.addVisitaThunk(id));
    const analytics = getAnalytics();
    logEvent(analytics, "funnel", {
      action: "detalle",
    });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta property="og:url" content={window.location.href}/>
        <meta property="og:title" content={title}/>
      </Helmet>
      {!outsideDomain ? <Header history={history} /> : <HeaderOrganizacion history={history} organizacion={{avatar:publicacion?.avatar, name:publicacion?.organizacion}} colorPrincipal={colorPrincipal} colorSecundario={colorSecundario}/>}
      <PublicacionDetails
        history={history}
        publicacion={publicacion}
        loading={modelPublicacion.loading}
        cotizacion={cotizacion}
        outsideDomain={outsideDomain}
      />
      {/* <Sugerencias history={history} /> */}
      {!outsideDomain ? <Footer history={history} /> : <FooterOrganizacion history={history} instagram={instagram}/>}
    </React.Fragment>
  );
}

export default Publicacion;
