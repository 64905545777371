import React, { useEffect, useState } from "react";
import _ from "lodash";
import PopularesItem from "./PopularesItem";
import Loader from "../../utils/Loader";

function Populares({ history, searchCategoria, contenido }) {
  return (
    <section className="home-modelos">
      <div className="container section-end">
        <h2>Modelos más populares</h2>
        <Loader loaderSize={30} isLoading={contenido ? false : true}>
          {contenido && (
            <div className="row">
              {contenido["populares"].map((row, index) => (
                <PopularesItem
                  key={index}
                  searchCategoria={searchCategoria}
                  item={row}
                />
              ))}
            </div>
          )}
        </Loader>
      </div>
    </section>
  );
}

export default Populares;
