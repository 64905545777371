import React, { useEffect, useState } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import controller from "./duck";
import LoginRender from "./LoginRender";
import Footer from "../../utils/Footer";
import { logger, parseJwt, validateEmail } from "../../utils/utils";

const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || "vaun.com.py";

function Login() {
  let history = useHistory();
  const dispatch = useDispatch();
  const modelStandard = useSelector((state) => state.loginStandard);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [submitErrorMsg, setSubmitErrorMsg] = useState(null);
  const [gralErrorMsg, setGralErrorMsg] = useState(null);

  const modelRegistro = useSelector((state) => state.registro);

  const getNameFromFbResponse = (response) => {
    if (response.name) {
      return response.name;
    } else {
      let spaceAndLastName = "";

      if (response.last_name && response.last_name !== "") {
        spaceAndLastName = " " + response.last_name;
        return response.first_name + spaceAndLastName;
      }

      return null;
    }
  };

  const responseFacebook = (response) => {
    logger.debug({ "responseFacebook.response": response });

    const fullName = getNameFromFbResponse(response);

    const data = {
      socialAccountID: response.id,
      site: "facebook",
      fullName: fullName,
      firstName: response.first_name,
      lastName: response.last_name,
      organizacionID: null,
      // invitacionToken: match?.params?.token ? match.params.token : null,
    };

    // Para las redes sociales,
    // registro intenta login
    // si no funciona, intenta registro y login
    // (devuelve token)
    dispatch(controller.registroThunk(data));
  };

  const responseGoogle = (response) => {
    try {
      let data = {
        socialAccountID: response.profileObj.googleId,
        site: "google",
        fullName: response.profileObj.name,
        organizacionID: null,
        // invitacionToken: match?.params?.token ? match.params.token : null,
      };
      logger.debug({ "responseGoogle.data": data });
      dispatch(controller.registroThunk(data));
    } catch (error) {
      logger.error("responseGoogle.error");
      logger.error(error);
      setGralErrorMsg(
        "Ocurrió un error al realizar la operación. Por favor, intentá nuevamente."
      );
    }
  };

  const manageError = (errorMessage) => {
    document.cookie =
      "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=" +
      COOKIE_DOMAIN;
    localStorage.removeItem("token");

    setSubmitErrorMsg(
      `Ocurrió un error: ${errorMessage}. Por favor, intentá nuevamente.`
    );
    dispatch(controller.duck.actions.clear());
  };

  useEffect(() => {
    const data = modelStandard?.data?.data;
    const error = modelStandard?.error;
    const errorMessage = modelStandard?.error?.response?.data?.message;
    if (error) {
      let errorToShow;
      if (errorMessage) {
        errorToShow = errorMessage;
      } else {
        errorToShow = "Ocurrió un error al realizar la operación";
      }
      manageError(errorToShow);
      return;
    }
    if (data) {
      if (!data?.data?.token) {
        manageError("Ocurrió un error al iniciar sesión.");
        return;
      }
      logger.debug("login.ok");
      setAuthDataAndRedirect(
        data.data.token,
        () => {
          dispatch(controller.duck.actions.clear());
        },
        { desde: "loginStandardUseEffect" }
      );
    }
  }, [modelStandard]);

  useEffect(() => {
    const data = modelRegistro?.data?.data;
    const error = modelRegistro?.error;
    const errorMessage = modelRegistro?.error?.response?.data?.message;

    if (error) {
      let errorToShow;

      if (errorMessage) {
        errorToShow = errorMessage;
      }
      setGralErrorMsg(
        `Ocurrió un error: ${errorToShow}. Por favor, intentá nuevamente.`
      );
      dispatch(controller.duckRegistro.actions.clear());
    }
    if (data) {
      logger.debug("modelRegistro.data", data);
      
      const analytics = getAnalytics();
      logEvent(analytics, "estadisticas", {
        action: "usuariosNuevos",
      });
      setAuthDataAndRedirect(
        data.data.token,
        () => {
          dispatch(controller.duckRegistro.actions.clear());
        },
        { desde: "registroUseEffect" }
      );
    }
  }, [modelRegistro]);

  const setAuthDataAndRedirect = (token, callBeforeRedirect, debugInfo) => {
    logger.debug("setAuthDataAndRedirect.debugInfo", debugInfo);

    localStorage.setItem("token", token);
    document.cookie = "token=" + token + ";domain=" + COOKIE_DOMAIN + ";path=/";

    const decodedToken = parseJwt(token);

    let url = null;

    if (decodedToken.rol === "superadmin") {
      url = process.env.REACT_APP_ADMIN_URL
        ? process.env.REACT_APP_ADMIN_URL
        : "https://hamilton.vaun.com.py";
    } else {
      const redirectTo = localStorage.getItem("redirectTo");

      url = redirectTo !== null ? redirectTo : "/publicaciones";
      logger.debug({ "setAuthDataAndRedirect.redirectTo": url });
      localStorage.removeItem("redirectTo");
    }

    callBeforeRedirect && callBeforeRedirect();

    window.location.assign(url);
  };

  function login() {
    if (!validateEmail(username)) {
      setSubmitErrorMsg(
        "Por favor, proporcioná un email con el formato correcto"
      );
      return;
    }

    let data = {
      username,
      password,
      type: "standard",
    };
    dispatch(controller.loginStandardThunk(data));
  }

  return (
    <React.Fragment>
      <LoginRender
        history={history}
        setPassword={setPassword}
        setUsername={setUsername}
        login={login}
        isLoading={modelStandard.loading || modelRegistro.loading}
        responseFacebook={responseFacebook}
        responseGoogle={responseGoogle}
        gralErrorMsg={gralErrorMsg}
        submitErrorMsg={submitErrorMsg}
        setGralErrorMsg={setGralErrorMsg}
      />
      <Footer />
    </React.Fragment>
  );
}

export default Login;
