/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { parseJwt } from "./utils";
import Perfil from "../publicaciones/results/Perfil";

function HeaderOrganizacion({ history, organizacion, colorPrincipal, colorSecundario }) {

  const [addShowClassName, setAddShowClassName] = useState(false);
  const [token, setToken] = useState(() => {
    const rawToken = localStorage.getItem("token");
    if (!rawToken) {
      return null;
    }
    return {
      raw: rawToken,
      decoded: parseJwt(rawToken),
    };
  });
  const [menuIsActive, setMenuIsActive] = useState(false);

  return (
    <header id="header" style={{backgroundColor:colorPrincipal}}>
      <div className="container" style={{ paddingBottom: 15, paddingTop: 15 }}>
        <div className="row align-items-center">
          <div className="col-lg-4">
            {organizacion?.avatar ? (
              <img
                src={organizacion?.avatar}
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/");
                }}
                style={{marginLeft: 20,width:50, height:50}}
                // className="logo"
              ></img>
            ) : (
              <a
                onClick={() => {
                  history.push("/");
                }}
                style={{
                  cursor:"pointer",
                  marginLeft: 20,
                  fontSize: 15,
                  fontWeight: 700,
                  color: colorSecundario,
                }}
              >
                {organizacion?.name}
              </a>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderOrganizacion;
