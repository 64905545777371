import React, { useState, useEffect } from "react";
import { parseJwt, sleep } from "../utils/utils";
import Loader from "./Loader";
import { useLocation } from "react-router-dom";

// NOTA: si algún día se necesita usar
// useHistory.push en vez de window.location.replace
// en el return
// token ? <Children/> : <Loader/>;
// para que no monte los componentes hijos.
function WithAuth({ children }) {
  const location = useLocation();

  const [token, setToken] = useState(() => {


    const rawTokenFromCookie = document.cookie
      ? document.cookie.replace(
          /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        )
      : null;

    if (!rawTokenFromCookie) {
   
      localStorage.setItem("redirectTo", window.location.href);
      window.location.replace("/logout");
      return null;
    }

    let decodedToken = parseJwt(rawTokenFromCookie);

    localStorage.setItem("token", rawTokenFromCookie);

    const result = {
      raw: rawTokenFromCookie,
      decoded: decodedToken,
    };

    return result;
  });

  // didMount
  useEffect(() => {
    const rawToken = localStorage.getItem("token");
 

    let decodedToken = parseJwt(rawToken);
  
    localStorage.setItem("token", rawToken);

    const result = {
      raw: rawToken,
      decoded: decodedToken,
    };

    setToken(result);
  }, []);

  useEffect(() => {
    if (!token) {
    
      window.location.replace("/logout");
      return;
    }
  }, [token]);

  const Children = children;

  return token ? <Children token={token} /> : <Loader>{null}</Loader>;
}

export default WithAuth;
