/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import Loader from "../utils/Loader";
import Header from "../utils/Header";
import Footer from "../utils/Footer";
import publicacionController from "../publicaciones/duck";
import authController from "../publicaciones/auth/duck";
import { logger, thousandSeparator } from "../utils/utils";
import { rolDescriptionByRol } from "../utils/utils";
import Alert from "../utils/Alert";
// import { Modal, Button } from "react-bootstrap";
import Phone from "../utils/Phone";
import Direccion from "../utils/Direccion";
import EditarPerfilTelefono from "./EditarPerfilTelefono";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function DatosVendedorOrganizacion({
  imagenUrl,
  nombre,
  direccion,
  telefono,
  email,
  lat,
  lng,
}) {
  return (
    <aside className="logo-vendedor mi-cuenta">
      <div className="ficha-vendedor-res">
        <div className="ficha-title clearfix">
          <a
            href=""
            className="info-vendedor"
            onClick={(e) => e.preventDefault()}
          >
            <figure className="avatar">
              <img
                src={imagenUrl ? imagenUrl : "/images/user.jpg"}
                alt="Vendedor"
              />
            </figure>
          </a>
          <h4 className="name-com">{nombre}</h4>
          <div className="ubicacion">
            <p className="ciudad-res">{direccion}</p>
          </div>
          {lat && lng && (
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
              target="_blank"
              className="link"
              rel="noreferrer"
            >
              Ver Ubicación
            </a>
          )}
        </div>
        {/* <div className="acciones">
      <a href="editar-perfil.php" className="btn btn-contactar">
        Editar Perfil
        <img
          src="/images/pencil.png"
          alt="icono"
          className="ico-pen"
        />
      </a>
    </div> */}
      </div>
      <div className="datos-res">
        {telefono && (
          <div className="titulodato">
            <p>Teléfono</p>
            <h3>{telefono}</h3>
          </div>
        )}
        {email && (
          <div className="titulodato">
            <p>Email</p>
            <h3>{email}</h3>
          </div>
        )}
      </div>
    </aside>
  );
}

export default function EditarPerfil({ token }) {
  let history = useHistory();
  const dispatch = useDispatch();

  const modelOrganizacion = useSelector((state) => state.organizacionById);
  const modelUsuario = useSelector((state) => state.getUsuarioByCredencial);
  const modelUpdateUsuario = useSelector((state) => state.updateUsuario);
  const modelUpdatePassByOldPass = useSelector(
    (state) => state.updatePassByOldPass
  );

  const [modalErrorMsg, setModalErrorMsg] = useState("");
  const [usuario, setUsuario] = useState(null);
  const [organizacion, setOrganizacion] = useState(null);
  // const [token, setToken] = useState(() => {
  //   const rawToken = localStorage.getItem("token");
  //   logger.debug({rawToken});
  //   if (rawToken) {
  //     const result = {
  //       raw: rawToken,
  //       decoded: parseJwt(rawToken),
  //     };
  //     return result;
  //   }
  //   return null;
  // });
  const [generalMsg, setGeneralMsg] = useState({
    text: "",
    type: "primary",
  });
  const [dataSubmitMsg, setDataSubmitMsg] = useState({
    text: "",
    type: "primary",
  });
  const [passwordSubmitMsg, setPasswordSubmitMsg] = useState({
    text: "",
    type: "primary",
  });
  const [loading, setLoading] = useState(true);
  const [sidebarDatos, setSidebarDatos] = useState(null);
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [direccion, setDireccion] = useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [usarTelOrg, setUsarTelOrg] = useState(true);

  useEffect(() => {
    logger.debug({ token });
    if (token.decoded.organizacionID) {
      dispatch(
        publicacionController.organizacionByIdThunk(
          token.decoded.organizacionID
        )
      );
    }
    dispatch(authController.getUsuarioByCredencialThunk(token.decoded.id));
  }, [dispatch, token]);

  useEffect(() => {
    const data = modelOrganizacion?.data?.data?.data;
    const error = modelOrganizacion?.error;

    if (error) {
      const errorMessage =
        modelOrganizacion?.error?.response?.data?.message ||
        "Ocurrió un error al consultar datos de organización";
      setGeneralMsg({ text: errorMessage, type: "danger" });
      dispatch(publicacionController.duckOrganizacion.actions.clear());
    }
    if (data) {
 
      setOrganizacion(data);
      dispatch(publicacionController.duckOrganizacion.actions.clear());
    }
  }, [modelOrganizacion]);

  useEffect(() => {
    const data = modelUsuario?.data?.data?.data;
    const error = modelUsuario?.error;

    if (error) {
      const errorMessage =
        modelUsuario?.error?.response?.data?.message ||
        "Ocurrió un error al consultar datos de usuario. Por favor, intente nuevamente recargando la página";
      setGeneralMsg({ text: errorMessage, type: "danger" });
      dispatch(authController.duckGetUsuarioByCredencial.actions.clear());
    }

    if (data) {
      logger.debug("modelUsuario.useEffect.ifData");
      logger.debug(data);
      setUsuario(data);
      setNombre(data.nombre);
      setTelefono(
        !data?.phoneNumber || data.phoneNumber.length < 13
          ? "+595"
          : data.phoneNumber
      );
      setDireccion(data.address?.direccion);
      setLat(data.address?.lat ? data.address.lat : null);
      setLng(data.address?.lng ? data.address.lng : null);
      dispatch(authController.duckGetUsuarioByCredencial.actions.clear());
    }
  }, [modelUsuario]);

  useEffect(() => {
    const data = modelUpdateUsuario?.data?.data;
    const error = modelUpdateUsuario?.error;

    if (error) {
      const errorMessage =
        modelUpdateUsuario?.error?.response?.data?.message ||
        "Ocurrió un error al actualizar datos de usuario. Por favor, intente nuevamente.";
      setDataSubmitMsg({ text: errorMessage, type: "danger" });
      dispatch(authController.duckUpdateUsuario.actions.clear());
    }
    if (data) {
      setDataSubmitMsg({
        text: "Datos actualizados correctamente",
        type: "primary",
      });
      dispatch(authController.duckUpdateUsuario.actions.clear());
      dispatch(authController.getUsuarioByCredencialThunk(token.decoded.id));
      logger.debug("update user finished");
    }
  }, [modelUpdateUsuario]);

  useEffect(() => {
    const data = modelUpdatePassByOldPass?.data?.data;
    const error = modelUpdatePassByOldPass?.error;

    if (error) {
      const errorMessage =
        modelUpdatePassByOldPass?.error?.response?.data?.message ||
        "Ocurrió un error al actualizar contraseña. Por favor, intente nuevamente.";
      setPasswordSubmitMsg({ text: errorMessage, type: "danger" });
      dispatch(authController.duckUpdatePassByOldPass.actions.clear());
    }
    if (data) {
      dispatch(authController.duckUpdatePassByOldPass.actions.clear());
      logger.debug("update credential finished");
      setPasswordSubmitMsg({
        text: "Contraseña actualizada correctamente",
        type: "primary",
      });
    }
  }, [modelUpdatePassByOldPass]);

  useEffect(() => {
    if (token?.decoded?.organizacionID && organizacion) {
      setSidebarDatos({
        imagenUrl: organizacion.imagenes?.[0]?.url,
        nombre: organizacion.name,
        direccion: organizacion.address?.direccion,
        lat: organizacion.address?.lat,
        lng: organizacion.address?.lng,
        telefono: organizacion.phoneNumber,
        email: organizacion.email,
      });
    } else if (usuario) {
      setSidebarDatos({
        imagenUrl: null,
        nombre: usuario.nombre,
        direccion: usuario.address?.direccion,
        lat: usuario.address?.lat,
        lng: usuario.address?.lng,
        telefono: usuario.phoneNumber,
        email: usuario.email,
      });
    }
  }, [usuario, organizacion, token]);

  const actualizarUsuario = () => {
    setDataSubmitMsg({
      text: "",
    });
    setPasswordSubmitMsg({
      text: "",
    });

    if (!token.decoded.id) {
      setDataSubmitMsg({
        text: "No se pudo procesar la solicitud",
        type: "danger",
      });
      return;
    }

    let requerirTelefono = false;

    if (token.decoded.organizacionID) {
      if (!usarTelOrg && (telefono === "" || telefono.length < 13)) {
        requerirTelefono = true;
      }
    } else {
      if (telefono === "" || telefono.length < 13) {
        requerirTelefono = true;
      }
    }

    if (requerirTelefono) {
      setDataSubmitMsg({
        text: "El teléfono debe tener el formato +595xxxabcdef. Ejemplo: +595961222333",
        type: "danger",
      });
      return;
    }

    if (!direccion || !nombre) {
      setDataSubmitMsg({
        text: "Por favor, completá todos los campos",
        type: "danger",
      });
      return;
    }

    const params = {
      credentialID: token.decoded.id,
      nombre,
      phoneNumber: telefono,
      address: {
        direccion,
        lat,
        lng,
      },
    };

    dispatch(authController.updateUsuarioThunk(params));
  };

  const actualizarContrasena = () => {
    setDataSubmitMsg({
      text: "",
    });
    setPasswordSubmitMsg({
      text: "",
    });

    if (!oldPassword || !newPassword) {
      setPasswordSubmitMsg({
        text: "Por favor, completá la contraseña anterior y la contraseña nueva",
        type: "danger",
      });
      return;
    }

    const params = {
      userToken: token.raw,
      oldPassword,
      newPassword,
    };

    dispatch(authController.updatePassByOldPassThunk(params));
  };

  let usuarioPerteneceAStr = "";

  if (token?.decoded?.organizacionID && !modelOrganizacion.loading) {
    usuarioPerteneceAStr = `de ${organizacion?.name}`;
  } else if (!token?.decoded?.organizacionID) {
    usuarioPerteneceAStr = "independiente";
  }

  return (
    <React.Fragment>
      <Header history={history} />
      <section className="resultados-busqueda">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              {sidebarDatos && (
                <DatosVendedorOrganizacion
                  imagenUrl={sidebarDatos.imagenUrl}
                  nombre={sidebarDatos.nombre}
                  direccion={sidebarDatos.direccion}
                  lat={sidebarDatos.lat}
                  lng={sidebarDatos.lng}
                  telefono={sidebarDatos.telefono}
                  email={sidebarDatos.email}
                />
              )}
            </div>
            <div className="col-lg-9">
              <section id="editar-datos" className="container-logeado">
                <div className="container-editor sin-fondo">
                  <h1>Editar Perfil</h1>
                  <h3 style={{ color: "#818181" }}>
                    {token.decoded.rol &&
                      rolDescriptionByRol[token.decoded.rol]}{" "}
                    {usuarioPerteneceAStr}
                  </h3>
                  {generalMsg?.text && (
                    <Alert
                      type={generalMsg.type || "primary"}
                      text={generalMsg.text}
                      visible={true}
                      style={{ textAlign: "left" }}
                    />
                  )}
                  {/* <div className="row">
                    <div className="col-lg-2">
                      <figure className="avatar1">
                        <img src="/images/avatar.png" alt="Vendedor" />
                      </figure>
                    </div>
                    <div className="col-lg-3">
                      <a href="#" className="btn btn-primary dark btn-cambiar">
                        Cambiar Foto
                      </a>
                    </div>
                    <div className="eliminar col-lg-3">
                      <a href="#" className="link">
                        Eliminar Foto
                      </a>
                    </div>
                  </div> */}
                </div>

                <div className="container-editor">
                  <h3>Datos Personales</h3>
                  <Loader
                    isLoading={modelUsuario.loading}
                    loaderSize={30}
                    // loaderColor="#fff"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <form>
                      <div className="form-row">
                        <div className="form-group col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Nombre"
                            value={nombre}
                            onChange={(evt) => setNombre(evt.target.value)}
                          />
                        </div>
                        <EditarPerfilTelefono
                          esParteDeOrg={Boolean(token?.decoded?.organizacionID)}
                          title={""}
                          inputContainerClassName={"form-group col-sm-6"}
                          setTelefono={setTelefono}
                          telefono={telefono}
                          usarTelOrg={usarTelOrg}
                          setUsarTelOrg={setUsarTelOrg}
                          telOrg={organizacion?.phoneNumber}
                        />
                        <div className="form-group col-md-6">
                          {/* <label>Número de teléfono</label> */}
                          {/* <Phone
                            value={telefono}
                            setValue={setTelefono}
                            international={false}
                            containerStyle={{}}
                          /> */}
                          {/* <input
                          type="tetelxt"
                          className="form-control"
                          placeholder="Teléfono"
                          value={telefono}
                          onChange={(evt) => setTelefono(evt.target.value)}
                        /> */}
                        </div>
                        {/* <div className="form-group col-md-6">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={(evt) => setEmail(evt.target.value)}
                        />
                      </div> */}

                        <div className="form-group col-md-12">
                          {token?.decoded?.organizacionID && (
                            <div>
                              <span>
                                <img
                                  alt="icon"
                                  src="images/ico-pass.png"
                                  style={{ width: "12px" }}
                                />{" "}
                                Usando dirección de organización
                              </span>
                            </div>
                          )}
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Dirección"
                            value={
                              organizacion?.address?.direccion
                                ? organizacion.address.direccion
                                : direccion
                            }
                            onChange={(evt) => setDireccion(evt.target.value)}
                            disabled={
                              token?.decoded?.organizacionID ? true : false
                            }
                          />
                          {!token?.decoded?.organizacionID && (
                            <Direccion
                              direccion={direccion}
                              setDireccion={setDireccion}
                              setLat={setLat}
                              setLng={setLng}
                            />
                          )}
                        </div>
                      </div>
                      {dataSubmitMsg?.text && (
                        <Alert
                          type={dataSubmitMsg.type || "primary"}
                          text={dataSubmitMsg.text}
                          visible={true}
                          style={{ textAlign: "left" }}
                        />
                      )}
                      <button
                        type="button"
                        className="btn btn-primary dark btn-guardar"
                        onClick={() => actualizarUsuario()}
                      >
                        <Loader
                          isLoading={modelUpdateUsuario.loading}
                          loaderSize={19}
                          loaderColor="#fff"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          Guardar cambios
                        </Loader>
                      </button>
                    </form>
                  </Loader>
                </div>
                <div className="container-editor">
                  <h3>Datos de Cuenta</h3>
                  <form>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input
                          value={oldPassword}
                          onChange={(evt) => setOldPassword(evt.target.value)}
                          type="password"
                          className="form-control"
                          placeholder="Contraseña Anterior"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          value={newPassword}
                          onChange={(evt) => setNewPassword(evt.target.value)}
                          type="password"
                          className="form-control"
                          placeholder="Contraseña Nueva"
                        />
                      </div>
                    </div>
                    {passwordSubmitMsg?.text && (
                      <Alert
                        type={passwordSubmitMsg.type || "primary"}
                        text={passwordSubmitMsg.text}
                        visible={true}
                        style={{ textAlign: "left" }}
                      />
                    )}
                    <button
                      type="button"
                      className="btn btn-primary dark btn-guardar"
                      onClick={actualizarContrasena}
                    >
                      <Loader
                        isLoading={modelUpdatePassByOldPass.loading}
                        loaderSize={19}
                        loaderColor="#fff"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Guardar contraseña
                      </Loader>
                    </button>
                  </form>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <Footer history={history} />
    </React.Fragment>
  );
}
