import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Helmet } from "react-helmet";
import GoogleMapReact from "google-map-react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { getAnalytics, logEvent } from "firebase/analytics";
import Header from "../../utils/Header";
import BannerWithSearchTaller from "./BannerWithSearchTaller";
import Footer from "../../utils/Footer";
import controller from "../duck";

const marker = require("../../utils/img/marker.png").default;
const markerInactivo = require("../../utils/img/marker-inactivo.png").default;

const Marker = ({ setShow, setMarkerSelected, row }) => (
  <div
    onClick={() => {
      setMarkerSelected(row);
      setShow(true);
    }}
  >
    <img
      src={row.incluido ? marker : markerInactivo}
      style={{
        width: 30,
        height: 30,
      }}
    />
  </div>
);

function TalleresMap() {
  let history = useHistory();
  const dispatch = useDispatch();
  const modelSeguros = useSelector((state) => state.segurosForSelect);
  const modelTalleres = useSelector((state) => state.talleresBySeguro);
  const [seguro, setSeguro] = useState(null);
  const [segurosOptions, setSegurosOptions] = useState([
    { value: "all", label: "Todos los seguros" },
  ]);
  const [markers, setMarkers] = useState([]);
  const [show, setShow] = useState(false);
  const [markerSelected, setMarkerSelected] = useState(null);
  const [center, setCenter] = useState(null);

  let zoom = 15;

  const getLocation = () => {
    if (!navigator.geolocation) {
      setCenter({
        lat: -25.295196,
        lng: -57.63172,
      });
     // console.log("Geolocation is not supported by your browser");
    } else {
     // console.log("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
        //  console.log(position.coords.latitude, position.coords.longitude);
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          setCenter({
            lat: -25.295196,
            lng: -57.63172,
          });
         // console.log("Unable to retrieve your location");
        }
      );
    }
  };

  useEffect(() => {
    const data = modelTalleres?.data?.data;
    const error = modelTalleres?.error?.message;

    if (error) {
      alert(error);
      dispatch(controller.duckGetTalleresBySeguro.actions.clear());
    }
    if (data) {
      setMarkers(data.data);
      setMarkerSelected(null);
      dispatch(controller.duckGetTalleresBySeguro.actions.clear());
    }
  }, [modelTalleres]);

  useEffect(() => {
    const data = modelSeguros?.data?.data;
    const error = modelSeguros?.error?.message;

    if (error) {
      alert(error);
      dispatch(controller.duckGetSegurosForSelect.actions.clear());
    }
    if (data) {
      setSegurosOptions([...segurosOptions, ...data.data]);
      dispatch(controller.duckGetSegurosForSelect.actions.clear());
    }
  }, [modelSeguros]);

  useEffect(() => {
    dispatch(controller.getSegurosForSelectThunk());
    dispatch(
      controller.getTalleresBySeguroThunk({
        seguro: "all",
      })
    );
    getLocation();
  }, []);

  function searchSeguro(value) {
    setSeguro(value);
    dispatch(
      controller.getTalleresBySeguroThunk({
        seguro: value.value,
      })
    );
  }

 
  return (
    <React.Fragment>
      <Helmet>
        <title>Talleres | VAUN</title>
        <meta name="title" content={"Talleres | VAUN"} />
        <meta property="og:url" content={window.location.href}/>
        <meta property="og:title" content={"Talleres | VAUN"}/>
      </Helmet>
      <Header history={history} />

      <div className="bg-degradado-gris">
        <div style={{ height: "90vh", width: "100%" }}>
          <GoogleMapReact
            onChange={(region) => {
              setCenter(region.center);
            }}
            bootstrapURLKeys={{
              key: "AIzaSyBQtRSpoG3szG4wZUHpnAiAquyMFUUH3WY",
            }}
            center={center}
            defaultCenter={center}
            defaultZoom={zoom}
            options={{
              disableDefaultUI: true,
            }}
          >
            {markers.map((row) => (
              <Marker
                key={row._id}
                row={row}
                setMarkerSelected={setMarkerSelected}
                setShow={setShow}
                lat={row.address.lat}
                lng={row.address.lng}
              />
            ))}
            {markerSelected && (
              <Modal
                className="modalAlert"
                show={show}
                onHide={() => {
                  setShow(false);
                }}
              >
                <Modal.Header closeButton={false}>
                  <Modal.Title>{markerSelected.nombre}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Telefono: {markerSelected.telefono}</p>
                  <p>
                    Web:{" "}
                    <a
                      href={markerSelected.link}
                      target="_BLANK"
                      rel="noreferrer"
                    >
                      {markerSelected.link}
                    </a>
                  </p>
                  <p>Direccion: {markerSelected.address.direccion}</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    bsstyle="primary"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    Cerrar
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </GoogleMapReact>
        </div>
      </div>
      <BannerWithSearchTaller
        seguro={seguro}
        segurosOptions={segurosOptions}
        searchSeguro={searchSeguro}
        loading={modelSeguros.loading}
      />
      <Footer history={history} />
    </React.Fragment>
  );
}

export default TalleresMap;
