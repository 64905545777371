import React, { useState, useEffect } from "react";

import Phone from "../utils/Phone";

export default function EditarPerfilTelefono({
  title,
  inputContainerClassName,
  setTelefono,
  telefono,
  esParteDeOrg,
  usarTelOrg,
  setUsarTelOrg,
  telOrg,
}) {
  // useEffect(() => {
  //   if (!mostrarInput) {
  //     setTelefono("+595");
  //   }
  // }, [mostrarInput, setTelefono]);

  return (
    <div className={inputContainerClassName}>
      {title && <h3>{title}</h3>}

      {esParteDeOrg && (
        <div
          className="editarPerfilTelefono multiple-filter"
          style={{ marginBottom: "-1px" }}
        >
          <div className="filter-options">
            <ul className="list">
              <li
                onClick={() => {
                  setUsarTelOrg(!usarTelOrg);
                }}
                className={usarTelOrg ? "active" : ""}
              >
                Usar teléfono de organización
              </li>
            </ul>
          </div>
        </div>
      )}

      <Phone
        value={!esParteDeOrg ? telefono : usarTelOrg ? telOrg : telefono}
        setValue={setTelefono}
        international={false}
        containerStyle={{}}
        disabled={esParteDeOrg && usarTelOrg}
      />
    </div>
  );
}
