import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import controller from "./duck";
import ChangePassByTokenRender from "./ChangePassByTokenRender";
import Footer from "../../utils/Footer";
import { logger, parseJwt, validateEmail } from "../../utils/utils";

const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || "vaun.com.py";

function Confirmation() {
  let history = useHistory();
  const dispatch = useDispatch();
  const modelChangePass = useSelector((state) => state.changePassByToken);
  const modelCheckToken = useSelector((state) => state.checkResetToken);
  const [password, setPassword] = useState(null);
  const [password2, setPassword2] = useState(null);
  const [submitErrorMsg, setSubmitErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const { resetToken } = useParams();

  useEffect(() => {
    const data = modelChangePass?.data?.data;
    const error = modelChangePass?.error?.response?.data?.message;

    if (error) {
      setSubmitErrorMsg(error);
      dispatch(controller.duckChangePassByToken.actions.clear());
    }
    if (data) {
      history.push("/login");
      dispatch(controller.duckChangePassByToken.actions.clear());
    }
  }, [modelChangePass]);

  useEffect(() => {
    const data = modelCheckToken?.data?.data;
    const error = modelCheckToken?.error?.response?.data?.message;

    if (error) {
      setSubmitErrorMsg(error);
      dispatch(controller.duckCheckResetToken.actions.clear());
    }
    if (data) {
      dispatch(controller.duckCheckResetToken.actions.clear());
    }
  }, [modelCheckToken]);

  useEffect(() => {
    dispatch(controller.checkResetTokenThunk(resetToken));
  }, []);

  function submit() {
    if (!password || !password2) {
      return setSubmitErrorMsg("Por favor complete todos los campos");
    }
    if (password !== password2) {
      return setSubmitErrorMsg("Las contraseeñas no coinciden");
    }
    let data = {
      password,
      resetToken
    };
    dispatch(controller.changePassByTokenThunk(data));
  }

  return (
    <React.Fragment>
      <ChangePassByTokenRender
        history={history}
        setPassword={setPassword}
        setPassword2={setPassword2}
        submit={submit}
        isLoading={modelChangePass.loading || modelCheckToken.loading}
        submitErrorMsg={submitErrorMsg}
        successMsg={successMsg}
      />
      <Footer />
    </React.Fragment>
  );
}

export default Confirmation;
