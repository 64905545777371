/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { thousandSeparator, timeSince } from "../../utils/utils";
import NoResults from "../../utils/NoResults";

function PageLink({
  pageNumber,
  currentPageNumber,
  handlePagination,
  ellipsis = false,
  itemClassName = "page-item",
  linkClassName = "page-link",
  debugStr = "",
}) {
  let mItemClassName = itemClassName;
  if (!ellipsis) {
    mItemClassName =
      itemClassName + (currentPageNumber === pageNumber ? " active" : "");
  }
  return (
    <li className={mItemClassName}>
      <a
        onClick={(e) => {
          e.preventDefault();
          if (!ellipsis) {
            handlePagination(pageNumber);
          }
        }}
        className={linkClassName}
      >
        {(ellipsis ? "..." : pageNumber) + debugStr}
      </a>
    </li>
  );
}

function Results({
  cotizacion,
  history,
  publicaciones,
  totalResults,
  activePage,
  orderby,
  setOrderBy,
  limit,
  handlePagination,
  isLoading,
  sugerencias,
}) {
  const pages = Math.ceil(totalResults / limit);

  const getPageLinks = (totalPages, activePage, handlePagination) => {
    let pageLinks = [];

    const pageLinkLimit =
      activePage + 1 <= totalPages ? activePage + 1 : totalPages;

    const pageLinkStart = activePage - 1 >= 1 ? activePage - 1 : 1;

    if (activePage - 1 === 2) {
      const pageLink = (
        <PageLink
          key={1}
          pageNumber={1}
          currentPageNumber={activePage}
          handlePagination={handlePagination}
        // debugStr={'a'}
        />
      );
      pageLinks.push(pageLink);
    } else if (activePage > 3) {
      const pageLink = (
        <PageLink
          key={1}
          pageNumber={1}
          currentPageNumber={activePage}
          handlePagination={handlePagination}
        // debugStr={'b'}
        />
      );
      pageLinks.push(pageLink);
    }

    if (activePage === 4) {
      const pageLink = (
        <PageLink
          key={2}
          pageNumber={2}
          currentPageNumber={activePage}
          handlePagination={handlePagination}
        // debugStr={'c'}
        />
      );
      pageLinks.push(pageLink);
    } else if (activePage > 4) {
      const ellipsis = <PageLink key={2} ellipsis={true} />;
      pageLinks.push(ellipsis);
    }

    for (
      let pageNumber = pageLinkStart;
      pageNumber <= pageLinkLimit;
      pageNumber++
    ) {
      const pageLink = (
        <PageLink
          key={pageNumber}
          pageNumber={pageNumber}
          currentPageNumber={activePage}
          handlePagination={handlePagination}
        />
      );
      pageLinks.push(pageLink);
    }

    if (activePage + 3 < totalPages) {
      const ellipsis = <PageLink key={activePage + 2} ellipsis={true} />;
      pageLinks.push(ellipsis);
    } else if (activePage + 3 === totalPages) {
      const pageLink = (
        <PageLink
          key={totalPages - 1}
          pageNumber={totalPages - 1}
          currentPageNumber={activePage}
          handlePagination={handlePagination}
        // debugStr={'d'}
        />
      );
      pageLinks.push(pageLink);
    }

    if (activePage + 1 < totalPages) {
      const lastPageLink = (
        <PageLink
          key={totalPages}
          pageNumber={totalPages}
          currentPageNumber={activePage}
          handlePagination={handlePagination}
        // debugStr={'e'}
        />
      );
      pageLinks.push(lastPageLink);
    }

    return pageLinks;
  };

  return publicaciones && publicaciones.length > 0 ? (
    <div className="col-lg-9">
      <section className="resultados-list">
        <div className="header-list clearfix">
          {totalResults > 0 ? (
            <p className="total-resultados">
              <strong>{totalResults}</strong>{" "}
              {totalResults === 1 ? "Resultado" : "Resultados"}
            </p>
          ) : null}

          <p className="orderby">
            <strong>Ordenar por</strong>
            <select
              name=""
              id=""
              value={orderby}
              onChange={(e) => setOrderBy(e.target.value)}
            >
              <option value="precio">Precio</option>
              <option value="kilometraje">Kilometraje</option>
              <option value="ts_desc">Más nuevos</option>
              <option value="ts">Más antiguos</option>
            </select>
          </p>
        </div>

        <div className="cards-resultados">
          <div className="row gutter-7">
            {publicaciones &&
              publicaciones.length > 0 &&
              publicaciones.map((row, index) => (
                <div key={index} className="col-6 col-md-4">
                  <article className="product-item">
                    <figure className="img">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(
                            `/publicacion/${
                              row.attrs.marca
                            }-${row.attrs.modelo.replace("/", "_")}-${
                              row.attrs.anho
                            }-${row.attrs.kilometraje}/${row._id}`
                          );
                        }}
                        className="img-link"
                      >
                        <img src={row.imagenes[0].url} alt="" />
                      </a>
                      {/* <a className="fav-container">
                          <span className="fav"></span>
                        </a> */}
                    </figure>
                    <div className="desc">
                      <div style={{ marginBottom: 5 }}>
                        <span style={{ fontSize: 10, alignItems: "center" }}>
                          <img
                            style={{ width: 15, height: 15 }}
                            alt=""
                            src="/images/clock.png"
                          />{" "}
                          {timeSince(row.ts)}
                        </span>
                      </div>
                      <h4 className="title">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              `/publicacion/${
                                row.attrs.marca
                              }-${row.attrs.modelo.replace("/", "_")}-${
                                row.attrs.anho
                              }-${row.attrs.kilometraje}/${row._id}`
                            );
                          }}
                        >
                          {row.attrs.marca + " " + row.attrs.modelo}
                        </a>
                        {row.attrs.kilometraje == 0 ? (
                          <div className="details" style={{ marginTop: 10 }}>
                            {/* <span>
                              {row.attrs.anho + " | "}
                            </span> */}

                            <strong
                              style={{
                                backgroundColor: "#ffc107",
                                borderRadius: 15,
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 2,
                                paddingBottom: 2,
                              }}
                            >
                              {row.attrs.anho +
                                " | " +
                                thousandSeparator(row.attrs.kilometraje) +
                                " km"}
                            </strong>
                          </div>
                        ) : (
                          <div className="details" style={{ marginTop: 10 }}>
                            {row.attrs.anho +
                              " | " +
                              thousandSeparator(row.attrs.kilometraje) +
                              " km"}
                          </div>
                        )}
                      </h4>
                      <p className="price">
                        USD. {thousandSeparator(row.precio, true)}
                      </p>
                      <p className="price sm">
                        GS. {thousandSeparator(row.precio * cotizacion, false)}
                      </p>
                    </div>
                    <div className="bottom">
                      <div className="bottom-item">
                        <img
                          src="/images/ico-combustible.png"
                          alt="Combulstible"
                          className="ico"
                        />
                        {row.attrs.combustible}
                      </div>
                      <div className="bottom-item">
                        <img
                          src="/images/ico-caja.png"
                          alt="Caja"
                          className="ico"
                        />
                        {row.attrs.caja}
                      </div>
                      {/* <div className="bottom-item favoritos">
                          <a
                            href="#"
                            className="fav"
                            title="Agregar a favoritos"
                          ></a>
                        </div> */}
                    </div>
                  </article>
                </div>
              ))}
          </div>
        </div>

        <div className="footer-list clearfix">
          {totalResults > 0 ? (
            <p className="total-resultados">
              <strong>{totalResults}</strong>{" "}
              {totalResults === 1 ? "Resultado" : "Resultados"}
            </p>
          ) : null}

          <nav className="paginacion-resultados">
            <ul className="pagination">
              {activePage !== 1 ? (
                <li className="page-item">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      handlePagination(activePage - 1);
                    }}
                    className="page-link prev"
                    aria-label="Previous"
                  >
                    <span className="sr-only">Anterior</span>
                  </a>
                </li>
              ) : null}

              {getPageLinks(pages, activePage, handlePagination)}

              {activePage !== pages ? (
                <li className="page-item">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      handlePagination(activePage + 1);
                    }}
                    className="page-link next"
                    aria-label="Next"
                  >
                    <span className="sr-only">Siguiente</span>
                  </a>
                </li>
              ) : null}
            </ul>
          </nav>
        </div>
        {
    sugerencias && sugerencias.length > 0 ? (
      <>
        <h2 style={{ marginTop: 20 }}>Publicaciones sugeridas</h2>
        <div className="cards-resultados">
          <div className="row gutter-7">
            {sugerencias &&
              sugerencias.length > 0 &&
              sugerencias.map((row, index) => (
                <div key={index} className="col-6 col-md-4">
                  <article className="product-item">
                    <figure className="img">
                      <a
                        onClick={() =>
                          history.push(
                            `/publicacion/${row.attrs.marca}-${row.attrs.modelo.replace('/', '_')}-${row.attrs.anho}-${row.attrs.kilometraje}/${row._id}`
                          )
                        }
                        className="img-link"
                      >
                        <img src={row.imagenes[0].url} alt="" />
                      </a>
                      {/* <a className="fav-container">
                  <span className="fav"></span>
                </a> */}
                    </figure>
                    <div className="desc">
                      <div style={{ marginBottom: 5 }}>
                        <span style={{ fontSize: 10 }}>
                          <img
                            style={{ width: 15, height: 15 }}
                            alt=""
                            src="/images/clock.png"
                          />{" "}
                          {timeSince(row.ts)}
                        </span>
                      </div>
                      <h4 className="title">
                        <a
                          onClick={() =>
                            history.push("/publicacion/" + row._id)
                          }
                        >
                          {row.attrs.marca + " " + row.attrs.modelo}
                        </a>
                        {row.attrs.kilometraje === 0 ? (
                          <div
                            className="details"
                            style={{ marginTop: 10 }}
                          >
                            {/* <span>
                                  {row.attrs.anho + " | "}
                                </span> */}

                                <strong
                                  style={{
                                    backgroundColor: "#ffc107",
                                    borderRadius: 15,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                  }}
                                >
                                  {row.attrs.anho +
                                    " | " +
                                    thousandSeparator(row.attrs.kilometraje) +
                                    " km"}
                                </strong>
                              </div>
                            ) : (
                              <div
                                className="details"
                                style={{ marginTop: 10 }}
                              >
                                {row.attrs.anho +
                                  " | " +
                                  thousandSeparator(row.attrs.kilometraje) +
                                  " km"}
                              </div>
                            )}
                          </h4>
                          <p className="price">
                            USD. {thousandSeparator(row.precio, true)}
                          </p>
                          <p className="price sm">
                            GS.{" "}
                            {thousandSeparator(row.precio * cotizacion, false)}
                          </p>                          
                        </div>
                        <div className="bottom">
                          <div className="bottom-item">
                            <img
                              src="/images/ico-combustible.png"
                              alt="Combulstible"
                              className="ico"
                            />
                            {row.attrs.combustible}
                          </div>
                          <div className="bottom-item">
                            <img
                              src="/images/ico-caja.png"
                              alt="Caja"
                              className="ico"
                            />
                            {row.attrs.caja}
                          </div>
                          {/* <div className="bottom-item favoritos">
                  <a
                    href="#"
                    className="fav"
                    title="Agregar a favoritos"
                  ></a>
                </div> */}
                    </div>
                  </article>
                </div>
              ))}
          </div>
          <div className="col-md-8 offset-2" style={{ marginTop: 20 }}>
            <p>
              ¿No encontraste lo que necesitas?{" "}
              <strong>
                <a
                  style={{ color: "#343a40" }}
                  href=""
                  onClick={() => history.push("/resultados")}
                >
                  <u>Ver todas las publicaciones</u>
                </a>
              </strong>
            </p>
          </div>
        </div>
      </>
    ) : null
  }

  {/* <div className="sugerencias-resultados">
          <div className="sugerencias-col">
            <a href="resultados" className="sugerencia-item">
              <div className="marca">
                <img
                  src="images/marca-sugerido.png"
                  alt="KIA"
                  className="img-fluid"
                />
              </div>
              <figure className="img">
                <img
                  src="images/modelo-sugerido.jpg"
                  className="img-fluid"
                  alt="Modelo Sugerido"
                />
              </figure>
              <div className="desc">
                <h3 className="title">Kia Picanto</h3>
                <p className="price">
                  Desde <strong>Gs. 30.000.000</strong>
                </p>
              </div>
            </a>
          </div>

          

          <div className="sugerencias-col">
            <a href="resultados" className="sugerencia-item">
              <div className="marca">
                <img
                  src="images/marca-sugerido.png"
                  alt="KIA"
                  className="img-fluid"
                />
              </div>
              <figure className="img">
                <img
                  src="images/modelo-sugerido.jpg"
                  className="img-fluid"
                  alt="Modelo Sugerido"
                />
              </figure>
              <div className="desc">
                <h3 className="title">Kia Picanto</h3>
                <p className="price">
                  Desde <strong>Gs. 30.000.000</strong>
                </p>
              </div>
            </a>
          </div>

          <div className="sugerencias-col">
            <a href="resultados" className="sugerencia-item">
              <div className="marca">
                <img
                  src="images/marca-sugerido.png"
                  alt="KIA"
                  className="img-fluid"
                />
              </div>
              <figure className="img">
                <img
                  src="images/modelo-sugerido.jpg"
                  className="img-fluid"
                  alt="Modelo Sugerido"
                />
              </figure>
              <div className="desc">
                <h3 className="title">Kia Picanto</h3>
                <p className="price">
                  Desde <strong>Gs. 30.000.000</strong>
                </p>
              </div>
            </a>
          </div>

          <div className="sugerencias-col">
            <a href="resultados" className="sugerencia-item">
              <div className="marca">
                <img
                  src="images/marca-sugerido.png"
                  alt="KIA"
                  className="img-fluid"
                />
              </div>
              <figure className="img">
                <img
                  src="images/modelo-sugerido.jpg"
                  className="img-fluid"
                  alt="Modelo Sugerido"
                />
              </figure>
              <div className="desc">
                <h3 className="title">Kia Picanto</h3>
                <p className="price">
                  Desde <strong>Gs. 30.000.000</strong>
                </p>
              </div>
            </a>
          </div>

          <div className="sugerencias-col">
            <a href="resultados" className="sugerencia-item">
              <div className="marca">
                <img
                  src="images/marca-sugerido.png"
                  alt="KIA"
                  className="img-fluid"
                />
              </div>
              <figure className="img">
                <img
                  src="images/modelo-sugerido.jpg"
                  className="img-fluid"
                  alt="Modelo Sugerido"
                />
              </figure>
              <div className="desc">
                <h3 className="title">Kia Picanto</h3>
                <p className="price">
                  Desde <strong>Gs. 30.000.000</strong>
                </p>
              </div>
            </a>
          </div>

          <div className="title-cotainer">
            <p className="caption">RELACIONADOS</p>
            <h2>
              ¿Ya viste estos <strong>0km</strong>?
            </h2>
            <a href="resultados" className="btn btn-primary dark">
              Buscar 0km
            </a>
          </div>
        </div> */}
      </section >
    </div >
  ) : (
    <>
      <NoResults
        isLoading={isLoading}
        sugerencias={sugerencias}
        history={history}
      />
    </>
  );
}

export default Results;
