/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";

function Pasos({ publicacionID, pasoNro }) {
  let history = useHistory();
  return (
    <section id="paso-publicar">
      <div className="columna-pasoapaso">
        <ul className="menu-pasoapaso">
          <li className="item-pap" id="#publi1" data-step-target="step1">
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                history.push('/publicar/info' + (publicacionID ? `/${publicacionID}` : ''))
              }}
              className={"a-ppn" + (pasoNro === 1 ? " active" : " check-ppn")}
              rel="p1"
            >
              <span className="it-ppn">1</span>
              <span className="it-pp">Información Básica</span>
            </a>
          </li>
          <li className="item-pap" id="#publi2" data-step-target="step2">
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                publicacionID && history.push(`/publicar/imagenes/${publicacionID}`)
              }}
              className={"a-ppn" + (pasoNro === 2 ? " active" : pasoNro === 3 ? " active check-ppn" : "")}
              rel="p2"
            >
              <span className="it-ppn">2</span>
              <span className="it-pp">Subir Imágenes</span>
            </a>
          </li>
          <li className="item-pap" id="#publi3" data-step-target="step3">
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                publicacionID && history.push(`/publicar/detalles/${publicacionID}`)
              }}
              className={"a-ppn" + (pasoNro === 3 ? " active" : "")}
              rel="p3"
            >
              <span className="it-ppn">3</span>
              <span className="it-pp">Otros Detalles</span>
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Pasos;
