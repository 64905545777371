import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import Select from "react-select";
import { getAnalytics, logEvent } from "firebase/analytics";

import Loader from "../../utils/Loader";

import { precioOptions } from "../../utils/utils";

function RenderSearch({
  customStyles,
  value,
  setValue,
  options,
  label,
  isLoading,
  noOptionsMessage,
  placeholder = "Seleccionar",
}) {
  return (
    <div className="subseleccionarContainer">
      <div className="subseleccionar">
        <text
          style={{
            color: "#A7A9AC",
            display: "block",
            fontSize: "0.85rem",
            lineHeight: "1rem",
            cursor: "pointer",
            paddingRight: "1.25rem",
            paddingLeft: "1.25rem",
          }}
        >
          {label}
        </text>

        <Select
          // isClearable
          noOptionsMessage={() => noOptionsMessage}
          isLoading={isLoading}
          styles={customStyles}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => (
              <img
                style={{ width: 18, height: 10, marginRight: 20 }}
                src="/images/down.png"
                alt=""
              />
            ),
          }}
          value={value}
          onChange={(selected) => {
            setValue(selected);
          }}
          isSearchable
          placeholder={placeholder}
          options={options}
        />
      </div>
    </div>
  );
}

function BannerWithSearch({
  marcas,
  marca,
  setMarca,
  modelos,
  modelo,
  setModelo,
  anho,
  setAnho,
  searchPublicaciones,
  contenido,
  loadingMarcas,
  loadingModelos,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      paddingRight: isMobile ? "0.5rem" : "1.25rem",
      paddingLeft: isMobile ? "0.5rem" : "1.25rem",
      fontStyle: "italic",
      fontSize: "1.15rem",
      ":hover": {
        color: "#FFD340",
      },
    }),
    dropdownIndicator: (base) => {
      return {
        ...base,
        color: "#2a2a2a", // Custom colour
      };
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: state.isFocused ? "#FFD340" : "#2a2a2a",
        fontStyle: "italic",
        fontSize: "1.15rem",
        ":hover": {
          color: "#FFD340",
        },
      };
    },

    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
      marginRight: isMobile ? "0.5rem" : 0,
      marginLeft: isMobile ? "0.5rem" : 0,
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "none",
      color: state.isFocused && "#2a2a2a",
      borderBottom: "1px solid #d2dde3",
      paddingRight: isMobile ? "1.1rem" : "1.3rem",
      paddingLeft: isMobile ? "1.1rem" : "1.3rem",
    }),
  };

  function handleMarca(selected) {
    setMarca(selected);
    setModelo(null);
  }

  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  const [years, setYears] = useState(getYearsFromSomeYear(1990));

  function getYearsFromSomeYear(initialYear) {
    const finalYear = moment().format("YYYY");
    const yearsAux = [];
    for (let i = initialYear; i <= Number(finalYear) + 1; i++) {
      if (i >= 1990) {
        const option = {
          value: i,
          label: addCommas(i),
        };
        yearsAux.push(option);
      }
    }
    return yearsAux.reverse();
  }
  return (
    <section id="hero">
      <div className="video-container">
        <video
          playsInline
          autoPlay
          muted
          loop
          poster="images/video-cover.jpg"
          id="bg-video"
        >
          <source src="media/vaun-film.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="overlay-black"></div>
      <div className="container hero-content">
        <h1>{contenido ? contenido["banner"][0].titulo : ""}</h1>
        <div className="home-search">
          <RenderSearch
            placeholder="Todas las marcas"
            noOptionsMessage="Marcas no encontradas"
            isLoading={loadingMarcas}
            isMobile={isMobile}
            width={width}
            label="Marca"
            customStyles={customStyles}
            value={marca}
            setValue={handleMarca}
            options={marcas}
          />
          <RenderSearch
            placeholder="Todos los modelos"
            noOptionsMessage="Modelos no encotnrados"
            isLoading={loadingModelos}
            isMobile={isMobile}
            width={width}
            label="Modelo"
            customStyles={customStyles}
            value={modelo}
            setValue={setModelo}
            options={modelos}
          />
          {/* <RenderSearch
            customWidth={350}
            noOptionsMessage="Años no encontrados"
            isMobile={isMobile}
            width={width}
            label="Año"
            customStyles={customStyles}
            value={anho}
            setValue={setAnho}
            options={years}
          /> */}
          <div className="search-item buscar">
            <a
              onClick={() => {
                const analytics = getAnalytics();
                logEvent(analytics, "home_action", {
                  action: "search",
                });
                searchPublicaciones();
              }}
              className="btn btn-primary btn-search"
            >
              BUSCAR
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BannerWithSearch;
