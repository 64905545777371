/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from "react";
import axios from "axios";

import CortarImagen from "./CortarImagen";
import { logger } from "../utils/utils";

import Loader from "../utils/Loader";
import Alert from "../utils/Alert";

const loaderStyle = {
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  height: "400px",
};

const loaderSize = 30;

export default function CortarImagenes({
  imagenesTemporales,
  imgTmpRespuestaRecibida,
  uploadUrl,
  uploadHeaders,
  eliminacionLoading,
  onClickEliminar, // Recibe id de imagen.
  onAllDone,
  minCroppedWidth,
  minCroppedHeight,
  maxCroppedWidth,
  maxCroppedHeight,
  aspectRatioNumerator,
  aspectRatioDenominator,
  conceptoId,
  saveInConceptUrl,
  relatedImageId,
  setEliminacionLoading,
  submitErrorMsg,
  setSubmitErrorMsg,
  generalErrorMsg,
}) {
  const [currentImg, setCurrentImg] = useState(0);
  const [urlImagen, setUrlImagen] = useState(null);
  const [uploadStatus, setUploadStatus] = useState({});
  const [cropperLoading, setCropperLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [allFinished, setAllFinished] = useState(false);

  // const cropperRef = useRef(null);
  const cropperInstance = useRef(null);

  useEffect(() => {


    if (!imagenesTemporales || !imagenesTemporales?.length) {
      return;
    }

    setCurrentImg(0);
    setUrlImagen(imagenesTemporales[0].originalUrl);
    setUploadStatus(prepareUploadStatus(imagenesTemporales));
  }, [imagenesTemporales]);

  const prepareUploadStatus = (data) => {
    const newUploadStatus = {};
    data.forEach((element, index) => {
      newUploadStatus[index] = {};
      newUploadStatus[index]["status"] = false;
    });

    return newUploadStatus;
  };

  const getFilenameWithoutExtension = (str) => {
    if (typeof str !== "string") return;
    let frags = str.split(".");

    if (frags.length == 1) {
      return str;
    }
    return frags.splice(0, frags.length - 1).join(".");
  };

  const getCanvasBlob = (canvas, mimeType) => {
    return new Promise((resolve, reject) => {
      try {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, mimeType);
      } catch (error) {
        reject(error);
      }
    });
  };

  const fileUpload = async () => {
    setUploading(true);
    setSubmitErrorMsg("");
    const mimeType = getMimeType(urlImagen);

    const croppedCanvas = cropperInstance.current.getCroppedCanvas();
    const blob = await getCanvasBlob(croppedCanvas, mimeType);

 

    await uploadBlobAndGoNext(blob, "jpeg");
  };

  const getMimeType = (urlImagen) => {
    const extension = getExtensionFromFilename(urlImagen);
    if (['jpg', 'jpeg', 'png'].includes(extension)) {
      return 'image/jpeg';
    } else {
      const error = 'Extensión no permitida';
      setSubmitErrorMsg(error);
      throw new Error(error);
    }
  };

  const getExtensionFromFilename = (filename) => {
    let lastIndex = filename.lastIndexOf(".");
    if (lastIndex === -1) {
      let error = "No se pudo obtener extensión del nombre de archivo (1)";
      setSubmitErrorMsg(error);
      throw new Error(error);
    }

    let foundExtension = filename.substr(lastIndex + 1);

    if (foundExtension === "") {
      let error = "No se pudo obtener extensión del nombre de archivo (2)";
      setSubmitErrorMsg(error);
      throw new Error(error);
    }
    return foundExtension;
  };

  const replaceSpecialCharacters = (string) => string.replace(/ /g, '');
  //todo getFileName from imageUrl
  const uploadBlobAndGoNext = async (blob, extension) => {


    const url = uploadUrl;
    const formData = new FormData();
    const filename = getFilenameWithoutExtension(replaceSpecialCharacters(urlImagen));
    formData.append("files[]", blob, `${filename}.${extension}`);
    formData.append("imagenID", imagenesTemporales[currentImg]._id);

    const config = { headers: uploadHeaders };

    try {
      const uploadResult = await axios.post(url, formData, config);

      logger.debug("fileUpload.uploadResult");
      logger.debug(uploadResult);

      const saveInConceptParams = {
        url: uploadResult.data.data.url,
        key: uploadResult.data.data.key,
        bucket: uploadResult.data.data.bucket,
        imagenID: imagenesTemporales[currentImg]._id,
        conceptoId: conceptoId,
        relatedImageId,
      };

      logger.debug({ saveInConceptUrl });
      logger.debug({ saveInConceptParams });

      await axios.post(saveInConceptUrl, saveInConceptParams, config);

      setUploadStatus((prevState) => ({
        ...prevState,
        [currentImg]: { status: true },
      }));
      setUploading(false);
      siguienteImagen();
    } catch (e) {
   
      const errorMessage =
        e?.response?.data?.message || "Ocurrió un error al subir la imagen";
      setSubmitErrorMsg(errorMessage);
      setUploading(false);
    }
  };

  const siguienteImagen = () => {
    setCropperLoading(true);

    const cantidad = Array.isArray(imagenesTemporales)
      ? imagenesTemporales.length
      : 0;

    const next = currentImg + 1;

  

    if (next > cantidad - 1) {
   
      setCropperLoading(false);
      setAllFinished(true);
      return;
    }

    setCurrentImg(next);

    setUrlImagen(imagenesTemporales[next].originalUrl);
  };

  const onCropperReady = () => {

    setCropperLoading(false);
  };

  const showLoaderAndDoEliminar = () => {

    setEliminacionLoading(true);
    onClickEliminar(imagenesTemporales[currentImg]._id);
  };

  // Check if all done.



  if (
    allFinished ||
    (imgTmpRespuestaRecibida === true && !imagenesTemporales?.length)
  ) {
  
    onAllDone();
  }

  const buttonContainerStyle = {
    display: cropperLoading ? "none" : "block",
    marginBottom: 20,
  };

  const showUploadButton =
    uploadStatus[currentImg] &&
    uploadStatus[currentImg]["status"] &&
    eliminacionLoading === true &&
    cropperLoading === true
      ? false
      : true;

  const mostrarBotonSiguiente = !!uploadStatus[currentImg]?.["status"];



  // Render.

  return (
    <div>
      <Loader
        style={loaderStyle}
        isLoading={cropperLoading}
        loaderSize={loaderSize}
      >
        {null}
      </Loader>

      <CortarImagen
        // cropperRef={cropperRef}
        cropperInstance={cropperInstance}
        urlImagen={urlImagen}
        onCropperReady={onCropperReady}
        minCroppedWidth={minCroppedWidth}
        minCroppedHeight={minCroppedHeight}
        maxCroppedWidth={maxCroppedWidth}
        maxCroppedHeight={maxCroppedHeight}
        aspectRatioNumerator={aspectRatioNumerator}
        aspectRatioDenominator={aspectRatioDenominator}
      />

      <Loader
        isLoading={uploading || eliminacionLoading}
        loaderSize={loaderSize}
      >
        {null}
      </Loader>

      {submitErrorMsg && (
        <Alert
          type="danger"
          text={submitErrorMsg}
          visible={true}
          style={{ textAlign: "left" }}
        />
      )}

      {!cropperLoading && !uploading && !eliminacionLoading && (
        <div className="content-btncon">
          {showUploadButton && (
            <button onClick={fileUpload} className="btn btn-primary dark">
              SUBIR
            </button>
          )}
          <button onClick={showLoaderAndDoEliminar} className="btn btn-primary">
            Eliminar
          </button>
          {mostrarBotonSiguiente && (
            <button onClick={siguienteImagen} className="btn btn-primary">
              Siguiente
            </button>
          )}
        </div>
      )}
    </div>
  );
}
