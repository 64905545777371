import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ComparativoRender from "./ComparativoRender";
import Header from "../../utils/Header";

import Footer from "../../utils/Footer";
function Comparativo({ history }) {
  return (
    <React.Fragment>
      <Header history={history} />
      <ComparativoRender history={history} />

      <Footer />
    </React.Fragment>
  );
}

export default Comparativo;
