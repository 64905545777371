import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import Select from "react-select";
import { getAnalytics, logEvent } from "firebase/analytics";

import Loader from "../../utils/Loader";

import { precioOptions } from "../../utils/utils";

function RenderSearch({
  customStyles,
  value,
  setValue,
  options,
  label,
  isLoading,
  noOptionsMessage,
  placeholder = "Seleccionar",
}) {
  return (
    <div className="subseleccionarContainer">
      <div className="subseleccionar" style={{ backgroundColor:'white', borderRadius:4}}>
        <text
          style={{
            backgroundColor:'white',
            borderRadius:15,
            color: "#A7A9AC",
            display: "block",
            fontSize: "0.85rem",
            lineHeight: "1rem",
            cursor: "pointer",
            paddingRight: "1.25rem",
            paddingLeft: "1.25rem",
            paddingTop: '0.5rem',
          }}
        >
          {label}
        </text>

        <Select
          // isClearable
          noOptionsMessage={() => noOptionsMessage}
          isLoading={isLoading}
          styles={customStyles}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => (
              <img
                style={{ width: 18, height: 10, marginRight: 20 }}
                src="/images/down.png"
                alt=""
              />
            ),
          }}
          value={value}
          onChange={(selected) => {
            setValue(selected);
          }}
          isSearchable
          placeholder={placeholder}
          options={options}
        />
      </div>
    </div>
  );
}

function BannerWithSearchTaller({
  seguro,
  segurosOptions,
  searchSeguro,
  loading,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      paddingBottom: '0.5rem',
      paddingRight: isMobile ? "0.5rem" : "1.25rem",
      paddingLeft: isMobile ? "0.5rem" : "1.25rem",
      fontStyle: "italic",
      fontSize: "1.15rem",
      ":hover": {
        color: "#FFD340",
      },
    }),
    dropdownIndicator: (base) => {
      return {
        ...base,
  
        color: "#2a2a2a", // Custom colour
      };
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: state.isFocused ? "#FFD340" : "#2a2a2a",
        fontStyle: "italic",
        fontSize: "1.15rem",
        ":hover": {
          color: "#FFD340",
        },
      };
    },

    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: "none",
      marginRight: isMobile ? "0.5rem" : 0,
      marginLeft: isMobile ? "0.5rem" : 0,
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "none",
      color: state.isFocused && "#2a2a2a",
      borderBottom: "1px solid #d2dde3",
      paddingRight: isMobile ? "1.1rem" : "1.3rem",
      paddingLeft: isMobile ? "1.1rem" : "1.3rem",
    }),
  };

  return (
 

        <div style={{position:'absolute', top:'10vh', left: isMobile ? '10vw' : '30vw', width: isMobile ? '80vw' : '40vw'}}>
          <RenderSearch
            placeholder="Todas los seguros"
            noOptionsMessage="Seguros no encontrados"
            isLoading={loading}
            isMobile={isMobile}
            width={width}
            label="Seguro"
            customStyles={customStyles}
            value={seguro}
            setValue={searchSeguro}
            options={segurosOptions}
          />
          

      </div>
  );
}

export default BannerWithSearchTaller;
