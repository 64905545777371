import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";
import controller from "../duck";
import InformacionBasicaView from "./InformacionBasica.view";
import { logger } from "../../utils/utils";
import { countBy } from "lodash";

const getYearsFromSomeYear = (initialYear) => {
  const finalYear = moment().format("YYYY");
  const yearsAux = [];
  for (let i = initialYear; i <= Number(finalYear) + 1; i++) {
    if (i >= 1990) {
      yearsAux.push({ label: i, value: i });
    }
  }
  return yearsAux.reverse();
};

function InformacionBasica() {

  // [START banderas para query string parameters]
  // Banderas para setear marca/modelo
  // según valores recibidos por query string
  // solamente la primera vez.
  // Es decir, los useEffect que escuchan "marcas"
  // y "modelos", cada uno setea la marca y modelo
  // recibidas por query string solamente la primera
  // vez que "marcas" y "modelos" cambian (cuando
  // se trae del backend por 1ra. vez ambas listas)
  const marcasSwitch = useRef(true);
  const modelosSwitch = useRef(true);
  // [END banderas para query string parameters]

  let history = useHistory();
  const { publicacionID } = useParams(null);

  const searchParams = queryString.parse(history.location.search);

  const {
    marca: marcaParam,
    modelo: modeloParam,
    anho: anhoParam,
  } = searchParams;

  logger.debug({ marcaParam, modeloParam, anhoParam });

  const dispatch = useDispatch();

  const [token, setToken] = useState(() => localStorage.getItem("token"));
  logger.debug("InformacionBasica.token");
  logger.debug(token);

  const modelPublicacionIncompleta = useSelector(
    (state) => state.publicacionIncompleta
  );
  const modelAddPublicacion = useSelector((state) => state.addPublicacion);
  const modelUpdate = useSelector((state) => state.updateProductDetails);
  const modelMarcas = useSelector((state) => state.marcas);
  const modelModelos = useSelector((state) => state.modelos);

  const [publicacion, setPublicacion] = useState(null);
  // const [estado, setEstado] = useState(null);
  const [marca, setMarca] = useState(null);
  const [marcaOption, setMarcaOption] = useState(null);
  const [modelo, setModelo] = useState(null);
  const [modeloOption, setModeloOption] = useState(null);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [anho, setAnho] = useState(null);
  const [caja, setCaja] = useState(null);
  const [combustible, setCombustible] = useState(null);
  const [cuerpo, setCuerpo] = useState(null);
  const [kilometraje, setKilometraje] = useState("");
  const [anhos, setAnhos] = useState(() => getYearsFromSomeYear(1990));
  const [startPubErrorMsg, setStartPubErrorMsg] = useState("");
  const [missingDataErrorMsg, setMissingDataErrorMsg] = useState("");
  const [generalErrorMsg, setGeneralErrorMsg] = useState("");

  // componentDidMount
  useEffect(() => {
    dispatch(controller.getMarcasThunk());
    if (publicacionID) {
      dispatch(controller.getPublicacionIncompletaThunk(publicacionID));
    }
    return () => {
      dispatch(controller.duckAddPublicacion.actions.clear());
    };
  }, []);

  useEffect(() => {
    const data = modelPublicacionIncompleta?.data?.data;
    const error = modelPublicacionIncompleta?.error;

    if (error) {
      logger.debug({ publicacionIncompletaError: modelPublicacionIncompleta });
      const errorMessage =
        modelPublicacionIncompleta?.error?.response?.data?.message ||
        "Ocurrió un error al obtener datos de la publicación. Por favor, recargue la página.";
      setGeneralErrorMsg(errorMessage);
      dispatch(controller.duckGetPublicacionIncompleta.actions.clear());
    }

    if (data) {
      logger.debug({ publicacionIncompleta: data });
      if (data.data) {
        if (data?.data?.estado === "ELIMINADO") {
          history.push("/");
          return;
        }

        setPublicacion(data.data);

        if (data.data?.attrs) {
          setMarca(data.data.attrs?.marca || null);
          setModelo(data.data.attrs?.modelo || null);
          setModeloOption({
            label: data.data.attrs?.modelo,
            value: data.data.attrs?.modelo,
          });
          setAnho(data.data.attrs?.anho || null);
          setCuerpo(data.data.attrs?.cuerpo || null);
          setCombustible(data.data.attrs?.combustible || null);
          setCaja(data.data.attrs?.caja || null);
          setKilometraje(data.data.attrs?.kilometraje || "");
        }
        // setEstado(data.data.estado);
        dispatch(controller.duckGetPublicacionIncompleta.actions.clear());
      } else {
        const errorMessage =
          "Ha ocurrido un error al obtener la publicación, por favor intente nuevamente recargando la página";
        setGeneralErrorMsg(errorMessage);
        dispatch(controller.duckGetPublicacionIncompleta.actions.clear());
        return;
      }
    }
  }, [dispatch, modelPublicacionIncompleta]);

  useEffect(() => {
    logger.debug("modelAddPublicacion.useEffect");
    const data = modelAddPublicacion?.data?.data;
    const error = modelAddPublicacion?.error;

    if (error) {
      logger.debug("modelAddPublicacion.error");
      logger.debug(modelAddPublicacion);
      const errorMessage =
        modelAddPublicacion?.error?.response?.data?.message ||
        "Ocurrió un error al intentar guardar los datos. Por favor, intente nuevamente.";
      setStartPubErrorMsg(errorMessage);
      dispatch(controller.duckAddPublicacion.actions.clear());
      return;
    }
    if (data) {
      logger.debug("navigate");
      history.push({
        pathname: `/publicar/imagenes/${data.data._id}`,
      });
    }
  }, [modelAddPublicacion, dispatch]);

  useEffect(() => {
    logger.debug("modelUpdatePublicacion.useEffect");
    const data = modelUpdate?.data?.data;
    const error = modelUpdate?.error;

    if (error) {
      const errorMessage =
        modelUpdate?.error?.response?.data?.message ||
        "Ocurrió un error al actualizar datos de publicación. Por favor, intente nuevamente.";
      setStartPubErrorMsg(errorMessage);
      dispatch(controller.duckUpdateProductDetails.actions.clear());
    }
    if (data) {
      dispatch(controller.duckUpdateProductDetails.actions.clear());
      logger.debug("updateFinished");
      logger.debug("navigate");

      history.push({
        pathname: `/publicar/imagenes/${publicacionID}`,
      });
    }
  }, [modelUpdate]);

  useEffect(() => {
    const data = modelMarcas?.data?.data;
    const error = modelMarcas?.error;

    if (error) {
      const errorMessage =
        modelMarcas?.error?.response?.data?.message ||
        "Ocurrió un error al obtener datos de la publicación. Por favor, recargue la página.";
      setGeneralErrorMsg(errorMessage);
      dispatch(controller.duckMarcas.actions.clear());
      return;
    }
    if (data) {
      logger.debug({ marcasRecibidas: data.data });
      setMarcas(data.data);
      dispatch(controller.duckMarcas.actions.clear());
      return;
    }
  }, [modelMarcas, dispatch]);

  useEffect(() => {
    const data = modelModelos?.data?.data;
    const error = modelModelos?.error;

    if (error) {
      const errorMessage =
        modelModelos?.error?.response?.data?.message ||
        "Ocurrió un error al consultar modelos";
      setGeneralErrorMsg(errorMessage);
      dispatch(controller.duckGetModelos.actions.clear());
      return;
    }

    if (data) {
      setModelos(data.data);
      dispatch(controller.duckGetModelos.actions.clear());
      return;
    }
  }, [modelModelos, dispatch]);

  useEffect(() => {
    logger.debug({ "useEffect([marca]).marca": marca });
    if (marca) {
      setModelos([]);
      setGeneralErrorMsg("");
      dispatch(controller.getModelosThunk(marca));
    }
  }, [marca, dispatch]);

  useEffect(() => {
    if (!publicacionID && marcaParam && marcas && Array.isArray(marcas) && marcas.length > 0 && marcasSwitch.current) {
      setMarca(marcaParam);
      marcasSwitch.current = false;
    }
  }, [marcas]);

  useEffect(() => {
    if (!publicacionID && modeloParam && modelos && Array.isArray(modelos) && modelos.length > 0 && modelosSwitch.current) {
      setModelo(modeloParam);
      setModeloOption({ label: modeloParam, value: modeloParam });
      setAnho(anhoParam);
      modelosSwitch.current = false;
    }
  }, [modelos]);

  function insertarPublicacion() {
    logger.debug("insertarPublicacion");

    setMissingDataErrorMsg("");
    setStartPubErrorMsg("");

    const anhoValue = anho && typeof anho === "number" ? anho : Number(anho);

    logger.debug({
      token,
      nombre: marca + " " + modelo,
      marca: marca,
      modelo: modelo,
      anho: anhoValue,
      cuerpo: cuerpo,
      combustible: combustible,
      caja: caja,
      kilometraje,
    });

    if (
      !anhoValue ||
      !marca ||
      !modelo ||
      !cuerpo ||
      !caja ||
      !combustible ||
      !kilometraje.length
    ) {
      setMissingDataErrorMsg("Por favor complete todos los campos");
      return;
    }

    if (!token) {
      setMissingDataErrorMsg(
        "Por favor inicie sesion para crear una publicacion"
      );
    }

    let data = {
      token,
      nombre: marca + " " + modelo,
      marca: marca,
      modelo: modelo,
      anho: anhoValue,
      cuerpo: cuerpo,
      combustible: combustible,
      caja: caja,
      kilometraje,
    };

    logger.debug("beforeIf.data");
    logger.debug(data);

    if (publicacionID) {
      // Si publicacion.estado es
      // INCOMPLETO, OCULTO, VALIDANDO
      // entonces no cambiar.
      // Si publicacion.estado es
      // VERIFICADO, RECHAZADO, cambiar a VALIDANDO.

      let newEstado = null;

      if (
        publicacion &&
        (publicacion.estado === "VERIFICADO" ||
          publicacion.estado === "RECHAZADO")
      ) {
        newEstado = "VALIDANDO";
      }

      const newAttrs = {
        marca: data.marca,
        modelo: data.modelo,
        cuerpo: data.cuerpo,
        combustible: data.combustible,
        caja: data.caja,
        anho: data.anho,
        kilometraje: data.kilometraje,
      };

      const newFirstLevelFields = {
        token,
        nombre: data.nombre,
      };

      if (newEstado) {
        newFirstLevelFields.estado = newEstado;
      }

      const editedPublicacion = {
        ...publicacion,
        ...newFirstLevelFields,
        attrs: {
          ...publicacion.attrs,
          ...newAttrs,
        },
      };
      logger.debug("submit.update.editedPublicacion");
      logger.debug({ editedPublicacion });
      logger.debug("beforeDispatch.editedPublicacion");
      logger.debug(editedPublicacion);
      dispatch(controller.updateProductDetailsThunk(editedPublicacion));
    } else {
      logger.debug("submit.add.data");
      logger.debug({ data });
      dispatch(controller.addPublicacionThunk(data));
    }
  }

  // Render

  return (
    <InformacionBasicaView
      history={history}
      loadingPublicacion={modelPublicacionIncompleta.loading}
      loadingMarcas={modelMarcas.loading}
      marcas={marcas}
      setMarca={setMarca}
      marca={marca}
      loadingModelos={modelModelos.loading}
      modelos={modelos}
      modelo={modelo}
      setModelo={setModelo}
      modeloOption={modeloOption}
      setModeloOption={setModeloOption}
      loadingAnhos={!anhos}
      anhos={anhos}
      anho={anho}
      setAnho={setAnho}
      insertarPublicacion={insertarPublicacion}
      kilometraje={kilometraje}
      setKilometraje={setKilometraje}
      cuerpo={cuerpo}
      setCuerpo={setCuerpo}
      combustible={combustible}
      setCombustible={setCombustible}
      caja={caja}
      setCaja={setCaja}
      // estado={estado}
      // setEstado={setEstado}
      publicacionID={publicacionID}
      publicacion={publicacion}
      generalErrorMsg={generalErrorMsg}
      missingDataErrorMsg={missingDataErrorMsg}
      startPubErrorMsg={startPubErrorMsg}
      continuarButtonLoading={
        modelAddPublicacion.loading || modelUpdate.loading
      }
      modelPublicacionIncompleta={modelPublicacionIncompleta}
    />
  );
}

export default InformacionBasica;
